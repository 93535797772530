import React, { useEffect, useState, useContext } from 'react';
import { fetchAllCourseDesignData } from '../utils/serverFetchAndAddUtilities';
import { CourseIDContext } from '../context/CourseIDContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faVideo, faFileAlt, faLink } from '@fortawesome/free-solid-svg-icons';
import './Syllabus.css';

const Syllabus = () => {
  const [syllabusData, setSyllabusData] = useState([]);
  const { courseID } = useContext(CourseIDContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAllCourseDesignData(courseID);

        // Filter items by type
        const courseDates = data.filter(item => item.dataType === 'courseDate');
        const topics = data.filter(item => item.dataType === 'topic');
        const sectionNames = data.filter(item => item.dataType === 'sectionName');
        const syllabusLinkItems = data.filter(item => item.dataType === 'syllabusLinkItem');

        // Combine items by dataIndex, respecting their order
        const combinedData = [
          ...sectionNames.map(item => ({ type: 'sectionName', data: item })),
          ...courseDates.map(courseDate => {
            const topic = topics.find(item => item.dataIndex === courseDate.dataIndex);
            const links = syllabusLinkItems
              .filter(link => link.questionType === topic?.dataID)
              .sort((a, b) => parseInt(a.dataIndex, 10) - parseInt(b.dataIndex, 10));
            return { type: 'courseDateTopic', courseDate, topic, links };
          })
        ].sort((a, b) => 
          parseInt(a.data?.dataIndex || a.courseDate?.dataIndex, 10) - 
          parseInt(b.data?.dataIndex || b.courseDate?.dataIndex, 10)
        );

        setSyllabusData(combinedData);
      } catch (error) {
        console.error('Error fetching syllabus data:', error);
      }
    };

    fetchData();
  }, [courseID]);

  return (
    <div className="syllabus">
      <h1 className="syllabus-header">Schedule</h1>
      <div className="syllabus-content">
        {syllabusData.map((item, index) => (
          <div key={index} className="syllabus-row">
            {item.type === 'sectionName' ? (
              <div className="syllabus-section">
                <span className="section-name">{item.data.dataText}</span>
              </div>
            ) : (
              <div className="syllabus-course-topic">
                <div className="course-date">{item.courseDate.dataText}</div>
                <div className="topic">{item.topic?.dataText || ''}</div>
                <div className="syllabus-links">
                  {item.links?.map(link => (
                    <a
                      key={link.dataID}
                      href={link.questionLogic}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="syllabus-link-icon"
                    >
                      <FontAwesomeIcon
                        icon={
                          link.dataText === 'reading'
                            ? faBookOpen
                            : link.dataText === 'video'
                            ? faVideo
                            : link.dataText === 'lecturenotes'
                            ? faFileAlt
                            : faLink
                        }
                      />
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Syllabus;
