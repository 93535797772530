import React, { useContext, useEffect } from 'react';
import QAndA from '../organisms/QAndA';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';

const PDFDisplay = () => {
  const { username } = useContext(AuthContext);
  console.log('Display for PDF:', username);

  useEffect(() => {
    
const sendHtmlToPuppeteer = async () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  console.log('API URL:', apiUrl);

  

  setTimeout(async () => {
    const renderedHtml = document.documentElement.outerHTML;

    // Inject CSS inline as a <style> block
    const styledHtml = `
      <!DOCTYPE html>
      <html>
      <head>
       
      </head>
      <body>
        ${renderedHtml}
      </body>
      </html>
    `;

    console.log('Styled HTML:', styledHtml);

    try {
      const response = await axios.post(
        `${apiUrl}/api/generate-pdf`,
        { html: styledHtml },
        { responseType: 'arraybuffer' }
      );

      console.log('Received Data Size:', response.data.byteLength);

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      // Trigger file download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'My Philosophy.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Close the tab once the download starts
      window.close();
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  }, 2000); // Adjust delay as needed for your specific app
};


    sendHtmlToPuppeteer();
  }, []);

  return (
    <QAndA
      username={username}
      sectionsToShow={'all'}
      answersReadOnly={true}
      showSectionHeaders={true}
    />
  );
};

export default PDFDisplay;
