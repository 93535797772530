import { useState } from 'react';
import React from 'react'; // Ensure React is in scope for JSX

export const useMouseoverTooltip = (courseDesignState) => {
    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const handleMouseEnter = (dataID, optionNumber, event) => {
        const questionItem = courseDesignState.find((item) => item.dataID === dataID);
        console.log('Matching item:', courseDesignState.find((item) => item.dataID === dataID));
        if (questionItem) {
            const optionsArray = questionItem.questionOptions.split('|||');
           // console.log('Options Array:', optionsArray);
            const optionText = optionsArray[Number(optionNumber) - 1] || '';
            //console.log('Option Text:', optionText);
            setTooltipContent(`Question: ${questionItem.dataText}\n\nAnswer: ${optionText}`);
        }
        setTooltipPosition({ x: event.clientX, y: event.clientY - 20 });
        

    };

    const handleMouseLeave = () => {
        setTooltipContent(''); // Ensure proper state reset
    };

    return {
        tooltipContent,
        tooltipPosition,
        handleMouseEnter,
        handleMouseLeave,
    };
};
