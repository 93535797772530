import React, { useState, useContext, useRef, useEffect } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import LogicDisplay from './LogicDisplay'; // Import the new LogicDisplay component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons'; // Import the checkmark icon
import { validateLogic } from '../utils/validateLogic';
import useAutoResizeTextareas from '../utils/useAutoResizeTextareas';



const LogicEntry = ({ initialValue, onChange }) => {
    const { courseDesignState } = useContext(CourseDesignContext);
    const [value, setValue] = useState(initialValue);
    const [isValid, setIsValid] = useState(true);
        const [isEditing, setIsEditing] = useState(initialValue === '' || initialValue === null); // Default to editing if logic is empty
  //  const [validationMessage, setValidationMessage] = useState('');
    const textareaRef = useRef(null); // Create a ref for the textarea

    useAutoResizeTextareas(); // Automatically resize textareas

    // Focus textarea on entering editing mode
    useEffect(() => {
        if (isEditing && textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [isEditing]);
    
    const validateInput = (input) => {
        // Add your recursive logic validation here
        return true; // Replace with actual validation logic
    };

    const handleChange = (e) => {
        let newValue = e.target.value;

                // Perform autocorrections
        newValue = newValue.replace(/\band\b/g, '&'); // Replace 'and' with '&'
        newValue = newValue.replace(/\^/g, '&'); // Replace '^' with '&'
newValue = newValue.replace(/\bor\b/g, '∨'); // Replace 'or' with '∨'
newValue = newValue.replace(/~/g, '¬'); // Replace '~' with '¬'
        newValue = newValue.replace(/\bnot\b/g, '¬'); // Replace 'not' with '¬'
        newValue = newValue.replace(/  /g, ' ');

        setValue(newValue);
        onChange(newValue);
        setIsValid(validateInput(newValue));
    };

     const handleValidation = () => {
        const isLogicValid = validateLogic(value); // Validate the logic input

        if (isLogicValid) {
            setIsValid(true);
         //   setValidationMessage('Logic is valid!');
            setIsEditing(false); // Switch to display mode
        } else {
            setIsValid(false);
         //   setValidationMessage('Invalid logic: Please check your syntax.');
        }
     };
    


    return isEditing ? (
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            <textarea
                ref={textareaRef} // Attach the ref to the textarea
                value={value}
                rows={1}
                onChange={handleChange}
                style={{
                    fontFamily: 'var(--font-mono)',
                    padding: '8px',
                    fontSize: '16px',
                    width: '500px',
                    maxWidth: '60%',
                    borderRadius: '4px',
                    border: `2px solid ${isValid ? '#ccc' : 'red'}`,
                }}
                placeholder="Enter logic conditions"
            />
            <button
             onClick={handleValidation} // Validate on checkmark click

                style={{
                    marginLeft: '8px',
                    cursor: 'pointer',
                    backgroundColor: 'var(--color2)',
                    border: 'none',
                }}
                title="Done Editing"
            >
                <FontAwesomeIcon icon={faCheck} /> {/* Checkmark icon for validation */}
            </button>
        </div>
    ) : (
        <LogicDisplay value={value} onEdit={() => setIsEditing(true)} courseDesignState={courseDesignState} />
    );
};

export default LogicEntry;
