// autocorrectLogic.js
export const autocorrectLogic = (input) => {
  let corrected = input;

  corrected = corrected.replace(/\band\b/g, '&'); // Replace 'and' with '&'
  corrected = corrected.replace(/\^/g, '&'); // Replace '^' with '&'
  corrected = corrected.replace(/\bor\b/g, '∨'); // Replace 'or' with '∨'
  corrected = corrected.replace(/~/g, '¬'); // Replace '~' with '¬'
  corrected = corrected.replace(/\bnot\b/g, '¬'); // Replace 'not' with '¬'
  corrected = corrected.replace(/  /g, ' ');

  return corrected;
};
