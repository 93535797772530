import React from 'react';

const ThesisLogicDisplay = ({ logic, handleMouseEnter, handleMouseLeave }) => {
    
   const renderLogic = (logicText) => {
    const pattern = /\(([\w-]+),\s*(\d+)\)/g;
    const parts = [];
    let lastIndex = 0;

    logicText.replace(pattern, (match, dataID, optionNumber, offset) => {
        if (offset > lastIndex) {
            parts.push(
                <React.Fragment key={`text-${lastIndex}`}>
                    {logicText.slice(lastIndex, offset)}
                </React.Fragment>
            ); // Add text before match
        }
        parts.push(
            <span
                key={`${dataID}-${optionNumber}`}
                style={{ cursor: 'pointer', color: 'var(--color-highlight)' }}
                onMouseEnter={(e) => handleMouseEnter(dataID, optionNumber, e)}
                onMouseLeave={handleMouseLeave}
            >
                {match}
            </span>
        );
        lastIndex = offset + match.length;
    });

    if (lastIndex < logicText.length) {
        parts.push(
            <React.Fragment key={`text-end`}>
                {logicText.slice(lastIndex)}
            </React.Fragment>
        ); // Add remaining text
    }

    return parts;
};


    return <>{renderLogic(logic || '')}</>;
};

export default ThesisLogicDisplay;
