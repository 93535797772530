import React, { createContext } from 'react';

export const CourseIDContext = createContext();

export const CourseIDProvider = ({ children, courseID }) => {
    //console.log("courseID in CourseIDProvider:", courseID); // Debugging line

  return (
    <CourseIDContext.Provider value={{ courseID }}>
      {children}
    </CourseIDContext.Provider>
  );
};
