/**
 * Calculates the total word count of all essay answers.
 *
 * @param {Array} answers - Array of student answer objects.
 * @returns {number} - Total word count of all essay answers.
 */
export const calculateTotalEssayWordCount = (answers) => {
  if (!Array.isArray(answers)) {
    throw new Error('Input must be an array of answer objects.');
  }

  let totalWordCount = 0;

  answers.forEach((answer) => {
    const essayAnswer = answer.questionEssayAnswer;

    if (typeof essayAnswer === 'string' && essayAnswer.trim().length > 0) {
      // Split the essay answer into words
      const words = essayAnswer.trim().split(/\s+/);
      totalWordCount += words.length;
    }
    // If essayAnswer is null, undefined, or not a string, skip it
  });

  return totalWordCount;
};
