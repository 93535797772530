import React, { useContext, useMemo, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { CourseDesignContext } from '../context/CourseDesignContext';
import QAndA from '../organisms/QAndA';
import GradingFields from '../atoms/GradingFields';
// Import utility function
import { fetchUserAnswersAsOfDate, fetchStudentGrades, determineTargetDate, fetchStudentAnswersForExam } from '../utils/serverFetchAndAddUtilities';
import { CourseIDContext } from '../context/CourseIDContext';

const GradingInterface = ({ evaluationId, username, onReturn }) => {

  const { courseID } = useContext(CourseIDContext);
  const { courseDesignState, questionByQuestionGrading } = useContext(CourseDesignContext);
  const [answerHistory, setAnswerHistory] = useState(null); // State for storing answer history
  const [loading, setLoading] = useState(true); // Loading state
  const [targetDate, setTargetDate] = useState(null); // Target date for grading
  
  // reload answers when custom due date is added
  const handleCustomDueDateChange = (newDueDate) => {
    setTargetDate(newDueDate); // Update targetDate with the new custom due date
  };

// determine the target date for grading based on exam due date or custom due date (if there is one)
  useEffect(() => {
  const fetchTargetDate = async () => {
    if (!courseID || !username || !evaluationId) return;

    const targetDate = await determineTargetDate(courseID, username, evaluationId, courseDesignState, fetchStudentGrades);
    setTargetDate(targetDate);
  };

  fetchTargetDate();
}, [courseID, username, evaluationId, courseDesignState]);

// Log targetDate whenever it updates
useEffect(() => {
  console.log('Updated target date:', targetDate);
}, [targetDate]);

  // Fetch answer history when the component mounts or when courseID/username changes
useEffect(() => {
  const fetchAnswers = async () => {
    if (!courseID || !username || !targetDate) return;

    setLoading(true);
    try {
      const answers = await fetchStudentAnswersForExam(courseID, username, targetDate, fetchUserAnswersAsOfDate);
      setAnswerHistory(answers); // Set the processed answers directly to state
    } catch (error) {
      setAnswerHistory(null);
    } finally {
      setLoading(false);
    }
  };

  fetchAnswers();
}, [courseID, username, targetDate]);




const sectionsToShow = useMemo(() => {
  const evaluation = courseDesignState.find(item => item.dataID === evaluationId && item.dataType === 'evaluation');
  return evaluation?.questionOptions || [];
}, [courseDesignState, evaluationId]);

  console.log('evaluation id from grading interface:', evaluationId);
  console.log('sections to show calculated in grading interface:', sectionsToShow);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grading-interface" style={{ display: 'flex', width: '100%', height: '100vh' }}>
      <div className="left-panel" style={{ width: '40%', padding: '20px', overflowY: 'auto', height: '100%', backgroundColor: 'white' }}>
        <button onClick={onReturn} className="return-button">
          <FontAwesomeIcon icon={faArrowLeft} /> Return to Grading List
        </button>
        <GradingFields
          username={username}
          evaluationId={evaluationId}
          onCustomDueDateChange={handleCustomDueDateChange} // Pass handler to GradingFields
 />
      </div>
      <div className="right-panel" style={{ width: '60%', padding: '20px', overflowY: 'auto', height: '100%'}}>
        <QAndA
          username={username}
          ignoreCurrentCourseDate={true}
          gradeEdit={questionByQuestionGrading}
          answersReadOnly={true}
          sectionsToShow={sectionsToShow}
          showSectionHeaders={true}
          evaluationID={evaluationId}
          studentAnswers={answerHistory} // Pass answerHistory to QAndA
        />
      </div>
    </div>
  );
};

export default GradingInterface;
