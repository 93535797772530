export const validateLogic = (input) => {
// get rid of leading/trailing whitespace and replace multiple spaces with a single space
        const cleanedInput = input.trim().replace(/\s+/g, ' ');

    // Step 1: Convert atomic sentences to 'S'
    let convertedInput = cleanedInput.replace(/\([^,()]+\)/g, 'S').replace(/\([^,()]+, ?[^,()]+\)/g, 'S');
    console.log('After converting atomic sentences to S:', convertedInput);

    // If the result is already 'S', validation is true
    if (convertedInput === 'S') {
        console.log('Validation result: true');
        return true;
    }

    // Loop to repeat steps 2-5 up to 10 times or until validation is true
    for (let i = 0; i < 10; i++) {
        // Step 2: Replace every '¬S' with 'S'
        convertedInput = convertedInput.replace(/¬S/g, 'S');
        console.log(`Iteration ${i + 1} - After replacing ¬S with S:`, convertedInput);
        if (convertedInput === 'S') {
            console.log('Validation result: true');
            return true;
        }

        // Step 3: Replace every conjunction of 'S' (e.g., 'S & S' or 'S & S & S') with 'S'
        convertedInput = convertedInput.replace(/S(\s*&\s*S)+/g, 'S');
        console.log(`Iteration ${i + 1} - After replacing conjunctions of S with S:`, convertedInput);
        if (convertedInput === 'S') {
            console.log('Validation result: true');
            return true;
        }

        // Step 4: Replace every disjunction of 'S' (e.g., 'S ∨ S' or 'S ∨ S ∨ S') with 'S'
        convertedInput = convertedInput.replace(/S(\s*∨\s*S)+/g, 'S');
        console.log(`Iteration ${i + 1} - After replacing disjunctions of S with S:`, convertedInput);
        if (convertedInput === 'S') {
            console.log('Validation result: true');
            return true;
        }

        // Step 5: Replace every '(S)' with 'S'
        convertedInput = convertedInput.replace(/\(S\)/g, 'S');
        console.log(`Iteration ${i + 1} - After replacing (S) with S:`, convertedInput);
        if (convertedInput === 'S') {
            console.log('Validation result: true');
            return true;
        }
    }

    // If none of the steps resulted in 'S' after 10 iterations, validation is false
    console.log('Validation result: false');
    return false;
};
