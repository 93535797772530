import React from 'react';
import '../pages/CourseDesign.css'; // Ensure this CSS file is imported


const CourseDesignQuestion = ({ questionType, dataType, question, options, value, onChange, inputStyle = {}, questionStyle = {} // Set default to an empty object; can use these to add inline styles to question and input fields
 }) => {
  switch (questionType) {
    case 'text':
    case 'number': // Handle number input but treat as string
      return (
        <div>
          <label style={questionStyle} className='courseDesignQuestionField'>{question}</label>
          <input
            type={questionType}
            value={value || ''}
            onChange={(e) => onChange(e.target.value)} // Pass as string
            className="input-field"
            style={inputStyle} // can assign inputStyle to various instances to modify the look of the input field
          />
        </div>
      );
      case 'select':
      return (
        <div>
          <label>{question}</label>
          <div>
            {options.map((option, index) => (
              <label key={index} className={`radioButton ${value === option ? 'selected' : ''}`}>
                <input
                  type="radio"
                  name={dataType}
                  value={option}
                  checked={value === option}
                  onChange={(e) => onChange(e.target.value)}
                  style={{ display: 'none' }} // Hide the actual radio input
                />
                {option}
              </label>
            ))}
          </div>
        </div>
      );
    case 'date':
      return (
        <div>
          <label style={questionStyle}>{question}</label>
          <input
            type="date"
            value={value}
            onChange={(e) => onChange(e.target.value)}
                        style={inputStyle} // can assign inputStyle to various instances to modify the look of the input field
          />
        </div>
      );
    default:
      return null;
  }
};

export default CourseDesignQuestion;
