import React, { useContext, useEffect } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import CourseDesignQuestion from './CourseDesignQuestion';
import './GradingFormat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const GradingFormat = () => {
  const { courseDesignState, handleChange, removeFromDatabase, hasContradictionItems } = useContext(CourseDesignContext);
  const questionByQuestionGrading = courseDesignState.find(item => item.dataType === 'questionByQuestionGrading')?.dataText;
  const giveStudentsComments = courseDesignState.find(item => item.dataType === 'giveStudentsComments')?.dataText;
  const numberOfSections = courseDesignState.filter(item => item.dataType === 'sectionName').length;
  const evaluations = courseDesignState.filter(item => item.dataType === 'evaluation');
  const courseUsesGrading = courseDesignState.find(item => item.dataType === 'courseUsesGrading')?.dataText;
  const usesGradesForExams = courseDesignState.find(item => item.dataType === 'usesGradesForExams')?.dataText;
const numericVsLetterManualGrades = courseDesignState.find(item => item.dataType === 'numericVsLetterManualGrades')?.dataText;


 const handleAddEvaluation = () => {
  const newEvaluation = {
    dataType: 'evaluation',
    dataID: Math.random().toString(36).substring(2, 15).toUpperCase(), // Ensure this is unique
    dataText: '', // Default text empty
    questionOptions: '',
    questionCourseDate: ''
  };
  // This now only sets up data for a new UI element, not sending to state yet.
  handleChange('evaluation', newEvaluation.dataID, '', {
    questionOptions: '',
    questionCourseDate: ''
  });
};

  const handleDeleteEvaluation = (evaluationID) => {
    removeFromDatabase('evaluation', evaluationID);
  };
const sections = courseDesignState
  .filter(item => item.dataType === 'sectionName')
  .sort((a, b) => parseFloat(a.dataIndex) - parseFloat(b.dataIndex)) // Adding sorting by dataIndex
  .slice(0, numberOfSections)
  .map(item => ({ id: item.dataID, name: item.dataText }));

  const getAvailableSections = (currentEvaluationIndex) => {
    const selectedSections = evaluations.flatMap((evaluation, index) => 
      index !== currentEvaluationIndex ? evaluation.questionOptions.split(',').filter(Boolean) : []
    );
    return sections.filter(section => !selectedSections.includes(section.id));
  };
    
 useEffect(() => {
    // Set default values if they don't exist in the state
    if (!courseDesignState.find(item => item.dataType === 'penaltyForUnansweredMCQ')) {
      handleChange('penaltyForUnansweredMCQ', '0', '5');
    }
    if (!courseDesignState.find(item => item.dataType === 'penaltyForUnresolvedContradictions')) {
      handleChange('penaltyForUnresolvedContradictions', '0', '5');
    }
    if (!courseDesignState.find(item => item.dataType === 'isThereBoxForGradeAdjustment')) {
      handleChange('isThereBoxForGradeAdjustment', '0', 'Yes');
    }
  }, [courseDesignState, handleChange]);

  const getValue = (dataType) => {
    const item = courseDesignState.find(item => item.dataType === dataType);
    return item ? item.dataText : '';
  };
 

  return (
    <>
      <div>There are various ways to provide grades or comments (or both) to students using the website. This page lets you pick from various options. Then you can pick the number of different evaluations you will have in the course, which course sections each will include, and the due date for each. You can always change all of this later. </div>
      
      <br></br>
      
      <CourseDesignQuestion
        questionType="select"
        dataType="usesGradesForExams"
        dataID="0"
        question="Would you like your evaluations of student work to include grades? (These might be numeric or letter grades.)"
        options={['Yes', 'No']}
        value={usesGradesForExams}
        onChange={(value) => handleChange('usesGradesForExams', '0', value)}
      />

<br></br>
      
      {usesGradesForExams === 'Yes' && (
        <>
          <CourseDesignQuestion
            questionType="select"
            dataType="questionByQuestionGrading"
            dataID="0"
            question="There are two main ways to grade students' work on the site. One option is to give question-by-question numeric grades, which are then used to calculate an overall grade via a formula you can select. The other option is just to grade the work as a whole, without question-by-question grades. Would you like to use question-by-question grades?"
            options={['Yes', 'No']}
            value={questionByQuestionGrading}
            onChange={(value) => handleChange('questionByQuestionGrading', '0', value)}
        />
          <br></br>
          </>
          )}

     
      

      {usesGradesForExams === 'Yes' && questionByQuestionGrading === 'No' && (
        <>
        <CourseDesignQuestion
            questionType="select"
            dataType="numericVsLetterManualGrades"
            dataID="0"
            question="Would you like to enter numeric grades or letter grades?"
            options={['Numeric', 'Letter']}
            value={numericVsLetterManualGrades}
            onChange={(value) => handleChange('numericVsLetterManualGrades', '0', value)}
        />
          <br></br>
          </>
          )}

      
        
      {usesGradesForExams === 'Yes' && questionByQuestionGrading === 'Yes' && (
        <div className="grading-formula">
         <div>
            The default scoring system for evaluating student answers grades each essay question
            from 0-10 (with decimals allowed), and generates the final grade by averaging these
            and multiplying the result by 10. You can add penalties for unanswered multiple choice
            questions{hasContradictionItems && (', unresolved contradictions,')} and can add a place where you can make grade
            adjustments for any reason.
          </div>

          <br></br>

          <CourseDesignQuestion
            questionType="number"
            dataType="penaltyForUnansweredMCQ"
            question="Penalty for an unanswered multiple choice question:"
            value={getValue('penaltyForUnansweredMCQ')}
            onChange={(value) => handleChange('penaltyForUnansweredMCQ', '0', value)}
          />

          <br></br>

          {hasContradictionItems && (
            <CourseDesignQuestion
              questionType="number"
              dataType="penaltyForUnresolvedContradictions"
              question="Penalty for unresolved contradictions:"
              value={getValue('penaltyForUnresolvedContradictions')}
              onChange={(value) => handleChange('penaltyForUnresolvedContradictions', '0', value)}
            />
          )}

          <br></br>

          <CourseDesignQuestion
            questionType="select"
            dataType="isThereBoxForGradeAdjustment"
            question="Would you like to have a box for grade adjustments?"
            options={['Yes', 'No']}
            value={getValue('isThereBoxForGradeAdjustment')}
            onChange={(value) => handleChange('isThereBoxForGradeAdjustment', '0', value)}
          />
        </div>
      )}

        <CourseDesignQuestion
            questionType="select"
            dataType="giveStudentsComments"
            dataID="0"
            question="Would you like to give students comments on their work?"
            options={['Yes', 'No']}
            value={giveStudentsComments}
            onChange={(value) => handleChange('giveStudentsComments', '0', value)}
          />

      <br></br>
      
         <div>
           For each evaluation in the course, pick a name for the evaluation, pick the course sections which will be part of the evaluation, and pick a due date for the evaluation. (You can always change all of this later.)</div>
            

     <div>
      {evaluations.map((evaluation, index) => {
        const availableSections = getAvailableSections(evaluation.dataID);
        return (
<div key={evaluation.dataID} style={{ marginBottom: '8px' }}>
            <input
              type="text"
              className='input-field'
              value={evaluation.dataText}
              onChange={(e) => handleChange('evaluation', evaluation.dataID, e.target.value, {
                questionOptions: evaluation.questionOptions,
                questionCourseDate: evaluation.questionCourseDate
              })}
              placeholder={`Exam #${index + 1} name`}
              style={{ width: '500px', marginBottom: '10px' }}
            />
            <div style={{ marginLeft: '40px' }}>
              {sections.map(section => (
                <label 
                  key={section.id} 
                  className={`radioButton ${evaluation.questionOptions.includes(section.id) ? 'selected' : ''}`}
                  style={{ fontSize: '12px', marginBottom: '10px', opacity: availableSections.some(s => s.id === section.id) || evaluation.questionOptions.includes(section.id) ? 1 : 0.5 }}
                >
                  <input
                    type="checkbox"
                    name={`evaluation-${evaluation.dataID}-section`}
                    value={section.id}
                    checked={evaluation.questionOptions.includes(section.id)}
                    onChange={() => {
                      const currentOptions = evaluation.questionOptions.split(',').filter(Boolean);
                      const updatedOptions = currentOptions.includes(section.id)
                        ? currentOptions.filter(id => id !== section.id)
                        : [...currentOptions, section.id];
                      handleChange('evaluation', evaluation.dataID, evaluation.dataText, {
                        questionOptions: updatedOptions.join(','),
                        questionCourseDate: evaluation.questionCourseDate
                      });
                    }}
                    disabled={!availableSections.some(s => s.id === section.id) && !evaluation.questionOptions.includes(section.id)}
                    style={{ display: 'none' }}
                  />
                  {section.name}
                </label>
              ))}
            </div>
            <CourseDesignQuestion
              questionType="date"
              dataType={`evaluation-${evaluation.dataID}-date`}
              dataID={evaluation.dataID}
              question={`Due date for ${evaluation.dataText || `Exam #${index + 1}`}:`}
              value={evaluation.questionCourseDate}
              onChange={(value) => handleChange('evaluation', evaluation.dataID, evaluation.dataText, {
                questionOptions: evaluation.questionOptions,
                questionCourseDate: value
              })}
              inputStyle={{ width: '200px', marginLeft: '40px' }}
              questionStyle={{ marginLeft: '40px' }}
            />
            <button style={{ marginLeft: '40px', marginTop: '10px', marginBottom: '30px' }} onClick={() => handleDeleteEvaluation(evaluation.dataID)}>
              <FontAwesomeIcon icon={faTrash} /> 
            </button>
          </div>
        );
      })}
      <button onClick={handleAddEvaluation}>Add Evaluation</button>
    </div>

   
          
      
      </>
  );
}

export default GradingFormat;





