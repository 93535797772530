import React, { useContext, useEffect, useState } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import { StudentAnswerContext } from '../context/StudentAnswerContext';

function RadioQuestion({ questionID, user, answersReadOnly, userAnswers }) {
  const { courseDesignState } = useContext(CourseDesignContext);
  const { handleAnswerChange } = useContext(StudentAnswerContext);
 // const userAnswers = useMemo(() => studentAnswersState[user] || [], [studentAnswersState, user]);

  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const question = courseDesignState.find(item => item.dataID === questionID && item.dataType === 'question');

 useEffect(() => {
  const existingAnswer = userAnswers.find(answer => answer.questionID === questionID);
  if (existingAnswer) {
    setSelectedAnswer(existingAnswer.questionAnswer || null);
  } else {
    setSelectedAnswer(null); // Clear state when no answer exists
  }
}, [userAnswers, questionID]);


  if (!question) {
    return <div>Question not found</div>;
  }

  const questionText = question.dataText;
  const questionOptions = question.questionOptions ? question.questionOptions.split('|||') : [];

  const handleChange = (optionIndex) => {
    if (!answersReadOnly) {
      const optionValue = (optionIndex + 1).toString();
      setSelectedAnswer(optionValue);
      handleAnswerChange(user, questionID, optionValue);
    }
  };

  const getButtonClass = (index) => {
    const isSelected = selectedAnswer === (index + 1).toString();
    return isSelected ? "radioButton selected" : "radioButton";
  };

  const renderOption = (option, index) => {
    if (answersReadOnly) {
      return (
        <div key={index} className={getButtonClass(index)}>
          {option}
        </div>
      );
    } else {
      return (
        <label key={index} className={getButtonClass(index)}>
          <input
            type="radio"
            className="radioInput"
            name={questionID}
            checked={selectedAnswer === (index + 1).toString()}
            onChange={() => handleChange(index)}
          />
          {option}
        </label>
      );
    }
  };

  return (
    <div className='questionContainer'>
      <p className='questionText'>{questionText}</p>
      <div className='optionsContainer'>
        {questionOptions.map((option, index) => renderOption(option, index))}
      </div>
    </div>
  );
}

export default RadioQuestion;