// atoms/QuestionDesignTopicMenu.js

import React from 'react';

const QuestionDesignTopicMenu = ({ topics, selectedTopicIndex, handleTopicClick, handleThesisClick }) => (
    <ul>
        {topics.map((topic) => (
            <li
                key={topic.dataID}
                onClick={() => handleTopicClick(topic.dataID)}
                className={selectedTopicIndex === parseInt(topic.dataIndex, 10) ? 'active' : ''}
            >
                {topic.dataText}
            </li>
        ))}
           {/* Add Thesis item */}
        <li
            onClick={handleThesisClick}
            className={selectedTopicIndex === 'thesisMode' ? 'active thesis-item' : 'thesis-item'}
        >
            Theses
        </li>
    </ul>
);

export default QuestionDesignTopicMenu;
