import React, { useContext, useState, useEffect } from 'react';

import CourseDesignGeneral from '../organisms/CourseDesignGeneral';
import CourseDesignScheduleTopics from '../organisms/CourseDesignScheduleTopics';
import CourseDesignQuestionDesign from '../organisms/CourseDesignQuestionDesign';
import CourseDesignUsers from '../organisms/CourseDesignUsers';
import { CourseDesignContext } from '../context/CourseDesignContext';
import { Navigate  } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import './CourseDesign.css';
import GradingModule from '../organisms/GradingModule';
import { CourseIDContext } from '../context/CourseIDContext';
import LoginPage from './NewLoginPage';

const CourseDesign = () => {
  const { courseDesignState, handleChange } = useContext(CourseDesignContext);
  const [activeComponent, setActiveComponent] = useState('general');
  const { isLoggedIn } = useContext(AuthContext);
  const [status, setStatus] = useState(null);
  const { courseID } = useContext(CourseIDContext);

  // Fetch the user's status if logged in
  useEffect(() => {
    if (isLoggedIn) {
      const fetchStatus = async () => {
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        const username = sessionStorage.getItem('username'); // Assuming username is stored in sessionStorage

        try {
          const response = await fetch(`${apiUrl}/api/authenticate/status/${courseID}/${username}`);
          if (response.ok) {
            const { status } = await response.json();
            setStatus(status?.toLowerCase());
          } else {
            console.error('Failed to fetch status.');
            setStatus('unauthorized');
          }
        } catch (error) {
          console.error('Error fetching status:', error);
          setStatus('error');
        }
      };

      fetchStatus();
    }
  }, [isLoggedIn, courseID]);
  
  useEffect(() => {
    const lastVisited = courseDesignState.find(item => item?.dataType === 'courseDesignMainMenuLastVisited')?.dataText;
    if (lastVisited) {
      setActiveComponent(lastVisited);
    }
  }, [courseDesignState]);

 


  
  



  const handleMenuChange = (menuName) => {
    setActiveComponent(menuName);
    handleChange('courseDesignMainMenuLastVisited', '0', menuName);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'general':
        return <CourseDesignGeneral />;
      case 'sections':
        return <CourseDesignScheduleTopics />;
      case 'questions':
        return <CourseDesignQuestionDesign />;
      case 'users':
        return <CourseDesignUsers />;
      case 'grading':
        return <GradingModule />;
      default:
        return <CourseDesignGeneral />;
    }
  };
  // conditonally render button for grading module
  const showGradingModule = courseDesignState.find(item => item?.dataType === 'courseUsesGrading')?.dataText === 'Yes';



  // if user is not logged in return the LoginPage component

  if (!isLoggedIn) {
    return <LoginPage onLoginSuccess={() => console.log('hello')} />;
  }
  
  // if user is logged in but not an instructor redirect to url '/courseid/'
  if (isLoggedIn && status && status !== 'instructor') {
    return <Navigate to={`/${courseID}/`} />;
  }


  // if user is logged in and is an instructor
  if (isLoggedIn && status && status === 'instructor') {
    


    return (
      <div className='masterCourseDesignContainer'>
        <nav>
          <button
            className={`master-menu-button ${activeComponent === 'general' ? 'active' : ''}`}
            onClick={() => handleMenuChange('general')}
          >
            General
          </button>
          <button
            className={`master-menu-button ${activeComponent === 'sections' ? 'active' : ''}`}
            onClick={() => handleMenuChange('sections')}
          >
            Schedule
          </button>
          <button
            className={`master-menu-button ${activeComponent === 'questions' ? 'active' : ''}`}
            onClick={() => handleMenuChange('questions')}
          >
            Questions
          </button>
          <button
            className={`master-menu-button ${activeComponent === 'users' ? 'active' : ''}`}
            onClick={() => handleMenuChange('users')}
          >
            Users
          </button>
        
          {showGradingModule && (
            <button
              className={`master-menu-button ${activeComponent === 'grading' ? 'active' : ''}`}
              onClick={() => handleMenuChange('grading')}
            >
              Grading
            </button>
          )}
        </nav>

        <div>
          {renderComponent()}
        </div>
      </div>
    );
  };
}

export default CourseDesign;
