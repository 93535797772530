import React, { useState } from 'react';

function MainMenu({ onMenuSelect, isSmallScreen, showDescriptionButton, showSyllabusButton, courseTitle }) {
  const [activeButton, setActiveButton] = useState(null);

  const handleClick = (buttonId) => {
    setActiveButton(buttonId);
    onMenuSelect(buttonId);
  };

  return (
    <div className="menu">
      {courseTitle ? ( // Conditionally render courseTitle if provided
        <div className="course-title-display">{courseTitle}</div>
      ) : (
        <>
          {showDescriptionButton && (
            <button
              className={activeButton === 'description' ? 'selectedButton' : ''}
              onClick={() => handleClick('description')}
            >
              {isSmallScreen ? 'desc' : 'course description'}
            </button>
          )}
          {showSyllabusButton && (
            <button
              className={activeButton === 'syllabus' ? 'selectedButton' : ''}
              onClick={() => handleClick('syllabus')}
            >
              {isSmallScreen ? 'sched' : 'schedule'}
            </button>
          )}
          <button
            className={activeButton === 'myphi' ? 'selectedButton' : ''}
            onClick={() => handleClick('myphi')}
          >
            {isSmallScreen ? 'myphi' : 'my philosophy'}
          </button>
        </>
      )}
    </div>
  );
}

export default MainMenu;
