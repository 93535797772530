import React, { useContext, useState, useEffect, useMemo } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import { initializeSelection, generateUniqueId } from '../utils/courseDesignUtilities';
import QuestionDesignSectionMenu from '../atoms/QuestionDesignSectionMenu';
import QuestionDesignTopicMenu from '../atoms/QuestionDesignTopicMenu';
import QuestionDesignQuestionList from '../atoms/QuestionDesignQuestionList';
import QuestionCreationButton from '../atoms/QuestionCreationButton';
import ThesisDesign from '../molecules/ThesisDesign';
import QAndA from './QAndA'; // Import the QAndA component
import { AuthContext } from '../context/AuthContext';



const CourseDesignQuestionDesign = () => {
    const { courseDesignState, handleChange } = useContext(CourseDesignContext);
    const [selectedTopicDataIndex, setSelectedTopicDataIndex] = useState(null);
    const [selectedSectionIndex, setSelectedSectionIndex] = useState(() => {
        const savedIndex = courseDesignState.find(item => item.dataType === 'questionDesignMenuLastVisited')?.dataText;
        return savedIndex !== undefined ? parseInt(savedIndex) : 0;
    });
    const [selectedTopicIndex, setSelectedTopicIndex] = useState(() => {
        const savedIndex = courseDesignState.find(item => item.dataType === 'questionDesignSubMenuLastVisited')?.dataText;
        return savedIndex !== undefined ? parseInt(savedIndex) : 0;
    });
    // get user from AuthContext
    const { username } = useContext(AuthContext);
    console.log('user:', username);

    // logic for switching between design view student view and thesis view
    const [viewMode, setViewMode] = useState('designer');

    // Add this new handler for the "Theses" click
const handleThesisClick = () => {
    setSelectedTopicDataIndex(null); // Deselect any topic
    setSelectedTopicIndex('thesisMode');
    setViewMode('thesisMode'); // Set view mode to thesisMode
};
      
const questions = useMemo(() => {
        return courseDesignState.filter(
            item => item.dataType === 'question' && item.questionCourseDate === selectedTopicDataIndex
        );
    }, [courseDesignState, selectedTopicDataIndex]); // Memoize based on courseDesignState and selectedTopicDataIndex




    const sections = courseDesignState
        .filter(item => item.dataType === 'sectionName')
        .sort((a, b) => parseInt(a.dataIndex) - parseInt(b.dataIndex));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
//console.log('runs only on mount');
        initializeSelection(courseDesignState, sections, setSelectedSectionIndex, setSelectedTopicIndex, setSelectedTopicDataIndex, handleChange);
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const getTopicsForSelectedSection = (sectionIndex = selectedSectionIndex) => {
        if (sectionIndex === null) return [];

        const currentSection = sections[sectionIndex];
        const nextSection = sections[sectionIndex + 1];

        return courseDesignState
            .filter(item =>
                item.dataType === 'topic' &&
                item.questionOptions === 'yes' &&
                parseInt(item.dataIndex) > parseInt(currentSection.dataIndex) &&
                (!nextSection || parseInt(item.dataIndex) < parseInt(nextSection.dataIndex))
            )
            .sort((a, b) => parseInt(a.dataIndex) - parseInt(b.dataIndex));
    };

const handleSectionClick = (index) => {
    setSelectedSectionIndex(index);
    handleChange('questionDesignMenuLastVisited', '0', index.toString());

    const firstTopicInSection = getTopicsForSelectedSection(index)[0];
    if (firstTopicInSection) {
        setSelectedTopicIndex(parseInt(firstTopicInSection.dataIndex, 10));
        setSelectedTopicDataIndex(firstTopicInSection.dataID);
        handleChange('questionDesignSubMenuLastVisited', '0', firstTopicInSection.dataID);
    }
 // Only switch to designer mode if currently in thesis mode
    setViewMode((prevMode) => (prevMode === 'thesisMode' ? 'designer' : prevMode));};


    const handleTopicClick = (dataID) => {
        const selectedTopic = courseDesignState.find(item => item.dataType === 'topic' && item.dataID === dataID);
        if (selectedTopic) {
            setSelectedTopicDataIndex(selectedTopic.dataID);
            setSelectedTopicIndex(parseInt(selectedTopic.dataIndex, 10));
            handleChange('questionDesignSubMenuLastVisited', '0', selectedTopic.dataID);
             // Only switch to designer mode if currently in thesis mode
    setViewMode((prevMode) => (prevMode === 'thesisMode' ? 'designer' : prevMode));

        }
    };

    const handleCreateQuestionClick = () => {
        console.log('Creating new question with index', questions.length + 1);
        const newId = generateUniqueId();
        const nextDataIndex = questions.length + 2;
        const newQuestion = {
            dataType: 'question',
            dataID: newId,
            dataText: '',
            dataIndex: nextDataIndex.toString(),
            questionType: 'LongAnswer',
            questionOptions: '',
            questionLogic: '',
            questionCourseDate: selectedTopicDataIndex
        };
        // Update courseDesignState directly instead of using setQuestions
        handleChange('question', newQuestion.dataID, '', {
            dataIndex: newQuestion.dataIndex,
            questionType: newQuestion.questionType,
            questionOptions: newQuestion.questionOptions,
            questionLogic: newQuestion.questionLogic,
            questionCourseDate: selectedTopicDataIndex
        });
    };

    const toggleViewMode = () => {
    setViewMode(prevMode => prevMode === 'designer' ? 'student' : 'designer');
};


    return (
        <div className="question-design-wrapper">
            <QuestionDesignSectionMenu 
                sections={sections} 
                selectedSectionIndex={selectedSectionIndex} 
                handleSectionClick={handleSectionClick} 
            />
            <div>
                <div className="left-menu-bar">
                    <QuestionDesignTopicMenu 
                        topics={getTopicsForSelectedSection(selectedSectionIndex)} 
                        selectedTopicIndex={selectedTopicIndex}
                        handleTopicClick={handleTopicClick} 
                            handleThesisClick={handleThesisClick} // Add this handler

                    />
                </div>
               <div className="question-design-content">
    {viewMode === 'thesisMode' ? (
        <ThesisDesign />
    ) : (
        <>
            <div className="view-toggle">
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={viewMode === 'student'}
onChange={toggleViewMode}                    />
                    <span className="slider round"></span>
                </label>
                <span className="toggle-label">
                    {viewMode === 'designer' ? 'design view' : 'student view'}
                </span>
            </div>
            {viewMode === 'designer' && selectedSectionIndex !== null && selectedTopicIndex !== null && (
                <div>
                    <QuestionDesignQuestionList 
                        selectedTopicDataIndex={selectedTopicDataIndex} 
                    />
                    <QuestionCreationButton onCreate={handleCreateQuestionClick} />
                </div>
            )}
           {viewMode === 'student' && (
    <div>
        <QAndA
                                            username={username}
                                            firstSectionToShow={1}
                                            limitToSpecificDay={true}
                                            specificDayToShow={selectedTopicDataIndex}
                                            ignoreCurrentCourseDate={true}
        />
    </div>
)}
        </>
    )}
</div>

            </div>
        </div>
    );
};

export default CourseDesignQuestionDesign;
