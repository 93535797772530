import React, { useContext } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import { StudentGradesContext } from '../context/StudentGradesContext'; // Importing the context
import Rubric from './Rubric';

function StudentViewGradingDetails({ username, evaluationID }) {
  const { studentGradesState } = useContext(StudentGradesContext);
  const { courseDesignState, hasContradictionItems } = useContext(CourseDesignContext);

  // check the courseDesignState to see whether it contains an item with a dataType of "rubricWhereToShow" and a dataText that includes "3" and if so set showRubric to true
  const showRubric = courseDesignState.find(
    (item) => item.dataType === 'rubricWhereToShow' && item.dataText.includes('3')
  );

  // Get the grade entries for the specific user
  const userGrades = studentGradesState[username] || [];

  // Unanswered Multiple Choice Questions
  const unansweredMCQsEntry = userGrades.find(
    (g) =>
      g.evaluationDataID === evaluationID && g.gradingType === 'unansweredMCQs'
  );
  const unansweredMCQs = unansweredMCQsEntry
    ? unansweredMCQsEntry.individualGradingData
    : '0';

  // Unresolved Contradictions
  const unresolvedContradictionsEntry = userGrades.find(
    (g) =>
      g.evaluationDataID === evaluationID &&
      g.gradingType === 'unresolvedContradictions'
  );
  const unresolvedContradictions = unresolvedContradictionsEntry
    ? unresolvedContradictionsEntry.individualGradingData
    : '0';

  // Question by Question Average
  const questionGrades = userGrades
    .filter(
      (g) =>
        g.evaluationDataID === evaluationID && g.gradingType === 'questionGrade'
    )
    .map((g) => parseFloat(g.individualGradingData))
    .filter((grade) => !isNaN(grade)); // Exclude invalid numbers

  const questionByQuestionAverage =
    questionGrades.length > 0
      ? (
          questionGrades.reduce((sum, grade) => sum + grade, 0) /
          questionGrades.length
        ).toFixed(2)
      : 'No grades';

  return (
    <div className='grading-details'>
      <div>Question by question average: {questionByQuestionAverage}</div>
      {hasContradictionItems && (<div>Unresolved contradictions: {unresolvedContradictions}</div>)}
          <div>Unanswered multiple choice questions: {unansweredMCQs}</div>
          
      {showRubric && <Rubric />}
    </div>
  );
}

export default StudentViewGradingDetails;
