import React, { useState } from 'react';
import axios from 'axios';

function AdminCourseManagement() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [courseID, setCourseID] = useState('');
  const [instructorFullName, setInstructorFullName] = useState('');
  const [instructorUserID, setInstructorUserID] = useState('');
  const [message, setMessage] = useState('');
  const [creationError, setCreationError] = useState('');

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    const hardcodedPassword = 'Wittgenstein1953';

    if (passwordInput === hardcodedPassword) {
      setIsAuthenticated(true);
      setErrorMessage('');
    } else {
      setErrorMessage('Incorrect password. Please try again.');
    }
  };

  const handleCreateCourse = async (e) => {
    e.preventDefault();
    setMessage('');
    setCreationError('');

    try {
      const response = await axios.post(`${apiUrl}/api/admin/create-course`, {
        courseID,
        instructorFullName,
        instructorUserID,
      });
      setMessage(response.data.message);
    } catch (error) {
      console.error('Error creating course:', error);
      setCreationError('Failed to create course');
    }
  };

  if (!isAuthenticated) {
    return (
      <div>
        <h2>Admin Access</h2>
        <form onSubmit={handlePasswordSubmit}>
          <input
            type="password"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
            placeholder="Enter admin password"
          />
          <button type="submit">Submit</button>
        </form>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </div>
    );
  }

  return (
    <div>
      <h1>Admin Course Management</h1>
      <form onSubmit={handleCreateCourse}>
        <label>
          Course ID:
          <input
            type="text"
            value={courseID}
            onChange={(e) => setCourseID(e.target.value)}
            required
          />
        </label>
        <label>
          Instructor Full Name:
          <input
            type="text"
            value={instructorFullName}
            onChange={(e) => setInstructorFullName(e.target.value)}
            required
          />
        </label>
        <label>
          Instructor User ID:
          <input
            type="text"
            value={instructorUserID}
            onChange={(e) => setInstructorUserID(e.target.value)}
            required
          />
        </label>
        <button type="submit">Create Course</button>
      </form>
      {message && <p style={{ color: 'green' }}>{message}</p>}
      {creationError && <p style={{ color: 'red' }}>{creationError}</p>}
    </div>
  );
}

export default AdminCourseManagement;
