// molecules/ThesisDesign.js
/* eslint-disable jsx-a11y/accessible-emoji */


import React, { useContext, useState } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext'; // Import context
import { validateLogic } from '../utils/validateLogic'; // Import validation utility
import HelpToolTip from '../atoms/HelpToolTip'; // Import HelpToolTip component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCopy, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useMouseoverTooltip } from '../utils/useMouseoverTooltip';
import ThesisLogicDisplay from '../atoms/ThesisLogicDisplay';
import useAutoResizeTextareas from '../utils/useAutoResizeTextareas';
import { autocorrectLogic } from '../utils/autocorrectLogic';

const ThesisDesign = () => {
    const { courseDesignState, handleChange, removeFromDatabase } = useContext(CourseDesignContext); // Use context
    const { currentThesis, setCurrentThesis } = useContext(CourseDesignContext);
    console.log('currentThesis', currentThesis);
    const { thesisEditorIsOpen, setThesisEditorIsOpen } = useContext(CourseDesignContext);
    const [isLogicValid, setIsLogicValid] = useState(true);
    const [validationMessage, setValidationMessage] = useState('');

    // autoresize all text areas on a page
    useAutoResizeTextareas();

    // Filter and sort theses from state
    const theses = courseDesignState
        .filter(item => item.dataType === 'thesis')
        .sort((a, b) => a.dataText.localeCompare(b.dataText));


    // mouseover effect to identify references questions
    const { tooltipContent, tooltipPosition, handleMouseEnter, handleMouseLeave } =
    useMouseoverTooltip(courseDesignState);
    


    const validateThesisLogic = () => {
        const isValidLogic = validateLogic(currentThesis.logic);
        const isDuplicateName = theses.some(thesis => 
            thesis.dataText.toLowerCase() === currentThesis.name.toLowerCase() && thesis.dataID !== currentThesis.id
        );
        // remove all spaces from the name
        currentThesis.name = currentThesis.name.replace(/\s/g, '');

        if (!isValidLogic) {
            setIsLogicValid(false);
            setValidationMessage('This logic condition does not look well-formed.');
        } else if (isDuplicateName) {
            setIsLogicValid(false);
            setValidationMessage('This thesis name is already taken: Please choose a different name.');
        } else {
            setIsLogicValid(true);
            handleSaveThesis();
        }
    };

const handleSaveThesis = () => {
    // Save thesis to state and database
    if (!currentThesis.id) {
        const newId = Date.now().toString();
        handleChange('thesis', newId, currentThesis.name, {
            questionLogic: currentThesis.logic,
            questionOptions: currentThesis.description,
        });
    } else {
        handleChange('thesis', currentThesis.id, currentThesis.name, {
            questionLogic: currentThesis.logic,
            questionOptions: currentThesis.description,
        });
    }

    // Reset input fields
    setCurrentThesis({ id: '', name: '', logic: '', description: '' });
    setThesisEditorIsOpen(false);
};

const handleEditThesis = (id) => {
    const thesisToEdit = theses.find((thesis) => thesis.dataID === id);
    if (thesisToEdit) {
        setCurrentThesis({
            id: thesisToEdit.dataID,
            name: thesisToEdit.dataText,
            logic: thesisToEdit.questionLogic,
            description: thesisToEdit.questionOptions || '',
        });
        setThesisEditorIsOpen(true);
    }
};



    const handleDeleteThesis = (id) => {
        removeFromDatabase('thesis', id); // Remove thesis from state and database
        if (currentThesis.id === id) {
            setCurrentThesis({ id: '', name: '', logic: '', descriptiom: '' }); // Reset current thesis if it was being edited
            setThesisEditorIsOpen(false);
        }
    };

    return (
        <div>
            <HelpToolTip>
                 <div>
                If the logic for showing and hiding questions becomes complex, it can be useful to define 'theses,' which are in effect abbreviations for combinations of possible student answers. For example, suppose that your course considers four arguments for the existence of God. You may want to address questions to all students who think that at least one of these arguments is sound (or students who think that none of them are). Rather than always copying in a four part disjunction, you can define a thesis - 'soundArgumentForTheism' - using this disjunction. Then you can use this thesis name in the logic for questions later in the course. Because this thesis name is just an abbreviation for the logic you supply for the thesis, it can be negated, combined with other conditions, etc.

                <br></br><br></br>
                
                This is totally optional, and probably not worth it unless the logic of your site is at least moderately complex.
                
 <br></br><br></br>

                Each thesis should include a name for the thesis (which is what you will use in the logic for later questions), a brief description of what position the thesis is meant to abbreviate, and the logic that defines the thesis. 

            </div> 
</HelpToolTip>
            
         <div
    className="thesisEditor"
    style={{
        backgroundColor: 'white',
        boxShadow: 'var(--standard-box-shadow)',
        padding: '20px',
        marginTop: '24px',
        display: thesisEditorIsOpen ? 'block' : 'none', // Hide by default
    }}
>

        
  <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '16px' }}>
   <textarea 
                        placeholder="Thesis name"
                        rows="1"
                        value={currentThesis.name}
                        style={{
                            fontSize: '18px',
                            fontFamily: 'var(--font-mono)',
                            padding: '10px',
                            boxShadow: 'var(--standard-box-shadow)',
                            border: 'none',
                            overflow: 'hidden',
                        }}
    onChange={(e) =>
        setCurrentThesis({ ...currentThesis, name: e.target.value })
    }
/>
<textarea
    placeholder="Thesis logic"
    rows="1"
    style={{
        fontSize: '18px',
        fontFamily: 'var(--font-mono)',
        padding: '10px',
        boxShadow: 'var(--standard-box-shadow)',
        border: 'none',
        overflow: 'hidden',
    }}
    value={currentThesis.logic}
    onChange={(e) => {
        const correctedLogic = autocorrectLogic(e.target.value); // Apply autocorrection
        setCurrentThesis({ ...currentThesis, logic: correctedLogic }); // Update state
    }}
/>
<textarea
                        placeholder="Thesis description"
                        rows="1"
                       style={{
                            fontSize: '18px',
                            fontFamily: 'var(--font-mono)',
                            padding: '10px',
                           boxShadow: 'var(--standard-box-shadow)',
                            border: 'none',
                            overflow: 'hidden',
                        }}
                        value={currentThesis.description}
                        
    onChange={(e) =>
        setCurrentThesis({ ...currentThesis, description: e.target.value })
    }
/>
</div>
 <div style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
        {/* Save Button */}
        <button
            onClick={validateThesisLogic}
            style={{
                cursor: 'pointer',
                backgroundColor: 'var(--color2)',
                border: 'none',
                padding: '5px 10px',
                borderRadius: '4px',
                color: 'white',
            }}
        >
            <FontAwesomeIcon icon={faSave} size='1x' /> 
        </button>

        {/* Cancel Button */}
        <button
            onClick={() => {
                // Reset fields and close editor
                setCurrentThesis({ id: '', name: '', logic: '', description: '' });
                setThesisEditorIsOpen(false);
            }}
            style={{
                cursor: 'pointer',
                backgroundColor: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '4px',
                color: 'var(--color2)',
            }}
        >
            <FontAwesomeIcon icon={faTimes}  size='1x'/> 
        </button>
    </div>

            {validationMessage && (
                <div style={{ fontFamily: 'var(--font-secondary',  marginBottom: '16px' }}>
                    {validationMessage}
                </div>
            )}
        </div>
        
         
         {!thesisEditorIsOpen && (
            <button
                // center the button
                style={{
                    display: 'block',
                    margin: 'auto',
                    marginBottom: '16px',
                    cursor: 'pointer',
                    backgroundColor: 'var(--color2)',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    color: 'white',
                }}

    onClick={() => {
        setCurrentThesis({ id: '', name: '', logic: '', description: '' }); // Reset thesis fields
        setThesisEditorIsOpen(true);
    }}
    
>
    Add Thesis
                </button>
            )}

            {/* Read-Only List of Theses */}
          <div
    style={{
        backgroundColor: 'white',
        boxShadow: 'var(--standard-box-shadow)',
        fontFamily: 'var(--font-secondary)',
        fontSize: '18px',
        padding: '16px',
                    borderRadius: '8px',
        marginTop: '24px'
    }}
>
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <thead>
        <tr>
            <th style={{ textAlign: 'left', padding: '8px' }}>&nbsp;</th>
            <th style={{ textAlign: 'left', padding: '8px' }}>Thesis Name</th>
            <th style={{ textAlign: 'left', padding: '8px' }}>Description</th>
            <th style={{ textAlign: 'left', padding: '8px' }}>Logic</th>
            <th style={{ textAlign: 'center', padding: '8px' }}>&nbsp;</th>
        </tr>
    </thead>
    <tbody>
        {theses.map((thesis) => (
            <tr key={thesis.dataID} style={{ borderBottom: '0.4px solid #ddd' }}>
                {/* Copy Button */}
                <td style={{ padding: '8px', textAlign: 'center' }}>
                    <button
                        onClick={() => navigator.clipboard.writeText(thesis.dataText)}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: 'transparent',
                            height: '22px',
                            width: '22px',
                            border: 'none',
                            padding: '8px',
                        }}
                        title="Copy Thesis Name"
                    >
                        <FontAwesomeIcon
                            icon={faCopy}
                            style={{ cursor: 'pointer', color: 'var(--color2)', fontSize: '16px' }}
                        />
                    </button>
                </td>
                <td style={{ padding: '8px' }}>{thesis.dataText}</td>
                <td style={{ padding: '8px' }}>{thesis.questionOptions || 'No description'}</td>
                <td style={{ padding: '8px' }}>
                   <ThesisLogicDisplay
        logic={thesis.questionLogic}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
    />
                </td>
                {/* Edit and Delete Buttons */}
                <td style={{ padding: '8px', textAlign: 'center' }}>
                    <button
                        onClick={() => handleEditThesis(thesis.dataID)}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: 'var(--color2)',
                            border: 'none',
                            padding: '8px 16px',
                            borderRadius: '4px',
                            color: 'white',
                            marginRight: '8px',
                        }}
                    >
                        Edit
                    </button>
                    <button
                        onClick={() => handleDeleteThesis(thesis.dataID)}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: 'var(--color2)',
                            border: 'none',
                            padding: '8px 16px',
                            borderRadius: '4px',
                            color: 'white',
                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </td>
            </tr>
        ))}
    </tbody>
</table>

</div>

              {/* mouseover box to reveal question and answer data from codes */}
    {tooltipContent && (
        <div
            style={{
                position: 'absolute',
                top: tooltipPosition.y,
                left: tooltipPosition.x,
                backgroundColor: 'white',
                border: '1px solid #ddd',
                padding: '8px',
                borderRadius: '4px',
                boxShadow: 'var(--standard-box-shadow)',
                whiteSpace: 'pre-wrap',
                zIndex: 1000,
            }}
        >
            {tooltipContent}
        </div>
    )}

        </div>
    );
};

export default ThesisDesign;
