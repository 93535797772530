import React, { createContext, useState, useEffect, useCallback, useContext, useRef } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { AuthContext } from './AuthContext';
import { CourseIDContext } from './CourseIDContext';


const StudentAnswerContext = createContext();

const StudentAnswerProvider = ({ children }) => {
  const [studentAnswersState, setStudentAnswersState] = useState({});
    const [pendingChanges, setPendingChanges] = useState([]);
const { courseID } = useContext(CourseIDContext);

  // Add a ref to keep track of the latest pendingChanges in debounced functions
  const pendingChangesRef = useRef(pendingChanges);

  // Update the ref whenever pendingChanges changes
  useEffect(() => {
    pendingChangesRef.current = pendingChanges;
  }, [pendingChanges]);
  
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

 const authContext = useContext(AuthContext);  // Use AuthContext here


// Fetch initial state from the backend on page load
useEffect(() => {
  const fetchData = async () => {
    try {
      // Fetch only the logged-in user's data
      const response = await axios.get(`${apiUrl}/api/studentAnswers/${courseID}/${authContext.username}`);
      
      const initialState = response.data.reduce((acc, item) => {
        if (!acc[item.username]) {
          acc[item.username] = [];
        }
        acc[item.username].push({
          questionID: item.questionID.toString(),
          questionAnswer: item.questionAnswer?.toString(),
          questionEssayAnswer: item.questionEssayAnswer?.toString(),
        });
        return acc;
      }, {});

      setStudentAnswersState({ [authContext.username]: initialState[authContext.username] });
    } catch (error) {
      console.error('Error fetching student answer data:', error);
    }
  };

  fetchData();
}, [apiUrl, authContext, courseID]);



  // Debounced function to update the database
 const updateDatabase = useCallback(() => {
  const changesToSend = pendingChangesRef.current;
  if (changesToSend.length === 0) return;

  axios.post(`${apiUrl}/api/studentAnswers/update/${courseID}`, { answerData: changesToSend })
    .then(response => {
      if (response.data.historyErrors) {
        console.warn('Some history updates failed:', response.data.historyErrors);
      }
      // Clear pendingChanges after successful update
      setPendingChanges((prevPendingChanges) => prevPendingChanges.filter(
        (change) => !changesToSend.some(
          (sentChange) => sentChange.username === change.username && sentChange.questionID === change.questionID
        )
      ));
    })
    .catch(error => {
      console.error('Error updating student answer data:', error);
    });
}, [apiUrl, courseID]);



const debouncedUpdateDatabase = useCallback(debounce(() => {
  updateDatabase();
}, 500), [updateDatabase]);

  // Function to handle changes in the state and database
 const handleAnswerChange = (username, questionID, answer, isEssay = false) => {
  setStudentAnswersState((prevState) => {
    const userAnswers = prevState[username] || [];
    const existingEntryIndex = userAnswers.findIndex(item => item.questionID === questionID);

    let updatedUserAnswers;

    if (existingEntryIndex !== -1) {
      // Update existing answer
      const existingItem = userAnswers[existingEntryIndex];
      const updatedItem = isEssay 
        ? { ...existingItem, questionEssayAnswer: answer }
        : { ...existingItem, questionAnswer: answer };

      // Check if the answer has actually changed
      if (JSON.stringify(existingItem) === JSON.stringify(updatedItem)) {
        console.log('No change detected, returning early.');
        return prevState;
      }

      updatedUserAnswers = [
        ...userAnswers.slice(0, existingEntryIndex),
        updatedItem,
        ...userAnswers.slice(existingEntryIndex + 1)
      ];
    } else {
      // Add new answer
      const newEntry = {
        questionID,
        questionAnswer: isEssay ? null : answer,
        questionEssayAnswer: isEssay ? answer : null,
      };
      updatedUserAnswers = [...userAnswers, newEntry];
    }

    const newState = {
      ...prevState,
      [username]: updatedUserAnswers
    };

    // Update pendingChanges
    setPendingChanges((prevPendingChanges) => {
      const existingPendingIndex = prevPendingChanges.findIndex(
        (item) => item.username === username && item.questionID === questionID
      );
      const changedItem = {
        username,
        questionID,
        questionAnswer: isEssay ? null : answer,
        questionEssayAnswer: isEssay ? answer : null,
      };
      if (existingPendingIndex !== -1) {
        // Update existing pending change
        const newPendingChanges = [...prevPendingChanges];
        newPendingChanges[existingPendingIndex] = changedItem;
        return newPendingChanges;
      } else {
        // Add new pending change
        return [...prevPendingChanges, changedItem];
      }
    });

    // Call debouncedUpdateDatabase
    debouncedUpdateDatabase();

    return newState;
  });
};


  return (
    <StudentAnswerContext.Provider value={{ 
      studentAnswersState, 
      handleAnswerChange,
    }}>
      {children}
    </StudentAnswerContext.Provider>
  );
};

export { StudentAnswerContext, StudentAnswerProvider };