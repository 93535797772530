import React, { useContext } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';

function Contradiction({ questionID, user, answersReadOnly, userAnswers }) {
  const { courseDesignState } = useContext(CourseDesignContext);

  // Find the question in the courseDesignState
  const question = courseDesignState.find(item => item.dataID === questionID && item.dataType === 'question');

  if (!question) {
    return <div>Question not found</div>;
  }

  const questionText = question.dataText;

  return (
    <div className='questionContainer contradictionContainer'>
      <p className='questionText'>{questionText}</p>
      {/* You can add additional logic here if needed for contradiction-specific behavior */}
    </div>
  );
}

export default Contradiction;