import React, { useContext, useState, useEffect } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';

const QuestionDayPicker = () => {
  const { courseDesignState, handleChange } = useContext(CourseDesignContext);
  const [items, setItems] = useState([]);

  useEffect(() => {
    // make a list of all of the items in the state with a dataType of either topic or sectionName
    setItems(
      courseDesignState.filter(
        item => item.dataType === 'topic' || item.dataType === 'sectionName'
      )
    );
  }, [courseDesignState]);

  const handleButtonClick = (dataID, currentStatus) => {
    const questionOptions = currentStatus === 'yes' ? 'no' : 'yes';

    const currentItem = items.find(item => item.dataID === dataID);
    const currentDataText = currentItem ? currentItem.dataText : '';

    handleChange('topic', dataID, currentDataText, { questionOptions });
  };

  const handleSelectAll = () => {
    items
      .filter(item => item.dataType === 'topic')
      .forEach(item => {
        handleChange('topic', item.dataID, item.dataText, { questionOptions: 'yes' });
      });
  };

  const handleUnselectAll = () => {
    items
      .filter(item => item.dataType === 'topic')
      .forEach(item => {
        handleChange('topic', item.dataID, item.dataText, { questionOptions: 'no' });
      });
  };

  return (
    <>
    <div> <p>
          Select the course days with which you plan to associate questions.
          (You can always come back and change this later.)
        </p></div>
    <div className="question-day-picker" style={{ display: 'block' }}>
      
      <div className="rows" style={{ display: 'inline-block' }}>
       
        {items
          .sort((a, b) => a.dataIndex - b.dataIndex)
          .map(item => (
            <div key={item.dataID} className="row">
              {item.dataType === 'topic' ? (
                <button
                  className={`topicName-questionDayPicker ${item.questionOptions === 'yes' ? 'selected' : ''}`}
                  onClick={() => handleButtonClick(item.dataID, item.questionOptions)}
                >
                  {item.dataText}
                </button>
              ) : (
                <div className="sectionName-questionDayPicker">{item.dataText}</div>
              )}
            </div>
          ))}
      </div>
      <div className="action-buttons" style={{ display: 'inline-block', verticalAlign: 'top', marginLeft: '30px'  }}>
        <button className="hoverOpacityButton" style={{backgroundColor: 'var(--color1)', padding: '10px', fontSize: '18px', marginTop: '15px'}} onClick={handleSelectAll} >Select All</button>
        <button className="hoverOpacityButton" style={{backgroundColor: 'var(--color1)', padding: '10px', fontSize: '18px', marginTop: '15px'}} onClick={handleUnselectAll} >Unselect All</button>
      </div>
      </div>
      </>
  );
};

export default QuestionDayPicker;
