// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { CourseIDProvider } from './context/CourseIDContext';
import { Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { CourseDesignProvider } from './context/CourseDesignContext';
import { StudentGradesProvider } from './context/StudentGradesContext';
import { StudentAnswerProvider } from './context/StudentAnswerContext';
import StudentView from './pages/StudentView';
import AdminCourseManagement from './pages/AdminCourseManagement';
import CourseDesign from './pages/CourseDesign';
import PDFDisplay from './atoms/PDFDisplay';
import GradingPage from './pages/GradingPage';
import './App.css';



// Wrapper component to use courseID from useParams
const CourseRouteWrapper = ({ children }) => {
  const { courseID } = useParams();

  // Use courseID directly from the URL, no defaulting here
  if (!courseID) {
    console.error("courseID not found in URL parameters");
    return null;
  }

  
  return (
    <CourseIDProvider courseID={courseID}>
       <AuthProvider>
      <CourseDesignProvider>
        <StudentGradesProvider>
          <StudentAnswerProvider>
            {children}
          </StudentAnswerProvider>
        </StudentGradesProvider>
      </CourseDesignProvider>
      </AuthProvider>
    </CourseIDProvider>
  );
};



function App() {



  
  return (
    
      <Router>
      <Routes>
        
  <Route
    path="/"
    element={<Navigate to="/jspeaks" replace />}
  />

          <Route path="/admin" element={<AdminCourseManagement />} />
          
             

         <Route
          path="/:courseID/design"
          element={<CourseRouteWrapper>
            <CourseDesign />
          </CourseRouteWrapper>}
        />
<Route
  path="/:courseID/grading"
  element={<CourseRouteWrapper>
    <GradingPage />
  </CourseRouteWrapper>}
/>


                 <Route path="/:courseID/pdf" element={
                      <CourseRouteWrapper>
                    <PDFDisplay />
                             </CourseRouteWrapper>

          } />
          <Route path="/:courseID" element={
                      <CourseRouteWrapper>
                    <StudentView />
                             </CourseRouteWrapper>

          } />
          <Route path="/" element={<StudentView />} />
        </Routes>
      </Router>
   
  );
}

export default App;
