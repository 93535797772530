

export const evaluateQuestionLogic = (questionLogic, courseDesignState, studentAnswersState) => {
 // console.log('Starting evaluation for questionLogic:', questionLogic);

  if (!questionLogic || questionLogic.trim() === '') {
  //  console.log('QuestionLogic is null or empty, returning true');
    return true;
  }

  // Function to replace thesis abbreviations
  const replaceThesisAbbreviations = (logic) => {
   // console.log('Replacing thesis abbreviations in:', logic);
    const thesisRegex = /\(([^,\s()]+)\)/g;
    let updatedLogic = logic;

    updatedLogic = updatedLogic.replace(thesisRegex, (match, thesisName) => {
//      console.log('Found thesis abbreviation:', thesisName);
      const thesisItem = courseDesignState.find(
        item => item.dataType === 'thesis' && item.dataText === thesisName
      );
      if (thesisItem && thesisItem.questionLogic) {
       // console.log('Replacing', match, 'with', `(${thesisItem.questionLogic})`);
        // Always wrap the replacement in parentheses
        return `(${thesisItem.questionLogic})`;
      }
   //   console.log('No matching thesis found for', thesisName);
      return match; // Keep original if no replacement found
    });

    if (updatedLogic !== logic) {
   //   console.log('Logic updated, recursing with:', updatedLogic);
      return replaceThesisAbbreviations(updatedLogic);
    }

    return updatedLogic;
  };

  questionLogic = replaceThesisAbbreviations(questionLogic);
 // console.log('After thesis replacement:', questionLogic);

  // Replace logical symbols
  questionLogic = questionLogic
    .replace(/&/g, '&&')
    .replace(/¬/g, '!')
    .replace(/∨/g, '||');
 // console.log('After replacing logical symbols:', questionLogic);

  // Evaluate answer conditions
  const answerRegex = /\(([^,()]+),\s*(\d+)\)/g;
  questionLogic = questionLogic.replace(answerRegex, (match, questionId, answerNum) => {
   // console.log('Evaluating answer condition:', match);
    const studentAnswer = studentAnswersState.find(answer => answer.questionID === questionId);
    if (studentAnswer) {
      const answerValue = studentAnswer.questionAnswer || studentAnswer.questionEssayAnswer;
     // console.log('Found student answer:', answerValue);
      const result = answerValue && answerValue.includes(answerNum) ? 'true' : 'false';
     // console.log('Condition evaluates to:', result);
      return result;
    }
   // console.log('No student answer found, returning false');
    return 'false';
  });

  //console.log('Final logic to evaluate:', questionLogic);

  // Evaluate the final logic
  try {
    /* eslint-disable no-eval */
    const result = eval(questionLogic);
   // console.log('Evaluation result:', result);
    return result;
  } catch (error) {
    console.error('Error evaluating question logic for the following questionLogic:', error);
    console.log('logic error caused by this logic:', questionLogic);
    return false;
  }
};