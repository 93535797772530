import React, { createContext, useState, useContext } from 'react';
import { CourseIDContext } from './CourseIDContext';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { courseID } = useContext(CourseIDContext);

  const [authState, setAuthState] = useState(() => {
    const loggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
    const storedFullName = sessionStorage.getItem('fullName');
    const storedUsername = sessionStorage.getItem('username');
    const storedIsInstructor = sessionStorage.getItem('isInstructor') === 'true';
    return {
      isLoggedIn: loggedIn,
      fullName: storedFullName || '',
      username: storedUsername || '',
      isInstructor: storedIsInstructor,
      redirectUrl: '/',
    };
  });

  const setRedirectUrl = (url) => {
    setAuthState(prevState => ({ ...prevState, redirectUrl: url }));
  };

  const handleLogin = async (username, password) => {
    try {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${apiUrl}/api/authenticate/${courseID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok && !(username === password)) {
        const data = await response.json();
        sessionStorage.setItem('isLoggedIn', 'true');
        sessionStorage.setItem('isInstructor', data.isInstructor.toString());
        sessionStorage.setItem('username', username);
        sessionStorage.setItem('fullName', data.fullName);

        const newAuthState = {
          isLoggedIn: true,
          isInstructor: data.isInstructor,
          username: username,
          fullName: data.fullName,
          redirectUrl: authState.redirectUrl,
        };
        setAuthState(newAuthState);

        const currentRedirectUrl = newAuthState.redirectUrl;
        setRedirectUrl('/');

        return { success: true, isInstructor: data.isInstructor, redirectUrl: currentRedirectUrl };
       } else {
      // If response is not OK, we need to find out why
      if (response.status === 404) { // Assuming 404 for user not found
        return { success: false, reason: 'USER_NOT_FOUND' };
      } else if (response.status === 401) { // Assuming 401 for wrong password
        return { success: false, reason: 'WRONG_PASSWORD' };
      }  else if (username === password) {
        return { success: false, reason: 'RESET_PASSWORD' };
      } else {
        // Handle other HTTP errors
        return { success: false, reason: 'OTHER_ERROR' };
      }
    }
    } catch (error) {
      console.error('Login error:', error);
      return { success: false, reason: 'NETWORK_ERROR' };
    }
  };

  

  const getFullNameByUsername = async (username) => {
    try {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${apiUrl}/api/authenticate/fullname/${courseID}/${username}`);
      if (response.ok) {
        const data = await response.json();
        return data.fullName;
      }
      throw new Error('User not found.');
    } catch (error) {
      console.error('Error fetching full name:', error);
      return 'Unknown User';
    }
  };

  return (
    <AuthContext.Provider value={{ ...authState, handleLogin, setRedirectUrl, getFullNameByUsername }}>
      {children}
    </AuthContext.Provider>
  );
};
