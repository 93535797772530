import React, { useContext, useState, useEffect } from 'react';
import { StudentGradesContext } from '../context/StudentGradesContext'; // Importing the context

function LongAnswerGradeBox({ questionID, evaluationID, username }) {
  const { studentGradesState, handleGradeChange } = useContext(StudentGradesContext);
  const [grade, setGrade] = useState('');



  // Find the current grade for the question based on questionId and evaluationId
useEffect(() => {
  if (!studentGradesState || !studentGradesState[username]) {
    console.log('Grades not yet loaded or unavailable for this user.');
    return;
  }

  const userGrades = studentGradesState[username];
  const currentGrade = userGrades.find((g) => g.questionID === questionID);

  if (currentGrade) {
    setGrade(currentGrade.individualGradingData);
  } else {
    //console.log('No grade found for this question.');
    setGrade(''); // Set to an empty string when no grade exists
  }
}, [studentGradesState, questionID, username]);


  const handleChange = (event) => {
    const value = event.target.value;
    const regex = /^(10|10.0|[0-9]|([0-9]\.\d?))$/;

    if (value === '' || regex.test(value)) {
      const numericValue = parseFloat(value);
      if (numericValue <= 10 || value === '') {
        setGrade(value);
        handleGradeChange(username, questionID, 'questionGrade', value, '', evaluationID);
      }
    }
  };

  return (
    <div>
      <label>
        Question grade:
        <input
          type="text"
          value={grade || ''} // Ensures the input is controlled, showing the current grade
          onChange={handleChange}
        />
      </label>
    </div>
  );
}

export default LongAnswerGradeBox;
