import { useMemo } from 'react';

const getCurrentCourseDay = (courseDesignState) => {
  console.log('Starting getCurrentCourseDay');

  // Check whether course progress is automatic or manual
  const usesAutomaticProgressItem = courseDesignState.find(item => item.dataType === 'usesAutomaticProgress');
  const usesAutomaticProgress = usesAutomaticProgressItem ? usesAutomaticProgressItem.dataText === 'Yes' : false;
  console.log('usesAutomaticProgress:', usesAutomaticProgress);

  let calculatedDate;
if (!usesAutomaticProgress) {
  console.log('Using manual course progress');
  const currentCourseDateItem = courseDesignState.find(item => item.dataType === 'currentCourseDate');
  const currentCourseDate = currentCourseDateItem ? String(currentCourseDateItem.dataIndex) : null;
  console.log('Manual currentCourseDate:', currentCourseDate);
  return currentCourseDate;
}

  console.log('Using automatic course progress');

  // Get the current date and time in New York timezone
  const now = new Date();
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // 24-hour format
  });

  const parts = formatter.formatToParts(now);
  const currentDate = `${parts.find(part => part.type === 'year').value}-${parts.find(part => part.type === 'month').value}-${parts.find(part => part.type === 'day').value}`;
  const currentTime = `${parts.find(part => part.type === 'hour').value}${parts.find(part => part.type === 'minute').value}`;

  //console.log('Current date (New York):', currentDate);
  //console.log('Current time (New York):', currentTime);

  // Retrieve saved time and period from state
  const timeToShowNewContentItem = courseDesignState.find(item => item.dataType === 'timeToShowNewContent');
  if (!timeToShowNewContentItem) {
    console.log('No saved time for new content found');
    return null;
  }

  const savedTime = timeToShowNewContentItem.dataText; // HH:MM format
  const savedPeriod = timeToShowNewContentItem.questionOptions; // 'AM' or 'PM'
 //console.log('Saved time:', savedTime);
  //console.log('Saved period:', savedPeriod);

  // Convert saved time to 24-hour format
  let [hours, minutes] = savedTime.split(':').map(Number);
  if (savedPeriod === 'PM' && hours !== 12) hours += 12;
  if (savedPeriod === 'AM' && hours === 12) hours = 0;
  const savedTime24 = `${String(hours).padStart(2, '0')}${String(minutes).padStart(2, '0')}`;
  console.log('Converted saved time (24-hour):', savedTime24);

  // Compare the saved time with the current time
  if (currentTime < savedTime24) {
    console.log('Current time is earlier than the saved time; reducing date by 1');
    const previousDay = new Date(`${currentDate}T00:00:00`);
    previousDay.setDate(previousDay.getDate() - 1);

    // Manually format the adjusted date as yyyy-mm-dd
    calculatedDate = `${previousDay.getFullYear()}-${String(previousDay.getMonth() + 1).padStart(2, '0')}-${String(previousDay.getDate()).padStart(2, '0')}`;
  } else {
    console.log('Current time is later than or equal to the saved time; using current date');
    calculatedDate = currentDate;
  }

  //console.log('Calculated date:', calculatedDate);

  // Extract and sort course dates
 

    // Find the largest course date <= calculatedDate and return its dataIndex
  const selectedCourseDate = courseDesignState
    .filter(item => item.dataType === 'courseDate' && item.questionLogic)
    .sort((a, b) => b.questionLogic.localeCompare(a.questionLogic)) // Sort by questionLogic in descending order
    .find(item => item.questionLogic <= calculatedDate);

    //console.log('Selected course date item:', selectedCourseDate);
    
    //console.log('returned value:', selectedCourseDate ? String(selectedCourseDate.dataIndex) : null);

    return selectedCourseDate ? String(selectedCourseDate.dataIndex) : null; // Ensure dataIndex is returned as a string
};

export default getCurrentCourseDay;


