import React, { useContext, useEffect, useState } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import { fetchAllUsers, fetchGradesForExamColumns } from '../utils/serverFetchAndAddUtilities';
import './GradingStatistics.css';
import { CourseIDContext } from '../context/CourseIDContext';

const GradingStatistics = () => {
  const { courseDesignState } = useContext(CourseDesignContext);
  const [TAs, setTAs] = useState([]);
  const [studentsByTA, setStudentsByTA] = useState({});
  const [allStudents, setAllStudents] = useState([]);
  const [grades, setGrades] = useState([]);
  const { courseID } = useContext(CourseIDContext);

  const hasTAs = courseDesignState.some(
    (item) => item.dataType === 'areThereTAs' && item.dataText === 'Yes'
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await fetchAllUsers(courseID);
        const fetchedGrades = await fetchGradesForExamColumns(courseID);

        setGrades(fetchedGrades);

        if (hasTAs) {
          const taList = users.filter((user) => user.status === 'TA');
          setTAs(taList);

          const groupedStudents = {};
          taList.forEach((ta) => {
            groupedStudents[ta.username] = users.filter(
              (student) => student.TA === ta.username
            );
          });
          setStudentsByTA(groupedStudents);
        } else {
          const studentList = users.filter((user) => user.status === 'Student');
          setAllStudents(studentList);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [hasTAs, courseID]);

  const evaluations = courseDesignState.filter(
    (item) => item.dataType === 'evaluation'
  );

  const calculateStats = (evaluationID, students) => {
    const completedGrades = grades.filter(
      (grade) =>
        grade.evaluationDataID === evaluationID &&
        grade.gradingType === 'gradingComplete' &&
        grade.individualGradingData === 'yes' &&
        students.some((student) => student.username === grade.username)
    );

    const completedStudentUsernames = completedGrades.map(
      (grade) => grade.username
    );

    const examGrades = grades
      .filter(
        (grade) =>
          grade.evaluationDataID === evaluationID &&
          grade.gradingType === 'examGrade' &&
          completedStudentUsernames.includes(grade.username)
      )
      .map((grade) => parseFloat(grade.individualGradingData))
      .filter((grade) => !isNaN(grade) && grade >= 60);

    const mean =
      examGrades.length > 0
        ? (examGrades.reduce((sum, grade) => sum + grade, 0) / examGrades.length).toFixed(2)
        : '--';

    const median =
      examGrades.length > 0
        ? (() => {
            const sorted = [...examGrades].sort((a, b) => a - b);
            const mid = Math.floor(sorted.length / 2);
            return sorted.length % 2 !== 0
              ? sorted[mid]
              : ((sorted[mid - 1] + sorted[mid]) / 2).toFixed(2);
          })()
        : '--';

    return {
      progress: completedGrades.length,
      mean,
      median,
    };
  };

  return (
    <div className="grading-statistics">
      <table>
        <thead>
          <tr>
            {hasTAs && <th>TA Name</th>}
            {evaluations.map((evaluation) => (
              <React.Fragment key={evaluation.dataID}>
                <th>{evaluation.dataText} - Grading Progress</th>
                <th>{evaluation.dataText} - Mean (excl. &lt; 60)</th>
                <th>{evaluation.dataText} - Median</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {hasTAs
            ? TAs.map((ta) => (
                <tr key={ta.username}>
                  <td>{ta.fullname}</td>
                  {evaluations.map((evaluation) => {
                    const { progress, mean, median } = calculateStats(
                      evaluation.dataID,
                      studentsByTA[ta.username] || []
                    );
                    return (
                      <React.Fragment key={evaluation.dataID}>
                        <td>
                          {progress}/{studentsByTA[ta.username]?.length || 0}
                        </td>
                        <td>{mean}</td>
                        <td>{median}</td>
                      </React.Fragment>
                    );
                  })}
                </tr>
              ))
            : allStudents.length > 0 && (
                <tr>
                  {evaluations.map((evaluation) => {
                    const { progress, mean, median } = calculateStats(
                      evaluation.dataID,
                      allStudents
                    );
                    return (
                      <React.Fragment key={evaluation.dataID}>
                        <td>
                          {progress}/{allStudents.length}
                        </td>
                        <td>{mean}</td>
                        <td>{median}</td>
                      </React.Fragment>
                    );
                  })}
                </tr>
              )}
        </tbody>
      </table>
    </div>
  );
};

export default GradingStatistics;
