import React from 'react';
import './PDFButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const PDFButton = ( ) => {

const handleGenerateUserPDF = () => {
  const { origin, pathname } = window.location;
  const updatedPathname = pathname.endsWith('/') ? `${pathname}pdf` : `${pathname}/pdf`;
  window.open(`${origin}${updatedPathname}`, '_blank');
};


  return (
    <button id="pdfButton" onClick={handleGenerateUserPDF}>
       <FontAwesomeIcon icon={faFilePdf} size='2x' />
    </button>
  );
};

export default PDFButton;
