import React, { useContext, useMemo, useState, useEffect } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext'; // Adjust the path as necessary
import { StudentGradesContext } from '../context/StudentGradesContext'; // Adjust the path as necessary
import QAndA from './QAndA'; // Adjust the path as necessary
import './StudentGradeView.css'; // Custom CSS
import StudentViewGradingDetails from '../atoms/StudentViewGradingDetails'; // 
import { fetchUserAnswersAsOfDate, fetchStudentGrades, determineTargetDate, fetchStudentAnswersForExam } from '../utils/serverFetchAndAddUtilities';
import { CourseIDContext } from '../context/CourseIDContext';


const StudentGradeView = ({ username }) => {
  const { courseDesignState, questionByQuestionGrading, giveStudentsComments } = useContext(CourseDesignContext);
  const { studentGradesState } = useContext(StudentGradesContext);
  const [selectedEvaluationID, setSelectedEvaluationID] = useState(null);
  const [selectedTab, setSelectedTab] = useState('overall'); // 'overall', 'details', or 'questions'
  const [studentAnswers, setStudentAnswers] = useState(null);
const courseID = useContext(CourseIDContext).courseID;

  console.log('StudentGradeView username:', username);

  // Filter and sort items with dataType 'evaluation'
  const evaluations = useMemo(() => {
    return courseDesignState
      .filter(item => item.dataType === 'evaluation')
      .sort((a, b) => new Date(a.questionCourseDate) - new Date(b.questionCourseDate));
  }, [courseDesignState]);

  
// Add this useEffect hook to set the default selected evaluation
useEffect(() => {
  // Find the latest graded evaluation
  const latestGradedEvaluation = evaluations
    .filter((evaluation) => evaluation.questionLogic === 'graded')
    .sort((a, b) => new Date(b.questionCourseDate) - new Date(a.questionCourseDate))[0];

  if (latestGradedEvaluation) {
    setSelectedEvaluationID(latestGradedEvaluation.dataID);
  }
}, [evaluations]);

  // Get the comment for the selected evaluation and username
  const selectedEvaluationComment = useMemo(() => {
    if (!selectedEvaluationID || !studentGradesState[username]) return null;

    const gradeItem = studentGradesState[username].find(
      (item) =>
        item.evaluationDataID === selectedEvaluationID &&
        item.gradingType === 'comments'
    );

    return gradeItem ? gradeItem.comment : 'No comment available.';
  }, [selectedEvaluationID, studentGradesState, username]);

  // Get the overall grade for the selected evaluation
  const overallGrade = useMemo(() => {
    if (!selectedEvaluationID || !studentGradesState[username]) return null;

    const gradeItem = studentGradesState[username].find(
      (item) =>
        item.evaluationDataID === selectedEvaluationID &&
        item.gradingType === 'examGrade'
    );

    return gradeItem ? gradeItem.individualGradingData : 'No overall grade available.';
  }, [selectedEvaluationID, studentGradesState, username]);

  // Get the evaluation object for the selected evaluationID
  const selectedEvaluation = courseDesignState.find(
    item => item.dataID === selectedEvaluationID
  );

  // Fetch student answers when selectedEvaluationID changes
  useEffect(() => {
  const fetchAnswersForSelectedEvaluation = async () => {
    if (!selectedEvaluationID || !username) return;

    try {
      const targetDate = await determineTargetDate(
        courseID,
        username,
        selectedEvaluationID,
        courseDesignState,
        fetchStudentGrades
      );

      console.log('Determined targetDate:', targetDate);

      if (targetDate) {
        const answers = await fetchStudentAnswersForExam(
          courseID,
          username,
          targetDate,
          fetchUserAnswersAsOfDate
        );
        console.log('Fetched studentAnswers:', answers);
        setStudentAnswers(answers);
      } else {
        console.warn('No target date found for the selected evaluation.');
        setStudentAnswers(null);
      }
    } catch (error) {
      console.error('Error fetching student answers for evaluation:', error);
      setStudentAnswers(null);
    }
  };

  fetchAnswersForSelectedEvaluation();
}, [selectedEvaluationID, username, courseDesignState, studentGradesState, courseID]);


  return (
    <div className="student-grade-view">
      <h1>Evaluation and Grades</h1>

      {/* Evaluation Menu */}
      <div className="evaluation-menu">
        {evaluations.map((evaluation) => {
          const isGraded = evaluation.questionLogic === 'graded';
          return (
            <button
              key={evaluation.dataID}
              className={`evaluation-button ${isGraded ? 'clickable' : 'non-clickable'}`}
              disabled={!isGraded}
              onClick={() => isGraded && setSelectedEvaluationID(evaluation.dataID)}
            >
              {evaluation.dataText}
            </button>
          );
        })}
      </div>

      {/* Display the comment for the selected evaluation */}
      {selectedEvaluationID && (
        <div className="evaluation-comment">

          {giveStudentsComments && (
            <div className="comments-container">
              <div className="comment-box">
                {selectedEvaluationComment
  ? selectedEvaluationComment.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ))
  : <p>No comment available.</p>}

              </div>
            </div>
          )}

          {/* Submenu */}

<div className="evaluation-submenu">
  <button
    className={selectedTab === 'overall' ? 'selected' : ''}
    onClick={() => setSelectedTab('overall')}
  >
    Overall Grade
  </button>
  <button
    className={selectedTab === 'details' ? 'selected' : ''}
    onClick={() => setSelectedTab('details')}
  >
    Grading Details
  </button>
  {questionByQuestionGrading && (
            <button
    className={selectedTab === 'questions' ? 'selected' : ''}
    onClick={() => setSelectedTab('questions')}
  >
    Question by Question
              </button>
          )}
</div>


          {/* Render based on selected tab */}
          {selectedTab === 'overall' && (
            <div className="overall-grade">
            
              <p>Overall Grade: {overallGrade}</p>
            </div>
          )}

          {selectedTab === 'details' && (
             <StudentViewGradingDetails
              username={username}
              evaluationID={selectedEvaluationID}
            />
          )}

          {selectedTab === 'questions' && selectedEvaluation && (
            <QAndA
              username={username}
              sectionsToShow={selectedEvaluation.questionOptions}
              gradeDisplay={questionByQuestionGrading}
              evaluationID={selectedEvaluationID}
              showSectionHeaders={true}
              showMyPhiHeader={true}
              answersReadOnly={true}
              studentAnswers={studentAnswers} // Pass fetched answers
            />
          )}
        </div>
      )}
    </div>
  );
};

export default StudentGradeView;
