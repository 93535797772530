import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import ResetPassword from './ResetPassword';
import LoginInstructions from './LoginInstructions';
import './LoginForm.css';

function LoginForm({ onLoginSuccess  }) {
  const { handleLogin } = useContext(AuthContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resetPassword, setResetPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Clear any existing error messages
    const response = await handleLogin(username, password);

    if (response.success) {
      console.log('Login successful');
      onLoginSuccess(response); // Pass the entire response to onLoginSuccess
    } else {
      if (response.reason === 'USER_NOT_FOUND') {
        setErrorMessage('That username is not among the listed netids for this course. Be sure that you are using your netid rather than your email address or your numeric Notre Dame ID. (So, e.g., jspeaks rather than jspeaks@nd.edu or 123456789.) If you are using your netid and are registered for the course, please send an email to the instructor.');
      } else if (response.reason === 'WRONG_PASSWORD') {
        setErrorMessage('That is not the password saved for that netid. You can try again, or, if you forgot your password, send an email to the instructor.');
      } else if (response.reason === 'RESET_PASSWORD') {
        setResetPassword(true); // Show the password reset field
      } else {
        setErrorMessage('An error occurred during login. Please try again.');
      }
    }
  };

  return (
    <div>
      {resetPassword ? (
        <ResetPassword 
          username={username} 
        />
      ) : (
        <div>
          <LoginInstructions />
          <form onSubmit={handleSubmit}>
            <label>
              Username: 
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoFocus
              />
            </label><br />
            <label>
              Password: 
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
            <button className="loginButton" type="submit">LOGIN</button>
            {errorMessage && <p>{errorMessage}</p>}
          </form>
        </div>
      )}
    </div>
  );
}

export default LoginForm;