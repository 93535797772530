import React from 'react';

const LoginInstructions = () => {
  //const [firstVisit, setFirstVisit] = useState(false);


  // return div with the text If this is your first time logging in, you should use your netid as your username and password. You will then be prompted to pick a new password. Be sure to use your netid rather than your full email address or your nine digit Notre Dame ID number.
  
  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Login Instructions</h2>
      <p>If this is your first time logging in, you should use your netid as your username and password. You will then be prompted to pick a new password. Be sure to use your netid rather than your full email address or your nine digit Notre Dame ID number.</p>
    </div>
  );
  
};

export default LoginInstructions;
