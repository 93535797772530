// NothingToSeeHere.js
import React from 'react';

const NothingToSeeHere = () => {
  return (
    <div>
      <p>Nothing to see here yet. (Questions will show up here later in the course.)</p>
    </div>
  );
};

export default NothingToSeeHere;
