import React, { useState, useContext } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faVideo, faFileAlt, faLink } from '@fortawesome/free-solid-svg-icons';
import './SyllabusLinkAdder.css';

const SyllabusLinkAdder = () => {
  const { courseDesignState, handleChange, removeFromDatabase } = useContext(CourseDesignContext);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [linkEditors, setLinkEditors] = useState([]); // Array for new links being added

  // Filter topics and sort by dataIndex
  const topics = courseDesignState
    .filter(item => item.dataType === 'topic')
    .sort((a, b) => parseInt(a.dataIndex, 10) - parseInt(b.dataIndex, 10));

  // Filter syllabus link items for the selected topic
  const syllabusLinks = courseDesignState
    .filter(item => item.dataType === 'syllabusLinkItem' && item.questionType === selectedTopic?.dataID)
    .sort((a, b) => parseInt(a.dataIndex, 10) - parseInt(b.dataIndex, 10));

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);

    // Populate linkEditors with existing syllabus links in the correct format
    const newLinkEditors = courseDesignState
      .filter(item => item.dataType === 'syllabusLinkItem' && item.questionType === topic.dataID)
      .sort((a, b) => parseInt(a.dataIndex, 10) - parseInt(b.dataIndex, 10))
      .map(link => ({
        id: link.dataID,
        url: link.questionLogic,
        iconType: link.dataText, // Assuming dataText holds the icon type
      }));

    setLinkEditors(newLinkEditors); // Set the linkEditors state
  };

// SyllabusLinkAdder.jsx
const handleAddLink = () => {
  const newId = Date.now();  // or a UUID, but be consistent
  const newLinkEditor = { id: newId, url: '', iconType: 'reading' };

  setLinkEditors([...linkEditors, newLinkEditor]);

  // Also create the DB entry:
  handleChange(
    'syllabusLinkItem',
    newId.toString(),
    'reading',              // iconType stored as dataText
    {
      questionLogic: '',    // initially empty URL
      questionType: selectedTopic?.dataID,
      // dataIndex: ??? if desired
    }
  );
};


// SyllabusLinkAdder.jsx
const handleUpdateLink = (id, updates) => {
  // First update local state
  const updatedEditors = linkEditors.map(editor =>
    editor.id === id ? { ...editor, ...updates } : editor
  );
  setLinkEditors(updatedEditors);

  // Grab the merged editor fields after local state update
  const updatedEditor = updatedEditors.find(e => e.id === id);

  // Now call handleChange with the correct signature
  handleChange(
    'syllabusLinkItem',
    id.toString(),            // dataID must be a string
    updatedEditor.iconType,   // This becomes the "value" -> stored in dataText
    {
      questionLogic: updatedEditor.url,            // stored in questionLogic
      questionType: selectedTopic?.dataID,         // keeps it tied to the topic
      // dataIndex: ??? if you want/need to store it
    }
  );
};


  return (
    <div className="syllabus-link-adder">
      <div className="left-column">
        <h3>Topics</h3>
        <ul className="topic-list">
          {topics.map(topic => (
            <li
              key={topic.dataID}
              className={`topic-item ${selectedTopic?.dataID === topic.dataID ? 'selected' : ''}`}
              onClick={() => handleTopicClick(topic)}
            >
              {topic.dataText || 'Untitled Topic'}
            </li>
          ))}
        </ul>
      </div>

      <div className="right-column">
        {selectedTopic ? (
          <>
            <h3>Selected Topic</h3>
            <p>{selectedTopic.dataText}</p>

            {linkEditors.map(editor => (
              <div key={editor.id} className="link-editor">
                <div className="icon-row">
                  <FontAwesomeIcon
                    icon={faBookOpen}
                    className={`link-icon ${editor.iconType === 'reading' ? 'selected-icon' : ''}`}
                    onClick={() => handleUpdateLink(editor.id, { iconType: 'reading' })}
                  />
                  <FontAwesomeIcon
                    icon={faVideo}
                    className={`link-icon ${editor.iconType === 'video' ? 'selected-icon' : ''}`}
                    onClick={() => handleUpdateLink(editor.id, { iconType: 'video' })}
                  />
                  <FontAwesomeIcon
                    icon={faFileAlt}
                    className={`link-icon ${editor.iconType === 'lecturenotes' ? 'selected-icon' : ''}`}
                    onClick={() => handleUpdateLink(editor.id, { iconType: 'lecturenotes' })}
                  />
                  <FontAwesomeIcon
                    icon={faLink}
                    className={`link-icon ${editor.iconType === 'html' ? 'selected-icon' : ''}`}
                    onClick={() => handleUpdateLink(editor.id, { iconType: 'html' })}
                  />
                </div>
                <input
                  type="text"
                  placeholder="URL"
                  className="url-input"
                  value={editor.url}
                  onChange={(e) => handleUpdateLink(editor.id, { url: e.target.value })}
                />
               <button
  className="delete-link-button"
  onClick={() => {
    removeFromDatabase('syllabusLinkItem', editor.id); // Remove from database
    setLinkEditors(linkEditors.filter(item => item.id !== editor.id)); // Remove from state
  }}
>
  Delete
</button>

                <button
                  className="view-link-button"
                  onClick={() => window.open(editor.url, '_blank', 'noopener,noreferrer')}
                >
                  View
                </button>
              </div>
            ))}

            <button className="add-link-button" onClick={handleAddLink}>
              Add New Syllabus Link
            </button>
          </>
        ) : (
          <p>Select a topic to add a link.</p>
        )}
      </div>
    </div>
  );
};

export default SyllabusLinkAdder;
