import React, { useState, useContext } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faTimes } from '@fortawesome/free-solid-svg-icons';
import LogicEntry from './LogicEntry';
import useAutoResizeTextareas from '../utils/useAutoResizeTextareas';
import { debounce } from 'lodash';


const QuestionCreator = ({ questionCourseDate, dataIndex, dataText, questionLogic, questionOptions, questionType, dataID }) => {
    const { handleChange, hasContradictionItems } = useContext(CourseDesignContext);
    const [currentQuestionType, setCurrentQuestionType] = useState(questionType || 'LongAnswer');
    const [questionText, setQuestionText] = useState(dataText || '');
    const [options, setOptions] = useState(questionOptions ? questionOptions.split('|||') : ['', '']);
    const [logic, setLogic] = useState(questionLogic || '');
    const [currentDataID] = useState(dataID || '');
const [showLogic, setShowLogic] = useState(!!logic); // Ensure showLogic is true only if logic exists

        // autoresize all text areas on a page
    useAutoResizeTextareas();


    // Define event handlers to manage user inputs instead of useEffect to avoid unnecessary re-renders and stale state updates
  // Create a debounced version of handleChange
const debouncedHandleChange = debounce((newText) => {
    handleChange(
        'question',
        currentDataID,
        newText,
        {
            questionType: currentQuestionType,
            questionOptions: options.join('|||'),
            questionLogic: logic,
            questionCourseDate,
            dataIndex: dataIndex.toString(),
        }
    );
}, 300); // Adjust the debounce delay as needed

// Handle text input changes
const handleTextChange = (e) => {
    const newText = e.target.value;
    setQuestionText(newText); // Update local state immediately for responsiveness
    debouncedHandleChange(newText); // Debounced global state update
};


    const handleOptionChange = (index, value) => {
        const newOptions = [...options];
        newOptions[index] = value;
        setOptions(newOptions);

        handleChange(
    'question',
    currentDataID,
    questionText,
    {
        questionType: currentQuestionType,
        questionOptions: newOptions.join('|||'),
        questionLogic: logic,
        questionCourseDate,
        dataIndex: dataIndex.toString(),
    }
);

    };

    const handleTypeChange = (type) => {
        setCurrentQuestionType(type);

        handleChange(
    'question',
    currentDataID,
    questionText,
    {
        questionType: type,
        questionOptions: options.join('|||'),
        questionLogic: logic,
        questionCourseDate,
        dataIndex: dataIndex.toString(),
    }
);

    };

    const handleLogicChange = (newLogic) => {
        setLogic(newLogic);
        setShowLogic(newLogic !== '');

        handleChange(
    'question',
    currentDataID,
    questionText,
    {
        questionType: currentQuestionType,
        questionOptions: options.join('|||'),
        questionLogic: newLogic,
        questionCourseDate,
        dataIndex: dataIndex.toString(),
    }
);

    };

    const addOption = () => {
    const newOptions = [...options, ''];
    setOptions(newOptions);

   handleChange(
    'question',
    currentDataID,
    questionText,
    {
        questionType: currentQuestionType,
        questionOptions: newOptions.join('|||'),
        questionLogic: logic,
        questionCourseDate,
        dataIndex: dataIndex.toString(),
    }
);

};

    

    const questionTypes = [
        { value: 'Radio', label: 'single choice' },
        { value: 'Checkbox', label: 'multiple choice' },
        { value: 'LongAnswer', label: 'essay' },
        ...(hasContradictionItems
    ? [{ value: 'Contradiction', label: 'contradiction' }]
    : [])
    ];



 const removeOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);

    // Update the database via handleChange
    handleChange(
        'question',
        currentDataID,
        questionText,
        {
            questionType: currentQuestionType,
            questionOptions: newOptions.join('|||'),
            questionLogic: logic,
            questionCourseDate,
            dataIndex: dataIndex.toString(),
        }
    );
};


    const copyOption = (index) => {
        const optionString = `(${currentDataID}, ${index + 1})`;  // Generate the string in the correct format
        navigator.clipboard.writeText(optionString).then(() => {
            console.log(`Copied to clipboard: ${optionString}`);
        }).catch(err => {
            console.error('Failed to copy to clipboard:', err);
        });
    };

    return (
        <div className="questionDesignBox">
            <div style={{ marginBottom: '8px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex' }}>
                    {questionTypes.map((type) => (
                        <span
                            key={type.value}
                            className={`radioButton ${currentQuestionType === type.value ? 'selected' : ''}`}
                            onClick={() => handleTypeChange(type.value)}
                            style={{ marginRight: '8px', cursor: 'pointer' }}
                        >
                            {type.label}
                        </span>
                    ))}
                </div>
                <div>
                    <span style={{ fontStyle: 'italic', fontSize: '0.9em', color: '#666' }}>ID: {currentDataID}</span>
                </div>
            </div>

            <div style={{ marginBottom: '8px' }}>
                <textarea
                    rows={1}
    className='questionTextArea'
    value={questionText}
    onChange={handleTextChange} // Existing handler to handle text changes
    placeholder="Question Text"
/>

            </div>

            {(currentQuestionType === 'Radio' || currentQuestionType === 'Checkbox') && (
                <div style={{ marginBottom: '8px' }}>
                   {options.map((option, index) => (
    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
        {/* Copy Button */}
        <button
            type="button"
            onClick={() => copyOption(index)}
            style={{
                marginRight: '8px',
                border: 'none',
                cursor: 'pointer',
                backgroundColor: 'transparent',
            }}
            title="Copy Option"
        >
            <FontAwesomeIcon icon={faCopy} style={{ cursor: 'pointer', color: 'var(--color2)', fontSize: '1.6em' }} />
        </button>

        {/* Option Text Textarea with Placeholder */}
                           <textarea
                               rows={1}
            value={option}
            onChange={(e) => handleOptionChange(index, e.target.value)}
            placeholder={`Option #${index + 1}`}
            style={{
                padding: '8px',
                flex: '0.9',
                maxWidth: '80%',
                borderRadius: '4px',
                fontFamily: 'var(--font-mono)',
                border: 'none',
                fontSize: '14px',
                boxShadow: 'var(--standard-box-shadow)',
                overflow: 'hidden', // Prevent scrollbars
                resize: 'none', // Disable manual resizing
                minHeight: '16px', // Set minimum height
            }}
           
        />

        {/* Delete Button */}
        {options.length > 2 && (
            <button
                type="button"
                onClick={() => removeOption(index)}
                style={{
                    marginLeft: '8px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                }}
                title="Remove Option"
            >
                <FontAwesomeIcon icon={faTimes} style={{ color: '#e74c3c', cursor: 'pointer' }} />
            </button>
        )}
    </div>
))}


                    <button
                        type="button"
                        onClick={addOption}
                        style={{
                            marginTop: '8px',
                            padding: '6px',
                            borderRadius: '2px',
                            backgroundColor: 'var(--color2)',
                            color: '#fff',
                            textTransform: 'lowercase',
                            border: 'none',
                            cursor: 'pointer',
                            marginLeft: 'calc(40px + 1.5em)'
                        }}
                    >
                        + add option
                    </button>
                </div>
            )}

         {showLogic ? (
    <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
        <label style={{ marginRight: '8px', marginTop: '10px' }}>logic:</label>
        <LogicEntry
            initialValue={logic} // Pass the initial logic value
            onChange={handleLogicChange} // Use the named handler here
        />

        <button
            type="button"
            onClick={() => {
                setLogic('');
                setShowLogic(false); // Switch back to non-edit mode
                    // Notify the database of the updated state
        handleChange(
            'question',
            currentDataID,
            questionText,
            {
                questionType: currentQuestionType,
                questionOptions: options.join('|||'),
                questionLogic: '', // Clear the logic field
                questionCourseDate,
                dataIndex: dataIndex.toString(),
            }
        );
            }}
            style={{
                marginLeft: '8px',
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
            }}
            title="Remove Logic"
        >
            <FontAwesomeIcon icon={faTimes} style={{ color: '#e74c3c', cursor: 'pointer' }} />
        </button>
    </div>
) : (
    <button
        type="button"
          onClick={() => {
        setShowLogic(true); // Show the logic section
    }}
        style={{ marginTop: '8px', padding: '6px', borderRadius: '2px', backgroundColor: 'var(--color1)', color: '#fff', border: 'none', cursor: 'pointer' }}
    >
        + add logic
    </button>
)}

        </div>
    );
};

export default QuestionCreator;
