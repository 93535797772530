import React, { useContext, useState } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import CourseDesignQuestion from '../molecules/CourseDesignQuestion';

// Import placeholder components (to be implemented later)
import CourseDesignCourseDescription from '../molecules/CourseDesignCourseDescription';
import CourseDesignSectionPicker from '../molecules/CourseDesignSectionPicker';
import CourseDesignFAQ from '../molecules/CourseDesignFAQ';
import CourseDesignRubric from '../molecules/CourseDesignRubric';

const CourseDesignGeneral = () => {
  const { courseDesignState } = useContext(CourseDesignContext);
  const [activeSection, setActiveSection] = useState('basics');

  const hasCourseDescription = courseDesignState.find(item => item?.dataType === 'hasCourseDescription')?.dataText === 'Yes';
  const hasFAQ = courseDesignState.find(item => item?.dataType === 'hasFAQ')?.dataText === 'Yes';
  const hasRubric = courseDesignState.find(item => item?.dataType === 'hasRubric')?.dataText === 'Yes';

  

  // Function to render the content based on the active section
const renderContent = () => {
  switch (activeSection) {
    case 'basics':
      return <BasicInformation />;
    case 'classSections':
      return <CourseDesignSectionPicker />;
 
    case 'courseDescription':
      return <CourseDesignCourseDescription />;
 
        case 'faq':
    return <CourseDesignFAQ />;
        case 'rubric':
      return <CourseDesignRubric />;
    default:
      return <BasicInformation />;
  }
};


  return (
    <div className='subcomponent-wrapper'>
      <div className="subcomponent-top-menu">
        <button
          className={`menu-button ${activeSection === 'basics' ? 'active' : ''}`}
          onClick={() => setActiveSection('basics')}
        >
          Basics
        </button>
          <button
        className={`menu-button ${activeSection === 'classSections' ? 'active' : ''}`}
        onClick={() => setActiveSection('classSections')}
      >
        Class Sections
      </button>
       
        {hasCourseDescription && (
          <button
            className={`menu-button ${activeSection === 'courseDescription' ? 'active' : ''}`}
            onClick={() => setActiveSection('courseDescription')}
          >
            Course Description
          </button>
        )}
       
           {hasFAQ && (
          <button
            className={`menu-button ${activeSection === 'faq' ? 'active' : ''}`}
            onClick={() => setActiveSection('faq')}
          >
            FAQ
          </button>
        )}
           {hasRubric && (
          <button
            className={`menu-button ${activeSection === 'rubric' ? 'active' : ''}`}
            onClick={() => setActiveSection('rubric')}
          >
            Rubric
          </button>
        )}
      </div>
      <div className="subcomponent-content">
        {renderContent()}
      </div>
    </div>
  );
};

// BasicInformation component (formerly the content of CourseDesignGeneral)
const BasicInformation = () => {
  const { courseDesignState, handleChange } = useContext(CourseDesignContext);

  // Extracting all relevant data from state
  const courseTitle = courseDesignState.find(item => item?.dataType === 'courseTitle')?.dataText || '';
  const instructorName = courseDesignState.find(item => item?.dataType === 'instructorName')?.dataText || '';
  const areThereTAs = courseDesignState.find(item => item?.dataType === 'areThereTAs')?.dataText || '';
const hasCourseDescription = courseDesignState.find(item => item?.dataType === 'hasCourseDescription')?.dataText || '';
  const hasSyllabus = courseDesignState.find(item => item?.dataType === 'hasSyllabus')?.dataText || '';
  const hasFAQ = courseDesignState.find(item => item?.dataType === 'hasFAQ')?.dataText || '';
  const hasRubric = courseDesignState.find(item => item?.dataType === 'hasRubric')?.dataText || '';
const hasContradictionItems = courseDesignState.find(item => item?.dataType === 'hasContradictionItems')?.dataText || '';
  const courseUsesGrading = courseDesignState.find(item => item.dataType === 'courseUsesGrading')?.dataText;

  return (
    <div>
      
      

      <CourseDesignQuestion
        questionType="text"
        dataType="courseTitle"
        dataID="0"
        question="Course title:"
        value={courseTitle}
        onChange={(value) => handleChange('courseTitle', '0', value)}
        inputStyle={{ width: '700px' }}
      />
<br></br>
      
      <CourseDesignQuestion
        questionType="text"
        dataType="instructorName"
        dataID="0"
        question="Instructor name:"
        value={instructorName}
        onChange={(value) => handleChange('instructorName', '0', value)}
                inputStyle={{ width: '500px' }}
      />
      <br></br>
      
      <div> The following are some basic questions about the structure of the website you want to create. You can change any of these answers later without messing anything up. So at this point just make your best guesses, and you can come back to this page later if you change your mind. </div>

      <br></br>
      <CourseDesignQuestion
        questionType="select"
        dataType="areThereTAs"
        dataID="0"
        question="Does this course have TAs and/or graders?"
        options={['Yes', 'No']}
        value={areThereTAs}
        onChange={(value) => handleChange('areThereTAs', '0', value)}
      />

      <br></br>
      
        <CourseDesignQuestion
        questionType="select"
        dataType="courseUsesGrading"
        dataID="0"
        question="You can use this website to evaluate students' work. This might include one or more of: giving students numeric grades, giving students letter grades, writing comments about students' work. Would you like to do one or more of these things?"
        options={['Yes', 'No']}
        value={courseUsesGrading}
        onChange={(value) => handleChange('courseUsesGrading', '0', value)}
      />
<br></br>

            <CourseDesignQuestion
        questionType="select"
        dataType="hasCourseDescription"
        dataID="0"
        question="Would you like to display a course description on this website?"
        options={['Yes', 'No']}
        value={hasCourseDescription}
        onChange={(value) => handleChange('hasCourseDescription', '0', value)}
      />
<br></br>
      <CourseDesignQuestion
        questionType="select"
        dataType="hasSyllabus"
        dataID="0"
        question="Would you like to display a syllabus on this website?"
        options={['Yes', 'No']}
        value={hasSyllabus}
        onChange={(value) => handleChange('hasSyllabus', '0', value)}
      />

      <br></br>
      <CourseDesignQuestion
        questionType="select"
        dataType="hasFAQ"
        dataID="0"
        question="Would you like to display an FAQ page for students?"
        options={['Yes', 'No']}
        value={hasFAQ}
        onChange={(value) => handleChange('hasFAQ', '0', value)}
      />

      <br></br>
      <CourseDesignQuestion
        questionType="select"
        dataType="hasRubric"
        dataID="0"
        question="Would you like to display a rubric?"
        options={['Yes', 'No']}
        value={hasRubric}
        onChange={(value) => handleChange('hasRubric', '0', value)}
      />

       <br></br>
      <CourseDesignQuestion
        questionType="select"
        dataType="hasContradictionItems"
        dataID="0"
        question="This site displays questions to students based on the time of the semester and the students' answers to previous questions. In addition to questions, you can display contradiction notifications which show when a student's previous answers are inconsistent. You can also use these as part of a grading rubric (if you use a rubric). Would you like to be able to do this?"
        options={['Yes', 'No']}
        value={hasContradictionItems}
        onChange={(value) => handleChange('hasContradictionItems', '0', value)}
      />
    
    </div>
  );
};

export default CourseDesignGeneral;