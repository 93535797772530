// CourseDesignCourseDescription.js
import React, { useState, useEffect, useContext } from 'react';
import MarkdownIt from 'markdown-it';
import { CourseDesignContext } from '../context/CourseDesignContext';
import './CourseDesignCourseDescription.css'; // Optional CSS for styling

const md = new MarkdownIt();

const CourseDesignCourseDescription = () => {
    const { handleChange, courseDesignState } = useContext(CourseDesignContext);
    const [markdown, setMarkdown] = useState('');

    // Load existing description from context
    useEffect(() => {
        const existingDescription = courseDesignState.find(item => item.dataType === "courseDescription" && item.dataID === "1");
        if (existingDescription) {
            setMarkdown(existingDescription.dataText);
        }
    }, [courseDesignState]);

    // Handle text changes
    const handleMarkdownChange = (event) => {
        const content = event.target.value;
        setMarkdown(content); // Update local state
        handleChange("courseDescription", "1", content); // Save Markdown to state/database
    };

    return (
        <div className="markdown-editor-container">
            <div>You can use the field below to enter your course description. You can style the text using Markdown. A preview is shown on the right.</div>
            <br />
            <div className="editor-preview-wrapper">
                <textarea
                    value={markdown}
                    onChange={handleMarkdownChange}
                    placeholder="Enter course description in Markdown..."
                    className="markdown-textarea"
                />
                <div
                    className="markdown-preview"
                    dangerouslySetInnerHTML={{ __html: md.render(markdown) }}
                />
            </div>
        </div>
    );
};

export default CourseDesignCourseDescription;
