// StudentProgressView.js
import React, { useContext, useMemo } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import { StudentAnswerContext } from '../context/StudentAnswerContext';
import {
  getTopicsForSection,
  getQuestionsForTopic,
} from '../utils/QAndAHelpers';
import { evaluateQuestionLogic } from '../utils/questionLogicEvaluator'; // Adjusted import path
import MyPhilosophyHeader from '../atoms/MyPhilosophyHeader';
import './StudentProgressView.css'; // Add styles as needed
import getCurrentCourseDay from '../utils/getCurrentCourseDay'; // Adjusted import path
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEdit } from '@fortawesome/free-solid-svg-icons';


const StudentProgressView = ({ username }) => {
  const { courseDesignState, hasContradictionItems } = useContext(CourseDesignContext);
  const { studentAnswersState } = useContext(StudentAnswerContext);
  const userAnswers = useMemo(
    () => studentAnswersState[username] || [],
    [studentAnswersState, username]
  );


  // Get the current course date 

const currentCourseDate = useMemo(() => {
  return getCurrentCourseDay(courseDesignState);
}, [courseDesignState]);




  // Get all sections and order them by dataIndex after converting that string into a number
const sections = useMemo(() => {
  return courseDesignState
    .filter((item) => item.dataType === 'sectionName')
    .sort((a, b) => a.dataIndex - b.dataIndex); // Sort sections by dataIndex
}, [courseDesignState]);


  return (
    <div className="student-progress-view">
      <MyPhilosophyHeader username={username} />
      {sections.map((section) => {
  const topics = getTopicsForSection(
    section.dataID,
    false, // ignoreCurrentCourseDate is false
    courseDesignState,
    currentCourseDate // Pass the actual current course date
  );
        const allQuestions = topics.flatMap((topic) =>
          getQuestionsForTopic(topic.dataID, courseDesignState)
        );

        // Filter questions that should be shown to the user
        const shownQuestions = allQuestions.filter((question) =>
          evaluateQuestionLogic(question.questionLogic, courseDesignState, userAnswers)
        );

        // helper function to check properties of student's answer to a question, if it exists
        const getUserAnswer = (questionID) =>
  userAnswers.find((answer) => answer.questionID === questionID);


        // Calculate counts using shownQuestions
      const unansweredMultipleChoice = shownQuestions.filter((question) => {
  if (question.questionType !== 'Radio' && question.questionType !== 'Checkbox') return false;

  const userAnswer = getUserAnswer(question.dataID);
        if (!userAnswer) return true; // Unanswered

  const answerValue = userAnswer.questionAnswer;
  if (typeof answerValue !== 'string') return true; // Unanswered

  // Check if answerValue includes at least one numeral
        return !/\d/.test(answerValue); // If no numerals, it's considered unanswered

}).length;


      const unansweredEssay = shownQuestions.filter((question) => {
  if (question.questionType !== 'LongAnswer') return false;

  const userAnswer = getUserAnswer(question.dataID);
  if (!userAnswer) return true; // Unanswered

  const essayAnswer = userAnswer.questionEssayAnswer;
  if (typeof essayAnswer !== 'string') return true; // Unanswered

  // Trim whitespace and check length
  const trimmedAnswer = essayAnswer.trim();
  return trimmedAnswer.length <= 1; // If empty or one character, it's considered unanswered
}).length;


      const unresolvedContradictions = shownQuestions.filter(
  (question) => question.questionType === 'Contradiction'
).length;


        // Check if section is complete
        const isComplete =
          unansweredMultipleChoice === 0 &&
          unansweredEssay === 0 &&
          unresolvedContradictions === 0;

        return (
        <div key={section.dataID} className="section-progress">
    <h2>
      <span className="section-header">{section.dataText}</span>
      <FontAwesomeIcon 
        icon={isComplete ? faCheckCircle : faEdit} 
        className="status-icon" 
      />
    </h2>
    <p>Unanswered multiple choice questions: {unansweredMultipleChoice}</p>
    <p>Unanswered essay questions: {unansweredEssay}</p>
    {hasContradictionItems && (
      <p>Unresolved contradictions: {unresolvedContradictions}</p>
    )}
  </div>
        );
      })}
    </div>
  );
};

export default StudentProgressView;
