import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';



const LogicDisplay = ({ value, onEdit, courseDesignState }) => {
    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    

    const pattern = /\(([\w-]+),\s*(\d+)\)/g;

let hoverTimeout;

const handleMouseEnter = (dataID, optionNumber, event) => {
    clearTimeout(hoverTimeout); // Clear any existing timeout to prevent hiding

    const questionItem = courseDesignState.find(item => item.dataID === dataID);
    if (questionItem) {
        const optionsArray = questionItem.questionOptions.split('|||');
        const optionText = optionsArray[Number(optionNumber) - 1] || '';
        setTooltipContent(`Question: ${questionItem.dataText}\n\nAnswer: ${optionText}`);
    }
    setTooltipPosition({ x: event.clientX, y: event.clientY - 20 });
};

const handleMouseLeave = () => {
    hoverTimeout = setTimeout(() => setTooltipContent(''), 200); // Delay hiding the tooltip by 200ms
};




    const renderHighlightedText = (text) => {
        const parts = [];
        let lastIndex = 0;

        text.replace(pattern, (match, dataID, optionNumber, offset) => {
            parts.push(text.slice(lastIndex, offset)); // Text before the match
            parts.push(
                <span
                    key={`${dataID}-${optionNumber}`}
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={(e) => handleMouseEnter(dataID, optionNumber, e)}
                    onMouseLeave={handleMouseLeave}
                >
                    {match}
                </span>
            );
            lastIndex = offset + match.length;
        });

        parts.push(text.slice(lastIndex)); // Remaining text after the last match
        return parts;
    };

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'var(--font-mono)', whiteSpace: 'pre-wrap' }}>
    <div style={{ flexGrow: 1 }}>
        {renderHighlightedText(value || '')}

    </div>
    <button
    onClick={onEdit}
    style={{
        marginLeft: '8px',
        cursor: 'pointer',
        transform: 'scale(0.67)', // Shrinks to 2/3 of original size
        transformOrigin: 'center', // Ensures scaling occurs from the center
    }}
>
    <FontAwesomeIcon icon={faPencilAlt} />
</button>
</div>


            {tooltipContent && (
                <div
                            className="logic-hover-display"

                    style={{
                        top: tooltipPosition.y,
                        left: tooltipPosition.x,
                    }}
                >
                    {tooltipContent}
                </div>
            )}
        </div>
    );
};

export default LogicDisplay;
