import React, { useContext } from 'react';
//import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import LoginForm from '../atoms/LoginForm';
import './LoginPage.css';

const LoginPage = ( {onLoginSuccess}) => {
  const { handleLogin } = useContext(AuthContext);
  //const navigate = useNavigate();


  return (
    <div className="loginContainer">
      <h1>Login Page</h1>
      <LoginForm onLoginSuccess={onLoginSuccess} handleLogin={handleLogin} />
    </div>
  );
};

export default LoginPage;