// CourseDescription.js
import React, { useState, useEffect, useContext } from 'react';
import MarkdownIt from 'markdown-it'; // Import markdown-it
import { CourseIDContext } from '../context/CourseIDContext';
import { fetchAllCourseDesignData } from '../utils/serverFetchAndAddUtilities';

const md = new MarkdownIt(); // Initialize markdown-it instance

const CourseDescription = () => {
    const { courseID } = useContext(CourseIDContext);
    const [courseTitle, setCourseTitle] = useState('');
    const [courseDescription, setCourseDescription] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchAllCourseDesignData(courseID);
                const titleItem = data.find(item => item.dataType === "courseTitle");
                const descriptionItem = data.find(item => item.dataType === "courseDescription" && item.dataID === "1");

                if (titleItem) setCourseTitle(titleItem.dataText);
                if (descriptionItem) setCourseDescription(descriptionItem.dataText); // Save Markdown content
            } catch (error) {
                console.error("Error fetching course description data:", error);
            }
        };

        fetchData();
    }, [courseID]);

    return (
        <div>
            <h1>{courseTitle}</h1>
            <div
                className="course-description-content"
                dangerouslySetInnerHTML={{ __html: md.render(courseDescription) }} // Convert Markdown to HTML
            />
        </div>
    );
};

export default CourseDescription;
