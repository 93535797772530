import React, { useState } from 'react';

const HelpTooltip = ({ children }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      onMouseEnter={() => setVisible(true)} // Show tooltip on hover
      onMouseLeave={() => setVisible(false)} // Hide tooltip when mouse leaves
    >
      <button
        onClick={() => setVisible(!visible)} // Toggle tooltip on click
        style={{
          backgroundColor: 'white',
          boxShadow: 'var(--standard-box-shadow)',
          border: 'none',
          borderRadius: '50%',
          color: 'var(--color2)',
          cursor: 'pointer',
            fontSize: '15px',
            padding: '6px',
            height: '30px',
            width: '30px',
        }}
      >
        ?
      </button>
      {visible && (
        <div
          style={{
            position: 'absolute',
            top: '120%',
            left: '50%',
            transform: 'translateX(-50%)',
            fontFamily: 'var(--font-secondary)',
            fontSize: '16px',
            padding: '10px',
            borderRadius: '4px',
            width: '500px',
            backgroundColor: 'white',
            boxShadow: 'var(--standard-box-shadow)',
            zIndex: 10,
            whiteSpace: 'pre-line', // This preserves line breaks in text
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default HelpTooltip;
