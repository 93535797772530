// atoms/ScheduleMakerSubcomponents.js

import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsUpDown, faTrash, faPlus, faTimes, faFileCircleQuestion, faBullseye } from '@fortawesome/free-solid-svg-icons';


export const CourseDateItem = ({ item, onChange, regularizeCourseSchedule }) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);
  const handleInputChange = (e) => {
    const newDate = e.target.value;
    const formattedDate = formatDate(newDate);
      onChange(item.dataType, item.dataID, formattedDate, { questionLogic: newDate });
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.showPicker();
    }
  }, [isEditing]);

  return (
    <div className="course-date-item">
      {isEditing ? (
        <input
          type="date"
          ref={inputRef}
          value={item.questionLogic !== '??' ? item.questionLogic : ''}
          onChange={handleInputChange}
          onBlur={handleBlur}
          autoFocus
          className="editable-input"
        />
      ) : (
        <div onClick={() => setIsEditing(true)} className="editable-text">
          {item.dataText !== '??' ? item.dataText : 'Set date'}
        </div>
      )}
    </div>
  );
};

// Utility function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1); // Adjust the date to avoid being one day too early
  const options = { weekday: 'short', month: 'numeric', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};




export const TopicItem = ({ item, onChange, removeItem, movementButtonAction, handleShowAddItemButtons, currentIndex, hasAssociatedQuestions }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (e) => {
    onChange(item.dataType, item.dataID, e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <div className="topic-item">
      {isEditing ? (
        <textarea
          value={item.dataText}
          onChange={handleInputChange}
          onBlur={handleBlur}
          autoFocus
          className="editable-textarea"
        />
      ) : (
        <div onClick={() => setIsEditing(true)} className="editable-text">
                      {item.dataText || 'Click to edit topic'}
                      {hasAssociatedQuestions && (
                          <FontAwesomeIcon icon={faFileCircleQuestion} style={{ color: 'var(--color2)', marginLeft:'8px' }} className="action-icon" />
                      )}
                      

        </div>
      )}
      {/* Action Buttons */}
     <div className="action-buttons">
        <button className="action-button" onClick={() => movementButtonAction(currentIndex)}>
          <FontAwesomeIcon icon={faArrowsUpDown} style={{ color: 'var(--color2)' }} className="action-icon" />
        </button>
      
        <button className="action-button" onClick={() => removeItem(item)}>
          <FontAwesomeIcon icon={faTrash} style={{ color: 'var(--color2)' }} className="action-icon" />
              </button>
              <button className="action-button" onClick={() => handleShowAddItemButtons(currentIndex)}>
          <FontAwesomeIcon icon={faPlus} style={{ color: 'var(--color2)' }} className="action-icon" />
        </button>
      </div>
    </div>
  );
};


export const SectionNameItem = ({
  item,
  onChange,
  removeItem,
  movementButtonAction,
  handleShowAddItemButtons,
  currentIndex,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (e) => {
    onChange(item.dataType, item.dataID, e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <div className="section-name-item">
      {isEditing ? (
        <textarea
          value={item.dataText}
          onChange={handleInputChange}
          onBlur={handleBlur}
          autoFocus
          className="editable-textarea"
        />
      ) : (
        <div onClick={() => setIsEditing(true)} className="editable-text">
                      {item.dataText || 'Click to edit section name'}
                      
        </div>
      )}
      {/* Action Buttons */}
      <div className="action-buttons">
        <button className="action-button" onClick={() => movementButtonAction(currentIndex)}>
          <FontAwesomeIcon icon={faArrowsUpDown} style={{ color: 'var(--color2)' }} className="action-icon" />
        </button>
        <button className="action-button" onClick={() => removeItem(item)}>
          <FontAwesomeIcon icon={faTrash} style={{ color: 'var(--color2)' }} className="action-icon" />
              </button>
        <button className="action-button" onClick={() => handleShowAddItemButtons(currentIndex)}>
          <FontAwesomeIcon icon={faPlus} style={{ color: 'var(--color2)' }} className="action-icon" />
        </button>
      </div>
    </div>
  );
};



export const NewSectionHeaderButton = ({ onClick }) => (
  <div className="new-section-header-button">
    <button className="addSectionHeader" onClick={onClick}>
      add new section header
    </button>
  </div>
);

export const NewClassDayButton = ({ onClick }) => (
  <div className="new-class-day-button">
    <button className="addClassDay" onClick={onClick}>
      add new class day
    </button>
  </div>
);

export const CancelAddNewItemButton = ({onClick}) => (
  <div className="cancel-add-new-item-button">
        <button className="cancelAddNewItem" onClick={onClick}>
      <FontAwesomeIcon icon={faTimes} />
    </button>
  </div>
);

export const MoveToHereButton = ({  dataText, onClick, show }) => (
  show ? (
        <div className="move-to-here-container" style={{ position: 'relative' }}>
    <button className="move-to-here-button" onClick={onClick}>
      <FontAwesomeIcon icon={faBullseye} />
      {dataText}
      </button>
      </div>
  ) : null
);