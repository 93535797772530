import axios from 'axios';

// Fetches all students for a specific course, excluding TAs and instructors
export const fetchStudents = async (courseID) => {
  const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users/${courseID}`);
//  console.log('response', response);
  return response.data.filter(user => user.status.toLowerCase() === 'student');
};

// fetches studnets for a specific TA or for the instructor fetches all students
export const fetchStudentsForUser = async (courseID, username) => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  try {
    // Fetch the user's status
    const userStatusResponse = await axios.get(`${apiUrl}/api/authenticate/status/${courseID}/${username}`);
    const userStatus = userStatusResponse.data.status.toLowerCase();

    // Fetch all students for the course
    const allUsersResponse = await axios.get(`${apiUrl}/api/users/${courseID}`);
    const allUsers = allUsersResponse.data;

    if (userStatus === 'instructor') {
      // Return all students for instructors
      return allUsers.filter(user => user.status.toLowerCase() === 'student');
    } else if (userStatus === 'ta') {
      // Return only students assigned to this TA
      return allUsers.filter(user => user.status.toLowerCase() === 'student' && user.TA === username);
    } else {
      throw new Error('Unauthorized access. Only instructors or TAs can fetch student lists.');
    }
  } catch (error) {
    console.error('Error fetching students for user:', error);
    throw error;
  }
};

// Fetches all users for a specific course, including TAs and instructors
export const fetchAllUsers = async (courseID) => {
  const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users/${courseID}`);
  console.log('response', response);
  return response.data
};

// takes as argument a username and fetches the status property of the user object
export const fetchUserStatus = async (courseID, username) => {
  const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users/${courseID}/${username}`);
  return response.data.status;
};


// Fetches a student's answer history for a specific course
export const fetchStudentHistory = async (courseID, username) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/studentAnswersHistory/${courseID}/${username}`
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return [];
    } else {
      console.error('Error fetching student answers:', error);
      throw error;
    }
  }
};

// Fetches students and calculates total word counts for their answers
export const fetchStudentsAndWordCounts = async (courseID) => {
  const students = await fetchStudents(courseID);
  const wordCountPromises = students.map(async (student) => {
    const answers = await fetchStudentHistory(courseID, student.username);
    const wordCount = calculateTotalEssayWordCount(answers);
    return { username: student.username, fullName: student.fullName, totalWordCount: wordCount };
  });

  const studentsWithWordCounts = await Promise.all(wordCountPromises);
  return studentsWithWordCounts.sort((a, b) => b.totalWordCount - a.totalWordCount);
};

// Assigns a grade for a specific evaluation and user
export const assignGrade = async (courseID, evaluationId, username) => {
  const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/grade/${courseID}`, { evaluationId, username });
  return response.data;
};

// Assigns a student to a TA for a specific course
export const assignStudentToTA = async (courseID, studentUsername, instructorUsername) => {
  return axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/users/${courseID}/${studentUsername}`, { TA: instructorUsername });
};

// Unassigns a student from a TA for a specific course
export const unassignStudentFromTA = async (courseID, studentUsername) => {
  return axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/users/${courseID}/${studentUsername}`, { TA: '0' });
};

// Updates user data for a specific course
export const updateUser = async (courseID, username, updatedData) => {
  return axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/users/${courseID}/${username}`, updatedData);
};

// Deletes a user for a specific course
export const deleteUser = async (courseID, username) => {
  return axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/users/${courseID}/${username}`);
};

// Adds a new user to a specific course
export const addUser = async (courseID, username, password, fullName, role) => {
  return axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/${courseID}/add`, {
    username,
    password,
    fullname: fullName,
    TA: role === 'TA' ? 1 : 0,
    status: role
  });
};

// Batch adds users from a file (CSV or Excel) for a specific course
export const batchAddUsers = async (courseID, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/${courseID}/batch-add`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

// Helper function to calculate total word count of essay answers
export const calculateTotalEssayWordCount = (answersData) => {
  return answersData.reduce((total, answer) => total + (answer.wordCount || 0), 0);
};

// Fetches all course design data for a specific course
export const fetchAllCourseDesignData = async (courseID) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/courseDesign/${courseID}`);
    return response.data; // Return all data, unfiltered
  } catch (error) {
    console.error('Error fetching all course design data:', error);
    throw error;
  }
};

export const fetchStudentAnswersHistory = async (courseID, username) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/studentAnswersHistory/${courseID}/${username}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching student answers:', error);
        return [];
    }
};

// fetches all student grades for a single student for a course

export const fetchStudentGrades = async (courseID, username) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/studentGrades/${courseID}/${username}`);

    // Handle case where the response is an object instead of an array
    if (response.data && Array.isArray(response.data)) {
      return response.data; // Data is already an array
    } else if (response.data && typeof response.data === 'object') {
      console.warn('Grades returned as object; converting to array');
      return Object.values(response.data).flat(); // Flatten object values into a single array
    }
    // Return empty array if no data
    return [];
  } catch (error) {
    console.error('Error fetching student grades:', error);
    return [];
  }
};




// fetches grades and then filters by column type to get data relevant for student grading table.

export const fetchGradesForExamColumns = async (courseID) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/studentGrades/${courseID}`);
    return response.data.filter(
      (item) => item.gradingType === 'examGrade' || item.gradingType === 'gradingComplete'
    );
  } catch (error) {
    console.error('Error fetching grades for exam columns:', error);
    return [];
  }
};

// gets user answers as an array for a specific date. This is not suitable for QAndA, which expects an object with the question answers as keys. 
export const fetchUserAnswersArrayAsOfDate = async (courseID, username, targetDate) => {
  try {
    // Fetch all answers for the user using the function defined in the same file
    const history = await fetchStudentAnswersHistory(courseID, username);

    // Normalize dates in the history to yyyy-mm-dd format
 // Normalize submissionDates in the history to yyyy-mm-dd format
const normalizedHistory = history.map((answer) => ({
  ...answer,
  submissionDate: answer.submissionDate.split('T')[0], // Extract only the yyyy-mm-dd part
}));

// Step 1: Discard answers later than the targetDate
const filteredHistory = normalizedHistory.filter(
  (answer) => answer.submissionDate <= targetDate
);

// Step 2: Select the most recent answer for each questionID
const mostRecentAnswers = filteredHistory.reduce((acc, answer) => {
  const { questionID, submissionDate } = answer;

  // Keep the most recent answer for each questionID
  if (!acc[questionID] || acc[questionID].submissionDate < submissionDate) {
    acc[questionID] = answer;
  }

  return acc;
}, {});


    // Convert the mostRecentAnswers object into an array
    return Object.values(mostRecentAnswers);
  } catch (error) {
    console.error('Error fetching or processing user answers:', error);
    return []; // Return an empty array in case of failure
  }
};




export const fetchUserAnswersAsOfDate = async (courseID, username, targetDate) => {
  try {
    // Fetch all answers for the user using the function defined in the same file
    const history = await fetchStudentAnswersHistory(courseID, username);

  
  // Normalize submissionDates in the history to yyyy-mm-dd format
const normalizedHistory = history.map((answer) => ({
  ...answer,
  submissionDate: answer.submissionDate.split('T')[0], // Extract only the yyyy-mm-dd part
}));

// Step 1: Discard answers later than the targetDate
const filteredHistory = normalizedHistory.filter(
  (answer) => answer.submissionDate <= targetDate
);

// Step 2: Select the most recent answer for each questionID
const mostRecentAnswers = filteredHistory.reduce((acc, answer) => {
  const { questionID, submissionDate } = answer;

  // Keep the most recent answer for each questionID
  if (!acc[questionID] || acc[questionID].submissionDate < submissionDate) {
    acc[questionID] = answer;
  }

  return acc;
}, {});


    // Convert to the format expected by QAndA - object rather than array
    const transformedAnswers = Object.values(mostRecentAnswers).reduce((acc, answer) => {
      const { questionID, questionAnswer, questionEssayAnswer } = answer;
      if (!acc[username]) acc[username] = [];
      acc[username].push({
        questionID,
        questionAnswer,
        questionEssayAnswer: questionEssayAnswer || undefined, // Convert null to undefined
      });
      return acc;
    }, {});

    return transformedAnswers;
  } catch (error) {
    console.error('Error fetching or processing user answers:', error);
    return {}; // Return an empty object in case of failure
  }
};

// functon to extract the correct due date for a given student and evaluation
export const determineTargetDate = async (courseID, username, evaluationID, courseDesignState, fetchStudentGrades) => {
  try {
    const grades = await fetchStudentGrades(courseID, username); // Fetch all grades for the student
    console.log('Fetched grades:', grades);

    // Step 1: Look for a custom due date
    const customDueDateEntry = grades.find(
      (grade) =>
        grade.gradingType === 'customDueDate' && grade.evaluationDataID === evaluationID
    );

    if (customDueDateEntry) {
      return customDueDateEntry.individualGradingData; // Return the raw date string
    }

    // Step 2: Fallback to courseDesignState's questionCourseDate
    const evaluation = courseDesignState.find(
      (item) => item.dataID === evaluationID && item.dataType === 'evaluation'
    );
    if (evaluation) {
      return evaluation.questionCourseDate; // Return the raw date string
    }

    // Default to null if no date is found
    return null;
  } catch (error) {
    console.error('Error determining target date:', error);
    return null; // Return null on error
  }
};

// function that uses targetDate (obatined using the above function) to fetch student answers for a specific exam
export const fetchStudentAnswersForExam = async (courseID, username, targetDate, fetchUserAnswersAsOfDate) => {
  try {
    if (!targetDate) {
      throw new Error('Target date is required to fetch student answers.');
    }

    // Call the reusable server-side fetch function
    const answers = await fetchUserAnswersAsOfDate(courseID, username, targetDate);
    console.log('Fetched and processed answers:', answers);
    return answers; // Return the fetched answers
  } catch (error) {
    console.error('Error fetching student answers for exam:', error);
    return null; // Return null on error
  }
};

// fetch current answers for a student in a course

export const fetchStudentAnswers = async (courseID, username) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/studentAnswers/${courseID}/${username}`
    );

    // Assuming the API returns an array of answers, we transform it into the correct format
    const answers = response.data.reduce((acc, item) => {
      acc.push({
        questionID: item.questionID,
        questionAnswer: item.questionAnswer || "", // Default to empty string if no answer
        questionEssayAnswer: item.questionEssayAnswer || null, // Default to null if no essay answer
      });
      return acc;
    }, []);

    return {
      [username]: answers, // Return the formatted object
    };
  } catch (error) {
    console.error("Error fetching current student answers:", error);
    return { [username]: [] }; // Return empty array for username on error
  }
};







