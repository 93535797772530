import React, { useContext, useState, useEffect } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import CourseDesignQuestion from '../molecules/CourseDesignQuestion';
import { generateClassDays, generateUniqueId } from '../utils/courseDesignUtilities';
import { parseISO, format } from 'date-fns';


const CourseDays = () => {
  const { courseDesignState, handleChange, removeFromDatabase, prepareStateForUpsertToDatabase, updateDatabase, setCourseDesignState } = useContext(CourseDesignContext);

  const [showDateBoxes, setShowDateBoxes] = useState(false);
  const startDate = courseDesignState.find(item => item?.dataType === 'startDate')?.dataText || '';
  const endDate = courseDesignState.find(item => item?.dataType === 'endDate')?.dataText || '';
  const initialDatesGenerated = courseDesignState.find(item => item?.dataType === 'initialDatesGenerator')?.dataText === 'true';

  const meetingDays = courseDesignState.reduce((days, item) => {
    if (item?.dataType?.startsWith('meetsOn') && item?.dataText === 'true') {
      days[item?.dataType.replace('meetsOn', '')] = true;
    }
    return days;
  }, {});

  //const selectedDays = Object.keys(meetingDays).filter(day => meetingDays[day]);

  useEffect(() => {
    setShowDateBoxes(initialDatesGenerated);
  }, [initialDatesGenerated]);


  

  const handleNextClick = async () => {
    // Get the selected days of the week
    const selectedDays = Object.keys(meetingDays).filter(day => meetingDays[day]);
    // Generate the new dates based on the updated start date, end date, and days of the week
    const newDates = generateClassDays(startDate, endDate, selectedDays);
    newDates.forEach(dateString => {
      const date = parseISO(dateString); // Parse the date string to a Date object
      const formattedDate = format(date, 'EEE, M/d'); // Format date to "Mon, 8/23"
      const randomId = generateUniqueId(7); // Generate a random 7-digit alphanumeric code
      handleChange('courseDate', randomId, formattedDate, { questionType: "autoGenerated", questionLogic: dateString }); // Insert new dates with formatted date string
    });
      handleChange('initialDatesGenerator', '0', 'true'); // Update the state to show date boxes
    setShowDateBoxes(true); // Update local state to switch view

  };


    const handleDaySelectionChange = (day, isChecked) => {
      handleChange(`meetsOn${day}`, '0', isChecked);
    };
  
  
  const handleRemoveCourseDates = () => {
    // Remove items with dataType of "endDate", "startDate", and "meetsOn{day}"
    const dataTypesToRemove = ["meetsOnMonday", "meetsOnTuesday", "meetsOnWednesday", "meetsOnThursday", "meetsOnFriday"];
    courseDesignState
      .filter(item => dataTypesToRemove.includes(item.dataType))
      .forEach(item => {
        console.log(`Removing from database: dataType=${item.dataType}, dataID=${item.dataID}`);
        removeFromDatabase(item.dataType, item.dataID);
      });
// Remove items with dataType of "courseDate"
    const courseDatesToRemove = courseDesignState.filter(item => item.dataType === 'courseDate');
    courseDatesToRemove.forEach(item => {
      console.log(`Removing course date from database: dataType=${item.dataType}, dataID=${item.dataID}`);
      removeFromDatabase(item.dataType, item.dataID);
    });
    // Reset the state to hide the date boxes and go back to the initial date selection view
    const stateWithoutCourseDays = courseDesignState.filter(item => item.dataType !== 'courseDate');
    console.log('State without course dates:', stateWithoutCourseDays);
    setCourseDesignState(stateWithoutCourseDays);
    const stateForDatabase = prepareStateForUpsertToDatabase(stateWithoutCourseDays);
    updateDatabase(stateForDatabase);
  };
  
  // function to turn a date saved in yyyy-mm-dd format into a text object like "Monday, April 1, 2025"
  
  const handleDateText = (date) => {
    const dateObject = parseISO(date);
    const formattedDate = format(dateObject, 'EEEE, MMMM d, yyyy');
    return formattedDate;
  }

  const formattedStartDate = startDate ? handleDateText(startDate) : '';
  const formattedEndDate = endDate ? handleDateText(endDate) : '';


  return (
    <div>
      {!showDateBoxes ? (
        <>
          <p>
          This page generates the list of ordinary meeting days for your course. 
          You can add or delete days on the next page, and can always change this later.
        </p>

          <CourseDesignQuestion
            questionType="date"
            dataType="startDate"
            dataID="0"
            question="Start Date of Semester:"
            value={startDate}
            onChange={(value) => handleChange('startDate', '0', value)}
          />

          <CourseDesignQuestion
            questionType="date"
            dataType="endDate"
            dataID="0"
            question="End Date of Semester:"
            value={endDate}
            onChange={(value) => handleChange('endDate', '0', value)}
          />

          <div>
            <label>Days of the Week the Course Meets:</label>
            <div>
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((day, i) => (
                <div key={i}>
                  <input
                    type="checkbox"
                    checked={!!meetingDays[day]}
                    onChange={(e) => handleDaySelectionChange(day, e.target.checked)}
                  />
                  <label>{day}</label>
                </div>
              ))}
            </div>
          </div>

          <button onClick={handleNextClick}>save</button>
        </>
      ) : (
          <>
            <div style={{backgroundColor: 'white', padding: '25px', marginBottom: '25px', boxShadow: 'var(--standard-box-shadow)'}}>
          <p>
                Course Start Date: {formattedStartDate || 'Not set'}<br />
                <br></br>
                Course End Date: {formattedEndDate || 'Not set'}<br />
                <br></br>
        Regular Meeting Days: {Object.keys(meetingDays).length > 0 
          ? Object.keys(meetingDays).join(', ') 
          : 'None selected'}
      </p>

      <p>Click below to reset the meeting days for the course. This won't delete any of the course content.</p>


          <button
  onClick={() => {
                // Remove all items with dataType "courseDate" 
                handleChange('initialDatesGenerator', '0', 'false');
                setShowDateBoxes(false);
 handleRemoveCourseDates();
  }}
>
            reset course dates
              </button>
              </div>
        </>
      )}
    </div>
  );
};

export default CourseDays;
