import { useEffect } from 'react';

function useAutoResizeTextareas() {
  useEffect(() => {
    const autoResize = (textarea) => {
      textarea.style.height = 'auto'; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Adjust to fit content
    };

    // Resize all text areas on page load
    const resizeAllTextareas = () => {
      const textareas = document.querySelectorAll('textarea');
      textareas.forEach(autoResize);
    };

    // Resize on initial load
    resizeAllTextareas();

    // Handle input events for dynamic resizing
    const handleInput = (event) => {
      const textarea = event.target;
      if (textarea.tagName === 'TEXTAREA') {
        autoResize(textarea);
      }
    };

    document.body.addEventListener('input', handleInput);

    return () => {
      document.body.removeEventListener('input', handleInput);
    };
  }, []);
}

export default useAutoResizeTextareas;

