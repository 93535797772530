import React, { useState, useEffect, useContext } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import { CourseIDContext } from '../context/CourseIDContext';
import CourseDesignQuestion from '../molecules/CourseDesignQuestion';
import ViewAndEditUsers from '../molecules/ViewAndEditUsers';
import TAStudentAssignments from '../molecules/TAStudentAssignments';
import { addUser, batchAddUsers } from '../utils/serverFetchAndAddUtilities';

const CourseDesignUsers = () => {
    const { courseDesignState, handleChange } = useContext(CourseDesignContext);
    const { courseID } = useContext(CourseIDContext);
    const [activeComponent, setActiveComponent] = useState('AddUsers');
    const [file, setFile] = useState(null);

    useEffect(() => {
        const lastVisited = courseDesignState.find(item => item?.dataType === 'courseDesignUsersLastVisited')?.dataText;
        if (lastVisited) {
            setActiveComponent(lastVisited);
        }
    }, [courseDesignState]);

    const handleMenuChange = (menuName) => {
        setActiveComponent(menuName);
        handleChange('courseDesignUsersLastVisited', '0', menuName);
    };

    // sets role to student by default
useEffect(() => {
    const existingRole = courseDesignState.find(item => item?.dataType === 'newUserRole')?.dataText;
    if (!existingRole) {
        handleChange('newUserRole', '0', 'student');
    }
}, [courseDesignState, handleChange]);


    const handleAddIndividualUser = async () => {
        const netID = courseDesignState.find(item => item?.dataType === 'newUserNetID')?.dataText || '';
        const fullName = courseDesignState.find(item => item?.dataType === 'newUserFullName')?.dataText || '';
        const role = courseDesignState.find(item => item?.dataType === 'newUserRole')?.dataText || '';

        try {
            await addUser(courseID, netID, netID, fullName, role); // Using utility function
            console.log(`User ${netID} added successfully.`);
            handleChange('newUserNetID', '0', '');
            handleChange('newUserFullName', '0', '');
            handleChange('newUserRole', '0', '');
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const handleBatchUpload = async () => {
        if (!file) {
            console.error('No file selected');
            return;
        }

        try {
            await batchAddUsers(courseID, file); // Using utility function
            console.log('Batch upload successful');
            setFile(null);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const renderAddUsers = () => (
        <div>
            <div>The instructor is the only person added to the list of users by default; everyone else has to be added. You can do that by adding individuals one by one, or by uploading an Excel file. <br></br><br></br>
            
            To add an individual user, enter their information here. Their initial password will be their netid. </div><br></br>
            <div>
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                    <input
                        type="text"
                        placeholder="netID"
                        value={courseDesignState.find(item => item?.dataType === 'newUserNetID')?.dataText || ''}
                        onChange={(e) => handleChange('newUserNetID', '0', e.target.value)}
                        className="custom-input-width"
                        style={{ width: '100px', marginRight: '10px', boxSizing: 'border-box' }}
                    />
                    <input
                        type="text"
                        placeholder="full name"
                        value={courseDesignState.find(item => item?.dataType === 'newUserFullName')?.dataText || ''}
                        onChange={(e) => handleChange('newUserFullName', '0', e.target.value)}
                        className="custom-input-width-large"
                        style={{ width: '200px', boxSizing: 'border-box' }}
                    />
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <CourseDesignQuestion
                        questionType="select"
                        dataType="newUserRole"
                        dataID="0"
                        question="Select user role:"
                        options={['student', 'TA', 'instructor', 'guest']}
                        value={courseDesignState.find(item => item?.dataType === 'newUserRole')?.dataText || ''}
                        onChange={(value) => handleChange('newUserRole', '0', value)}
                    />
                </div>
            </div>
            <button onClick={handleAddIndividualUser}>Add User</button>

            <br></br><br></br>

            <div>You can add many users at once by uploading an Excel file. One column in the file should be labeled "netID" and one should be labeled "full name."</div>
            <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload} />
            <button onClick={handleBatchUpload} disabled={!file}>Upload File</button>
        </div>
    );

  
    const renderComponent = () => {
        switch (activeComponent) {
            case 'AddUsers':
                return renderAddUsers();
            case 'ViewEditUsers':
                return <ViewAndEditUsers />;
            case 'TAStudentAssignments':
                return <TAStudentAssignments />;
            default:
                return renderAddUsers();
        }
    };

    return (
        <div className="subcomponent-wrapper">
            <div className="subcomponent-top-menu">
                <button
                    className={`menu-button ${activeComponent === 'AddUsers' ? 'active' : ''}`}
                    onClick={() => handleMenuChange('AddUsers')}
                >
                    Add Users
                </button>
                <button
                    className={`menu-button ${activeComponent === 'ViewEditUsers' ? 'active' : ''}`}
                    onClick={() => handleMenuChange('ViewEditUsers')}
                >
                    View & Edit Users
                </button>
                <button
                    className={`menu-button ${activeComponent === 'TAStudentAssignments' ? 'active' : ''}`}
                    onClick={() => handleMenuChange('TAStudentAssignments')}
                >
                    TA Student Assignments
                </button>
            </div>
            <div className="subcomponent-content">
                {renderComponent()}
            </div>
        </div>
    );
};

export default CourseDesignUsers;
