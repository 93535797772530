import React, { useState, useContext, useEffect } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import './CourseDesignFAQ.css';

const CourseDesignFAQ = () => {
  const { courseDesignState, handleChange } = useContext(CourseDesignContext);
  const [faqList, setFaqList] = useState([]);

  // Load the FAQ list from context state
  useEffect(() => {
    const faqEntry = courseDesignState.find(item => item.dataType === 'faq' && item.dataID === 'courseFAQ');
    if (faqEntry) {
      setFaqList(JSON.parse(faqEntry.dataText || '[]'));
    }
  }, [courseDesignState]);

  const saveFAQList = (newList) => {
    const dataText = JSON.stringify(newList);
    handleChange('faq', 'courseFAQ', dataText);
  };

  const handleUpdate = (index, key, value) => {
    const updatedList = [...faqList];
    updatedList[index][key] = value;
    setFaqList(updatedList);
    saveFAQList(updatedList);
  };

  const handleDelete = (index) => {
    const updatedList = faqList.filter((_, i) => i !== index);
    setFaqList(updatedList);
    saveFAQList(updatedList);
  };

  const handleMove = (index, direction) => {
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= faqList.length) return; // Prevent moving out of bounds
    const updatedList = [...faqList];
    const [movedItem] = updatedList.splice(index, 1);
    updatedList.splice(newIndex, 0, movedItem);
    setFaqList(updatedList);
    saveFAQList(updatedList);
  };

  const handleAddFAQ = () => {
    setFaqList([...faqList, { question: '', answer: '' }]);
  };
    
const adjustTextareaHeight = (textarea) => {
  textarea.style.height = 'auto'; // Reset the height to calculate the new size
  textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to fit content
};


  return (
    <div className="faq-design-container">
      <div>You can use the fields below to add FAQs to your course website. These will be linked from the left sidebar when students are answering the questions for the course. You can delete and reorder them using the buttons at the right. </div>
      <div className="faq-design-list">
        {faqList.map((faq, index) => (
          <div key={index} className="faq-design-item">
            Q: <textarea
  value={faq.question}
  onChange={(e) => handleUpdate(index, 'question', e.target.value)}
  placeholder="Enter question here"
  className="faq-design-question"
  onInput={(e) => adjustTextareaHeight(e.target)}
  ref={(el) => {
    if (el) {
      adjustTextareaHeight(el); // Adjust height after rendering
    }
  }}
/>

A: <textarea
  value={faq.answer}
  onChange={(e) => handleUpdate(index, 'answer', e.target.value)}
  placeholder="Enter answer here"
  className="faq-design-answer"
  onInput={(e) => adjustTextareaHeight(e.target)}
  ref={(el) => {
    if (el) {
      adjustTextareaHeight(el); // Adjust height after rendering
    }
  }}
/>

            <div className="faq-design-controls">
              <button onClick={() => handleMove(index, -1)} aria-label="Move up">
                <FontAwesomeIcon icon={faArrowUp} />
              </button>
              <button onClick={() => handleMove(index, 1)} aria-label="Move down">
                <FontAwesomeIcon icon={faArrowDown} />
              </button>
              <button onClick={() => handleDelete(index)} aria-label="Delete">
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))}
      </div>
      <button onClick={handleAddFAQ} className="add-faq-design-button">Add FAQ</button>
    </div>
  );
};

export default CourseDesignFAQ;
