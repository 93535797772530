import React from 'react';

const QuestionCreationButton = ({ onCreate }) => {
    return (
        <button
            onClick={onCreate}
            style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                marginTop: '12px',
                backgroundColor: 'var(--color2)',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '16px',
            }}
        >
            <span style={{ marginRight: '8px', fontSize: '20px' }}>+</span>
            add question
        </button>
    );
};

export default QuestionCreationButton;
