// CourseDesignRubric.js
import React, { useState, useEffect, useContext } from 'react';
import MarkdownIt from 'markdown-it';
import { CourseDesignContext } from '../context/CourseDesignContext';

const md = new MarkdownIt();

const CourseDesignRubric = () => {
    const { handleChange, courseDesignState } = useContext(CourseDesignContext);
    const [markdown, setMarkdown] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);

// Load existing rubric display options from context
useEffect(() => {
    const existingOptions = courseDesignState.find(item => item.dataType === "rubricWhereToShow" && item.dataID === "1");
    if (existingOptions) {
        setSelectedOptions(existingOptions.dataText.split(',').map(Number));
    }
}, [courseDesignState]);

// Toggle selection
const toggleOption = (id) => {
    let updatedOptions;
    if (selectedOptions.includes(id)) {
        updatedOptions = selectedOptions.filter(option => option !== id);
    } else {
        updatedOptions = [...selectedOptions, id];
    }
    setSelectedOptions(updatedOptions);
    handleChange("rubricWhereToShow", "1", updatedOptions.join(',')); // Save selections to state/database
};

    // Load existing rubric from context
    useEffect(() => {
        const existingRubric = courseDesignState.find(item => item.dataType === "rubric" && item.dataID === "1");
        if (existingRubric) {
            setMarkdown(existingRubric.dataText);
        }
    }, [courseDesignState]);

    // Handle text changes
    const handleMarkdownChange = (event) => {
        const content = event.target.value;
        setMarkdown(content); // Update local state
        handleChange("rubric", "1", content); // Save Markdown to state/database
    };

    return (
        <div className="markdown-editor-container">
            <div>Use the field below to write your rubric. You can style the text using Markdown. A preview is shown on the right.</div>
    
    
<div className="rubric-design-display-options">
    <div>Where do you want the rubric to be displayed? (You can check as many as you like, and can always change this later.)</div>
    <div className="rubric-design-options-container">
        {[
            { id: 1, text: 'In a link in the left sidebar where students view and answer questions' },
            { id: 2, text: 'In the FAQ page (if using)' },
            { id: 3, text: 'In the "grading details" box visible when students view comments and grades' }
        ].map(option => (
            <div
                key={option.id}
                className={`rubric-design-option-div ${selectedOptions.includes(option.id) ? 'rubric-design-selected' : ''}`}
                onClick={() => toggleOption(option.id)}
            >
                {option.text}
            </div>
        ))}
    </div>
</div>


            <br></br>



            <div className="editor-preview-wrapper">
                <textarea
                    value={markdown}
                    onChange={handleMarkdownChange}
                    placeholder="Enter rubric in Markdown..."
                    className="markdown-textarea"
                />
                <div
                    className="markdown-preview"
                    dangerouslySetInnerHTML={{ __html: md.render(markdown) }}
                />
            </div>
        </div>
    );
};

export default CourseDesignRubric;
