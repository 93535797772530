import { parseISO, format } from 'date-fns';
//import { v4 as uuidv4 } from 'uuid';


export const generateClassDays = (startDate, endDate, meetingDays) => {
  const days = [];
  let currentDate = parseISO(startDate); // Parse the start date without time zone issues
  const endParsedDate = parseISO(endDate); // Parse the end date

  // Map meetingDays to corresponding numbers
  const dayMap = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  };

  const meetingDayNumbers = meetingDays.map(day => dayMap[day]);

  while (currentDate <= endParsedDate) {
    const currentDayNumber = currentDate.getDay(); // Get the day of the week as a number
    if (meetingDayNumbers.includes(currentDayNumber)) {
      days.push(format(currentDate, 'yyyy-MM-dd')); // Push date in YYYY-MM-DD format
    }
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  return days;
};

// utility function to add a topic item to state or database




export function generateUniqueId(length = 5) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

// THE NEXT TWO ARE USED IN THE QUESTION DESIGN INTERFACE

export const getFilteredAndSortedQuestions = (questions, topicDataIndex) => {
    return questions
        .filter(question => question.questionCourseDate === topicDataIndex)
        .sort((a, b) => parseInt(a.dataIndex) - parseInt(b.dataIndex));
};

export const initializeSelection = (
    courseDesignState, 
    sections, 
    setSelectedSectionIndex, 
    setSelectedTopicIndex, 
    setSelectedTopicDataIndex, 
    handleChange
) => {
    const lastVisitedTopicID = courseDesignState.find(item => item.dataType === 'questionDesignSubMenuLastVisited')?.dataText;

    if (lastVisitedTopicID) {
        const lastVisitedTopic = courseDesignState.find(item => item.dataType === 'topic' && item.dataID === lastVisitedTopicID);
        if (lastVisitedTopic) {
            setSelectedTopicIndex(parseInt(lastVisitedTopic.dataIndex, 10));
            setSelectedTopicDataIndex(lastVisitedTopicID);
        }
    } else if (sections.length > 0) {
        const firstSectionIndex = 0;
        setSelectedSectionIndex(firstSectionIndex);
        const firstTopicInSection = sections.length > 0 ? courseDesignState
            .filter(item =>
                item.dataType === 'topic' &&
                parseInt(item.dataIndex) > parseInt(sections[firstSectionIndex].dataIndex)
            )
            .sort((a, b) => parseInt(a.dataIndex) - parseInt(b.dataIndex))[0] : null;
        if (firstTopicInSection) {
            setSelectedTopicIndex(parseInt(firstTopicInSection.dataIndex, 10));
            setSelectedTopicDataIndex(firstTopicInSection.dataID);
            handleChange('questionDesignSubMenuLastVisited', 'user', firstTopicInSection.dataID);
        }
    }
};

