// atoms/QuestionDesignSectionMenu.js

import React from 'react';

const QuestionDesignSectionMenu = ({ sections, selectedSectionIndex, handleSectionClick }) => (
    <div className="subcomponent-top-menu">
        {sections.map((section, index) => (
            <button
                key={section.dataID}
                onClick={() => handleSectionClick(index)}
                className={selectedSectionIndex === index ? 'active' : ''}
            >
                {section.dataText}
            </button>
        ))}
    </div>
);

export default QuestionDesignSectionMenu;
