import React, { useState, useEffect, useContext } from 'react';  // Add useContext and useEffect
import QuestionDayPicker from '../molecules/QuestionDayPicker';
import CourseDays from '../molecules/CourseDays';
//import CourseDesignSectionPicker from '../molecules/CourseDesignSectionPicker';
import { CourseDesignContext } from '../context/CourseDesignContext';  // Import the context
import CourseProgress from '../atoms/CourseProgress';
import CourseDesignScheduleMaker from '../molecules/CourseDesignScheduleMaker';
import CourseDesignSyllabus from '../molecules/CourseDesignSyllabus';



const CourseDesignScheduleTopics = () => {
  const { courseDesignState, handleChange } = useContext(CourseDesignContext);  // Use the context
  const [activeComponent, setActiveComponent] = useState('CourseDays');
    const hasSyllabus = courseDesignState.find(item => item?.dataType === 'hasSyllabus')?.dataText === 'Yes';


  // Load the last visited section on mount
  useEffect(() => {
    const lastVisited = courseDesignState.find(item => item?.dataType === 'courseDesignScheduleLastVisited')?.dataText;
    if (lastVisited) {
      setActiveComponent(lastVisited);
    }
  }, [courseDesignState]);

  // Save the active component to state and database
  const handleMenuChange = (menuName) => {
    setActiveComponent(menuName);
    handleChange('courseDesignScheduleLastVisited', '0', menuName);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'CourseDays':
        return <CourseDays />;
      case 'ScheduleMaker':
        return <CourseDesignScheduleMaker />;
      case 'QuestionDayPicker':
        return <QuestionDayPicker />;
         case 'syllabus':
      return <CourseDesignSyllabus />;
       case 'CourseProgress':  // Added this case
            return <CourseProgress />;
      default:
        return <CourseDays />;
    }
  };

  return (
    <div className="subcomponent-wrapper">
      <div className="subcomponent-top-menu">
        <button
          className={`menu-button ${activeComponent === 'CourseDays' ? 'active' : ''}`}
          onClick={() => handleMenuChange('CourseDays')}  // Use handleMenuChange instead of setActiveComponent
        >
          Dates
        </button>
       
         <button
          className={`menu-button ${activeComponent === 'ScheduleMaker' ? 'active' : ''}`}
          onClick={() => handleMenuChange('ScheduleMaker')}
        >
          Schedule Maker
        </button>
        <button
          className={`menu-button ${activeComponent === 'QuestionDayPicker' ? 'active' : ''}`}
          onClick={() => handleMenuChange('QuestionDayPicker')}
        >
          Days with Questions
        </button>

 {hasSyllabus && (
          <button
            className={`menu-button ${activeComponent === 'syllabus' ? 'active' : ''}`}
            onClick={() => handleMenuChange('syllabus')}
          >
            Syllabus Links
          </button>
        )}

        <button
    className={`menu-button ${activeComponent === 'CourseProgress' ? 'active' : ''}`}
    onClick={() => handleMenuChange('CourseProgress')}
>
    Course Progress
</button>
      </div>
      <div className="subcomponent-content">
        {renderComponent()}
      </div>
    </div>
  );
};

export default CourseDesignScheduleTopics;
