import React, { useState } from 'react';
import Syllabus from '../pages/Syllabus'; // Assuming Syllabus.js is in the same directory
import SyllabusLinkAdder from '../atoms/SyllabusLinkAdder';

const CourseDesignSyllabus = () => {
  const [viewMode, setViewMode] = useState('designer'); // Default to Design View

  const toggleViewMode = () => {
    setViewMode(prevMode => (prevMode === 'designer' ? 'student' : 'designer'));
  };

  return (
    <div className="course-design-syllabus">
      {/* View Toggle */}
      <div className="view-toggle">
        <label className="switch">
          <input
            type="checkbox"
            checked={viewMode === 'student'}
            onChange={toggleViewMode}
          />
          <span className="slider round"></span>
        </label>
        <span className="toggle-label">
          {viewMode === 'designer' ? 'Design View' : 'Student View'}
        </span>
      </div>

      {/* Conditional Rendering */}
      {viewMode === 'student' ? (
        <Syllabus />
      ) : (
        <SyllabusLinkAdder/>
      )}
    </div>
  );
};

export default CourseDesignSyllabus;
