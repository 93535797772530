import React, { useContext } from 'react';
import { StudentGradesContext } from '../context/StudentGradesContext'; // Importing the context


function LongAnswerGradeDisplay({ questionID, evaluationID, username }) {
  const { studentGradesState } = useContext(StudentGradesContext);

  // Get the grade entries for the specific user
  const userGrades = studentGradesState[username] || [];

  // Find the grade entry matching the criteria
  const gradeEntry = userGrades.find(
    (g) =>
      g.questionID === questionID &&
      g.evaluationDataID === evaluationID
  );

  const grade = gradeEntry ? gradeEntry.individualGradingData : 'No grade available';

  return (
    <div
      style={{
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        marginTop: '10px',
        padding: '10px',
      }}
    >
      Question grade: {grade}
    </div>
  );
}

export default LongAnswerGradeDisplay;


