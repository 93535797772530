// utils/scheduleMakerUtilities.js

// Function to generate a random 5-character alphanumeric ID
export const generateRandomID = () => {
  return Math.random().toString(36).substring(2, 7);
};

// Function to validate and correct data on component load
export const validateDataOnLoad = (courseDesignState) => {
  //console.log('Starting validation process...');

  let updatedState = courseDesignState.map(item => ({ ...item }));

//console.log('Course dates (deep copy):', courseDatesCopy);
  //console.log('Course dates mapping from deep copy:', courseDatesCopy.filter(item => item.dataType === 'courseDate').map(item => ({ dataText: item.dataText, dataIndex: item.dataIndex })));


  // //console.log the course dates
  //console.log('Course dates:', updatedState.filter(item => item.dataType === 'courseDate'));
  //console.log('Course dates:', updatedState.filter(item => item.dataType === 'courseDate').map(item => ({ dataText: item.dataText, originaldataindex: item.dataIndex })));
  //console.log('Course dates for the third time:', updatedState.filter(item => item.dataType === 'courseDate'));
  
//console.log('Filtered course dates:', courseDates);

  //console.log('Mapped course dates:', mappedCourseDates);
  
  /*console.log(
  'Course dates with the recommended mapping:',
  updatedState
    .filter(item => item.dataType === 'courseDate')
    .map(item => ({ dataText: item.dataText, dataIndex: item.dataIndex }))
);
*/


  //console.log('Topics:', updatedState.filter(item => item.dataType === 'topic').map(item => ({ dataText: item.dataText, dataIndex: item.dataIndex })));
  //console.log('Sections:', updatedState.filter(item => item.dataType === 'sectionName').map(item => ({ dataText: item.dataText, dataIndex: item.dataIndex })));

  // Step 0: if no sectionName objects exist in the state, then add one with a dataIndex of 1
  if (!updatedState.some(item => item.dataType === 'sectionName')) {
    //console.log('No sectionName objects found, adding one with a dataIndex of 1');
    updatedState.push({
      dataType: 'sectionName',
      dataID: generateRandomID(),
      dataText: 'Section 1',
      dataIndex: '1',
    });
  }
 
  // Step 1: Reindex Topics and Sections
  if (checkReindexTopicsAndSections(updatedState)) {
    updatedState = fixReindexTopicsAndSections(updatedState);
  }

  // Step 2: Equal Course Dates and Topics
  if (checkEqualCourseDatesAndTopics(updatedState)) {
    updatedState = fixEqualCourseDatesAndTopics(updatedState);
  }

  // Step 3: Remove Duplicate Course Date Data Indices
  if (checkRemoveDuplicateCourseDateDataIndices(updatedState)) {
    updatedState = fixRemoveDuplicateCourseDateDataIndices(updatedState);
  }

  // Step 4: Reorder Course Dates
  if (checkReorderCourseDates(updatedState)) {
    updatedState = fixReorderCourseDates(updatedState);
  }


  // Step 5: Align Course Dates with Topics
  if (checkAlignCourseDatesWithTopics(updatedState)) {
    updatedState = fixAlignCourseDatesWithTopics(updatedState);
  }
   

  //console.log('Validation process completed');

  return updatedState; // Return the updated state
};

// Step 1: Condition checker for reindexTopicsAndSections
export const checkReindexTopicsAndSections = (stateCopy) => {
  const items = stateCopy.filter(item => ['topic', 'sectionName'].includes(item.dataType));
  // order the items by dataIndex
  items.sort((a, b) => parseInt(a.dataIndex) - parseInt(b.dataIndex));
  // create an aaay of the dataIndexes of the items, putting them in order
  const dataIndexes = items.map(item => item.dataIndex);
  //console.log('dataIndexes:', dataIndexes);
  // check to see if there are any duplicate items in the list, and if so //console.log the duplicate items. check to see if the lowest one is not 1, and if so //console.log that there is no object with index of 1. check to see whether there are any gaps in the list of dataIndexes, and if so //console.log that there is a gap in the list of dataIndexes with the missing numbers in the //console.log. if none of these conditions are met //console.log that all is in oder. hasDuplicates is not defined
  if (new Set(dataIndexes).size !== dataIndexes.length) {
    //console.log('There are duplicate dataIndex values:', items);
    return true;
  }
  else if (parseInt(items[0].dataIndex) !== 1) {
    //console.log('There is no object with an index of 1');
    return true;
  }
        // see if there is any item in the list that is not 1 higher than the previous item and if so //console.log that there is a gap in the list of dataIndexes with the missing numbers in the //console.log
  else if (dataIndexes.some((dataIndex, idx) => parseInt(dataIndex) !== idx + 1)) {
    const missingIndices = [];
    for (let i = 1; i < dataIndexes.length; i++) {
      if (parseInt(dataIndexes[i]) !== parseInt(dataIndexes[i - 1]) + 1) {
        missingIndices.push(parseInt(dataIndexes[i - 1]) + 1);
      }
    }
    //console.log('There is a gap in the list of dataIndexes:', missingIndices);
    return true;
    
  } else {
    //console.log('All is in order with the dataIndexes of topics and sections');
    return false;
  }

};

// Step 1: Fixer for reindexTopicsAndSections
export const fixReindexTopicsAndSections = (oldstate) => {
    const stateCopy = oldstate.map(item => ({ ...item }));

  //console.log('Fixing reindexing of topics and sections...');

  const sectionsAndTopics = stateCopy.filter(item => ['topic', 'sectionName'].includes(item.dataType));
  // order the items by dataIndex property of the items
  const orderedSectionsAndTopics = sectionsAndTopics.sort((a, b) => parseInt(a.dataIndex) - parseInt(b.dataIndex));
  // each member of the array should have a dataIndex property that is a string of a number. It should be its index in the array plus 1. If it is not, then it should be changed to that.
  orderedSectionsAndTopics.forEach((item, idx) => {
    const newIndex = (idx + 1).toString();
    if (item.dataIndex !== newIndex) {
      //console.log(`Updating dataIndex for item with ID ${item.dataID} from ${item.dataIndex} to ${newIndex}`);
      item.dataIndex = newIndex;
    }
  });
  // return the stateCopy
  return stateCopy;
};




    


// Step 2
export const checkEqualCourseDatesAndTopics = (stateCopy) => {
  const topics = stateCopy.filter(item => item.dataType === 'topic');
  const courseDates = stateCopy.filter(item => item.dataType === 'courseDate');
  // //console.log the numbers of topics and courseDates. if they are not equal, return true. otherwise return false
  //console.log('Number of topics:', topics.length);
  //console.log('Number of course dates:', courseDates.length);
  return topics.length !== courseDates.length;
};

export const fixEqualCourseDatesAndTopics = (oldstate) => {
      const stateCopy = oldstate.map(item => ({ ...item }));

  //console.log('Fixing unequal number of topics and course dates...');

  const topics = stateCopy.filter(item => item.dataType === 'topic');
  const courseDates = stateCopy.filter(item => item.dataType === 'courseDate');

  if (topics.length > courseDates.length) {
    // Add more courseDate items
    const difference = topics.length - courseDates.length;
    const startingIndex = Math.max(...courseDates.map(item => parseInt(item.dataIndex)), 0) + 1;
    //console.log(`Adding ${difference} courseDate items starting at index ${startingIndex}`);
    for (let i = 0; i < difference; i++) {
      const newCourseDateItem = {
        dataType: 'courseDate',
        dataID: generateRandomID(),
        dataText: '??',
        dataIndex: (startingIndex + i).toString(),
      };
      //console.log('Creating new courseDate item:', newCourseDateItem);
      stateCopy.push(newCourseDateItem);
    }
  } else if (courseDates.length > topics.length) {
    // Add more topic items
    const difference = courseDates.length - topics.length;
    const startingIndex = Math.max(...topics.map(item => parseInt(item.dataIndex)), 0) + 1;
    //console.log(`Adding ${difference} topic items starting at index ${startingIndex}`);
    for (let i = 0; i < difference; i++) {
      const newTopicItem = {
        dataType: 'topic',
        dataID: generateRandomID(),
        dataText: 'day topic',
        dataIndex: (startingIndex + i).toString(),
      };
      //console.log('Creating new topic item:', newTopicItem);
      stateCopy.push(newTopicItem);
    }
  }

  return stateCopy;
};

// Step 3
export const checkRemoveDuplicateCourseDateDataIndices = (stateCopy) => {
  const courseDates = stateCopy.filter(item => item.dataType === 'courseDate');
  // create an array of all the dataIndexes of the courseDates
  const dataIndexes = courseDates.map(item => item.dataIndex);
  // check whether there are any duplicate dataIndexes in the array, and if so //console.log the duplicate dataIndexes and return true. otherwise //console.log that there are no duplicate dataIndexes and return false
  if (new Set(dataIndexes).size !== dataIndexes.length) {
    //console.log('There are duplicate dataIndex values:', courseDates);
    return true;
  } else {
    //console.log('There are no duplicate dataIndex values');
    return false;
  }
};

export const fixRemoveDuplicateCourseDateDataIndices = (oldstate) => {
      const stateCopy = oldstate.map(item => ({ ...item }));

  //console.log('Fixing duplicate course date dataIndex values...');

  const courseDates = stateCopy.filter(item => item.dataType === 'courseDate');
  const dataIndexCounts = {};

  courseDates.forEach(item => {
    const dataIndex = item.dataIndex;
    if (!dataIndexCounts[dataIndex]) {
      dataIndexCounts[dataIndex] = [];
    }
    dataIndexCounts[dataIndex].push(item);
  });

  // Fix duplicates by assigning unique dataIndex values
  Object.keys(dataIndexCounts).forEach(dataIndex => {
    const items = dataIndexCounts[dataIndex];
    if (items.length > 1) {
      items.forEach((item, idx) => {
        if (idx > 0) {
          const newDataIndex = (parseInt(dataIndex) + idx).toString();
          //console.log(`Updating dataIndex for item with ID ${item.dataID} from ${dataIndex} to ${newDataIndex}`);
          item.dataIndex = newDataIndex;
        }
      });
    }
  });

  return stateCopy;
};

// Step 4
export const checkReorderCourseDates = (stateCopy) => {
  // Filter courseDate items with valid dates in questionLogic
  const setDates = stateCopy.filter(
    item => item.dataType === 'courseDate' && /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(item.questionLogic)
  );
  //console.log('Set dates:', setDates);
  const unsetDates = stateCopy.filter(
    item => item.dataType === 'courseDate' && !/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(item.questionLogic)
  );
  // Sort set dates dates by questionLogic
  setDates.sort((a, b) => new Date(a.questionLogic) - new Date(b.questionLogic));
  // sort the unsetDates by dataIndex
  unsetDates.sort((a, b) => parseInt(a.dataIndex) - parseInt(b.dataIndex)); 
  // combine the two sorted lists, with the setDates first, but preserving order within each group
  const properlyOrderedCourseDates = setDates.concat(unsetDates);
  // now create an array of the dataIndexes of the properlyOrderedCourseDates, with index in the array corresponding to position in the order. Check to see if any dataIndex comes before a lower dataIndex. If it does, then put the dataIndexes in order (without changing any of them) and assign those dataIndexes to the sproperlyOrderedCourseDates in that order

  const dataIndexes = properlyOrderedCourseDates.map(item => item.dataIndex);
  const sortedDataIndexes = dataIndexes.slice().sort((a, b) => parseInt(a) - parseInt(b));
  // check whether each member of dataIndexes is the same as the corresponding member of sortedDataIndexes. If it is not, then //console.log the two arrays and return true. Otherwise return false
  if (dataIndexes.some((dataIndex, idx) => dataIndex !== sortedDataIndexes[idx])) {
    //console.log('Course date dataIndexes are out of order with the dates:', dataIndexes, sortedDataIndexes);
    return true;
  }
  else {
    //console.log('Course date dataIndexes are in order with the dates');
    return false;
  }
};

export const fixReorderCourseDates = (oldstate) => {
      const stateCopy = oldstate.map(item => ({ ...item }));

  //console.log('Fixing out-of-order course dates...');
  const setDates = stateCopy.filter(
    item => item.dataType === 'courseDate' && /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(item.questionLogic)
  );
  const unsetDates = stateCopy.filter(
    item => item.dataType === 'courseDate' && !/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(item.questionLogic)
  );
  // Sort set dates dates by questionLogic
  setDates.sort((a, b) => new Date(a.questionLogic) - new Date(b.questionLogic));

  // sort the unsetDates by dataIndex
  unsetDates.sort((a, b) => parseInt(a.dataIndex) - parseInt(b.dataIndex)); 

  // combine the two sorted lists, with the setDates first, but preserving order within each group
  const properlyOrderedCourseDates = setDates.concat(unsetDates);

  // now create an array of the dataIndexes of the properlyOrderedCourseDates, with index in the array corresponding to position in the order. Check to see if any dataIndex comes before a lower dataIndex. If it does, then put the dataIndexes in order (without changing any of them) and assign those dataIndexes to the sproperlyOrderedCourseDates in that order

  const dataIndexes = properlyOrderedCourseDates.map(item => item.dataIndex);
  const sortedDataIndexes = dataIndexes.slice().sort((a, b) => parseInt(a) - parseInt(b));

  //console.log('here is a list of the dataText of the course dates as ordered by their questionLogic date property:', properlyOrderedCourseDates.map(item => item.dataText));
  //console.log('here is a list of the dataIndex of the course dates as ordered by their questionLogic date property:', dataIndexes);
  //console.log('here are the coursedate dataindices in their proper order before modification of coursedate items:', sortedDataIndexes);

  // go through the properlyOrderedCourseDates and see if each members dataIndex is the same as the corresponding member of the sortedDataIndexes. If it is not, then change the dataIndex of that member to the corresponding member of the sortedDataIndexes and ocnsole log the change made
  properlyOrderedCourseDates.forEach((item, idx) => {
    const newIndex = sortedDataIndexes[idx];
    if (item.dataIndex !== newIndex) {
      //console.log(`Updating dataIndex for item with dataText ${item.dataText} from ${item.dataIndex} to ${newIndex}`);
      item.dataIndex = newIndex;

      // Find the corresponding item in stateCopy and update its dataIndex
      const originalItem = stateCopy.find(stateItem => stateItem.dataID === item.dataID);
      if (originalItem) {
        originalItem.dataIndex = newIndex;
      }
    }
  }
  );
  // return the stateCopy
  return stateCopy;
};

// Step 5
export const checkAlignCourseDatesWithTopics = (stateCopy) => {
  const topics = stateCopy.filter(item => item.dataType === 'topic');
  const courseDates = stateCopy.filter(item => item.dataType === 'courseDate');

  // create an array of the dataIndexes of the topics and the courseDates, with both arrays sorted via parseint from lowest to highest
  const topicDataIndexes = topics.map(item => parseInt(item.dataIndex)).sort((a, b) => a - b);
  const courseDateDataIndexes = courseDates.map(item => parseInt(item.dataIndex)).sort((a, b) => a - b);
  // go through the arrays iems by item starting with the first to nake syre that they have the same members
  for (let i = 0; i < Math.min(topicDataIndexes.length, courseDateDataIndexes.length); i++) {
    if (topicDataIndexes[i] !== courseDateDataIndexes[i]) {
      //console.log(`Misalignment between topic with dataIndex ${topicDataIndexes[i]} and courseDate with dataIndex ${courseDateDataIndexes[i]}`);
      return true;
    }
  }
  //console.log('All topics and course dates are aligned');
  return false;
}

export const fixAlignCourseDatesWithTopics = (oldstate) => {
      const stateCopy = oldstate.map(item => ({ ...item }));

  //console.log('Fixing alignment between topics and course dates...');

  const topics = stateCopy
    .filter(item => item.dataType === 'topic')
    .sort((a, b) => parseInt(a.dataIndex) - parseInt(b.dataIndex));

  const courseDates = stateCopy
    .filter(item => item.dataType === 'courseDate')
    .sort((a, b) => parseInt(a.dataIndex) - parseInt(b.dataIndex));

  // Sync dataIndex between topics and courseDates
  const length = Math.min(topics.length, courseDates.length);
  for (let i = 0; i < length; i++) {
    if (parseInt(courseDates[i].dataIndex) !== parseInt(topics[i].dataIndex)) {
      
      const newDataIndex = topics[i].dataIndex;
      courseDates[i].dataIndex = newDataIndex;
    }
  }

  return stateCopy;
};
