import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';

function MyPhilosophyHeader({ username }) {
  const { getFullNameByUsername } = useContext(AuthContext);
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    const fetchFullName = async () => {
      const name = await getFullNameByUsername(username);
      setFullName(name);
    };
    fetchFullName();
  }, [username, getFullNameByUsername]);

  const formatHeaderText = (name) => {
    if (!name) return "Philosophy";
    const endsWithS = name.endsWith('s') || name.endsWith('S');
    return `${name}${endsWithS ? "'" : "'s"} Philosophy`;
  };

  return (
    <h1 className='myPhiHeader'>{formatHeaderText(fullName)}</h1>
  );
}

export default MyPhilosophyHeader;
