import React, { useContext, useState, useEffect, useMemo } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import CourseDesignQuestion from '../molecules/CourseDesignQuestion';
import  getCurrentCourseDay  from '../utils/getCurrentCourseDay';

const CourseProgress = () => {
  const { courseDesignState, handleChange } = useContext(CourseDesignContext);
  const [useAutomaticProgress, setUseAutomaticProgress] = useState(null);
  const [currentCourseDate, setCurrentCourseDate] = useState('');
const [timeToShowNewContent, setTimeToShowNewContent] = useState('');
const [timePeriod, setTimePeriod] = useState('AM'); // Defaults to AM



  // Retrieve the list of course dates from the state and order them by dataIndex after calling parseInt on each dataIndex
  const courseDates = courseDesignState.filter(item => item.dataType === 'courseDate').sort((a, b) => parseInt(a.dataIndex) - parseInt(b.dataIndex));




  // Load initial values from state
  useEffect(() => {
    // Find the existing value for 'usesAutomaticProgress' in the state
    const automaticProgress = courseDesignState.find(item => item.dataType === 'usesAutomaticProgress');
    if (automaticProgress) {
      setUseAutomaticProgress(automaticProgress.dataText);
    }

    // Find the existing value for 'currentCourseDate' in the state
    const currentCourseDateItem = courseDesignState.find(item => item.dataType === 'currentCourseDate');
    if (currentCourseDateItem) {
      setCurrentCourseDate(currentCourseDateItem.dataText);
    }
  }, [courseDesignState]);

useEffect(() => {
  const timeToShowNewContentItem = courseDesignState.find(item => item.dataType === 'timeToShowNewContent');
  if (timeToShowNewContentItem) {
    setTimeToShowNewContent(timeToShowNewContentItem.dataText);
    setTimePeriod(timeToShowNewContentItem.questionOptions || 'AM');
  }
}, [courseDesignState]);

  


  // Handle the change for the automatic progress question
  const handleAutomaticProgressChange = (value) => {
    setUseAutomaticProgress(value);
    handleChange('usesAutomaticProgress', '0', value);
  };

const handleTimeChange = (input) => {
  // Split into hours and minutes
  let [hours, minutes] = input.split(':').map((val) => val.replace(/\D/g, '')); // Remove non-numeric characters

  // Ensure valid hours (01-12)
  if (hours) {
    hours = String(Math.max(1, Math.min(12, parseInt(hours)))).padStart(2, '0');
  }

  // Ensure valid minutes (00-59)
  if (minutes) {
    minutes = String(Math.max(0, Math.min(59, parseInt(minutes)))).padStart(2, '0');
  }

  const formattedTime = `${hours || '01'}:${minutes || '00'}`;
  setTimeToShowNewContent(formattedTime);
  handleChange('timeToShowNewContent', '0', formattedTime, { questionOptions: timePeriod });
};


const handleTimePeriodChange = (period) => {
  setTimePeriod(period);
  handleChange('timeToShowNewContent', '0', timeToShowNewContent, { questionOptions: period });
};




  // Handle the change for the current course date selection
  const handleCurrentCourseDateChange = (selectedDateText) => {
    setCurrentCourseDate(selectedDateText);
    const selectedDate = courseDates.find(date => date.dataText === selectedDateText);
    handleChange('currentCourseDate', '0', selectedDateText, { dataIndex: selectedDate?.dataIndex });
  };

  const currentCourseDay = useMemo(() => getCurrentCourseDay(courseDesignState), [courseDesignState]);
console.log('Determined current course day:', currentCourseDay);


  return (
    <div>

      <div>The questions which students see depend by default on (i) where chronologically in the course you are and (ii) their answers to prior questions. Part (ii) is managed by the logic you assign to individual questions. There are two ways to manage part (i). First, you can let the course progress be fixed automatically, so that the current day of the course is fixed by the actual date and time of day and your syllabus. (Keep in mind that you can change the dates assigned to various topics over the course of the semester.) Second, you can manually pick the current course date.</div>
      
      <br></br>

      {/* Question for automatic progress */}
      <CourseDesignQuestion
        questionType="select"
        dataType="usesAutomaticProgress"
        question="Do you want the course progress to be automatic?"
        options={['Yes', 'No']}
        value={useAutomaticProgress}
        onChange={handleAutomaticProgressChange}
      />

{useAutomaticProgress === 'Yes' && (
        <div>
          <br></br>
    <label>
      At what time of the day do you want the new content for that day to appear?
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem' }}>
        {/* Text Input for Time */}
       
              <input
  type="text"
  placeholder="HH:MM"
  value={timeToShowNewContent}
  onChange={(e) => handleTimeChange(e.target.value)}
  style={{
    padding: '0.5rem',
    fontSize: '1.5rem', // Larger font size
    border: '1px solid var(--color3)',
    borderRadius: '4px',
    boxShadow: 'var(--standard-box-shadow)', // Standard box shadow
    width: '120px', // Wider to accommodate larger font size
    textAlign: 'center', // Center-align text for better readability
  }}
/>


        {/* Dropdown for AM/PM */}
        <CourseDesignQuestion
          questionType="select"
          dataType="timeToShowNewContentPeriod"
          options={['AM', 'PM']}
          value={timePeriod}
          onChange={(value) => handleTimePeriodChange(value)}
        />
      </div>
    </label>
  </div>
)}



      {/* Show the course date selection only if 'No' is selected for automatic progress */}
      {useAutomaticProgress === 'No' && (
        <CourseDesignQuestion
          questionType="select"
          dataType="currentCourseDate"
          question="Select the current day of the course:"
          options={courseDates.map(date => date.dataText)}
          value={currentCourseDate}
          onChange={handleCurrentCourseDateChange}
        />
      )}
    </div>
  );
};

export default CourseProgress;
