import React, { useState, useEffect, useContext } from 'react';
import { CourseIDContext } from '../context/CourseIDContext';
import { fetchAllUsers, assignStudentToTA, unassignStudentFromTA } from '../utils/serverFetchAndAddUtilities';

const TAStudentAssignments = () => {
  const { courseID } = useContext(CourseIDContext);
  const [unassignedStudents, setUnassignedStudents] = useState([]);
  const [tas, setTAs] = useState([]);  // Only TAs
  const [assignments, setAssignments] = useState({});
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedForUnassignment, setSelectedForUnassignment] = useState({});


  

  const fetchData = async () => {
    try {
      setLoading(true);
      const users = await fetchAllUsers(courseID);

      // Filter TAs only
      const tasOnly = users.filter(user => user.status.toLowerCase() === 'ta');
      console.log('TAs:', tasOnly);

      // Collect usernames of all TAs for easy lookup
      const taUsernames = new Set(tasOnly.map(ta => ta.username));

      // Filter students without a TA assigned
      
   const studentsWithoutTA = users
    .filter(user => user.status.toLowerCase() === 'student' && (!user.TA || !taUsernames.has(user.TA)))
    .sort((a, b) => a.fullname.split(" ").slice(-1)[0].localeCompare(b.fullname.split(" ").slice(-1)[0])); // Sort by last name



      // Organize current assignments for each TA
      const currentAssignments = tasOnly.reduce((acc, ta) => {
        acc[ta.username] = users
    .filter(user => user.TA === ta.username)
    .map(user => ({ username: user.username, fullname: user.fullname }))
    .sort((a, b) => a.fullname.split(" ").slice(-1)[0].localeCompare(b.fullname.split(" ").slice(-1)[0])); // Sort by last name

        return acc;
      }, {});

      setTAs(tasOnly.map(ta => ({
        username: ta.username,
        fullname: ta.fullname
      })));
      setAssignments(currentAssignments);
      setUnassignedStudents(studentsWithoutTA.map(student => ({
        username: student.username,
        fullname: student.fullname
      })));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load data. Please try again.');
      setLoading(false);
    }
  };
// eslint-disable-next-line react-hooks/exhaustive-deps
useEffect(() => {
    fetchData();
  }, [courseID]);

  const handleStudentClick = (student) => {
    setSelectedStudents(prev =>
      prev.find(s => s.username === student.username)
        ? prev.filter(s => s.username !== student.username)
        : [...prev, student]
    );
  };

  const sortByLastName = (students) => {
    return students.sort((a, b) => 
        a.fullname.split(" ").slice(-1)[0].localeCompare(b.fullname.split(" ").slice(-1)[0])
    );
};

const assignStudents = async (ta) => {
    try {
        for (const student of selectedStudents) {
            await assignStudentToTA(courseID, student.username, ta.username);
        }
        setAssignments(prev => ({
            ...prev,
            [ta.username]: sortByLastName([...(prev[ta.username] || []), ...selectedStudents]) // Sort after adding
        }));
        setUnassignedStudents(prev => sortByLastName(prev.filter(student => 
            !selectedStudents.find(s => s.username === student.username)
        )));
        setSelectedStudents([]);
    } catch (error) {
        console.error('Error assigning students:', error);
        setError(`Failed to assign students: ${error.message}`);
    }
};


const toggleAssignedStudentSelection = (taUsername, student) => {
    setSelectedForUnassignment(prev => ({
        ...prev,
        [taUsername]: prev[taUsername]?.find(s => s.username === student.username)
            ? prev[taUsername].filter(s => s.username !== student.username)
            : [...(prev[taUsername] || []), student]
    }));
};

const unassignSelectedStudents = async (taUsername) => {
    try {
        const studentsToUnassign = selectedForUnassignment[taUsername] || [];
        for (const student of studentsToUnassign) {
            await unassignStudentFromTA(courseID, student.username);
        }

        setAssignments(prev => ({
            ...prev,
            [taUsername]: sortByLastName(prev[taUsername].filter(s => 
                !studentsToUnassign.find(u => u.username === s.username)
            ))
        }));
        setUnassignedStudents(prev => sortByLastName([
            ...prev,
            ...studentsToUnassign.map(student => ({ ...student, TA: "unassigned" }))
        ]));
        setSelectedForUnassignment(prev => ({ ...prev, [taUsername]: [] }));
    } catch (error) {
        console.error('Error unassigning students:', error);
        setError('Failed to unassign students. Please try again.');
    }
};



  const clearSelections = () => {
    setSelectedStudents([]);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <div>To assign one or more students to a TA, click on their names in the left column and then click the relevant button in the middle column. You can unassign students using the buttons in the right column.</div>
      <br></br>
    <div className="ta-student-assignments">
      {/* Unassigned Students Section */}
      <div className="unassigned-students">
        <h3>Unassigned Students</h3>
        <div>
          {unassignedStudents.map(student => (
            <div
              key={student.username}
              onClick={() => handleStudentClick(student)}
              className={`student ${selectedStudents.some(s => s.username === student.username) ? 'selected' : ''}`}
            >
              {student.fullname}
            </div>
          ))}
        </div>
        <button onClick={clearSelections} style={{ marginTop: '10px' }}>Clear Selections</button>
      </div>

      {/* TA Assignment Buttons */}
      <div className="ta-buttons">
        {tas.map(ta => (
          <button 
            key={ta.username} 
            onClick={() => assignStudents(ta)} 
            disabled={selectedStudents.length === 0}
          >
            Assign to {ta.fullname}
          </button>
        ))}
      </div>

     {/* Assigned Students Section */}
      <div className="assigned-students">
        {tas.map(ta => (
          
          <div key={ta.username} className="ta-assigned-list">
    <strong>Students assigned to {ta.fullname}:</strong>
    <div>
        {assignments[ta.username]?.map(student => (
            <div
                key={student.username}
                onClick={() => toggleAssignedStudentSelection(ta.username, student)}
                style={{
                    cursor: 'pointer',
                    padding: '5px',
                    borderRadius: '5px',
                    backgroundColor: selectedForUnassignment[ta.username]?.find(s => s.username === student.username)
                        ? 'var(--color3)' : 'transparent'
                }}
            >
                {student.fullname}
            </div>
        ))}
    </div>
    <button 
        onClick={() => unassignSelectedStudents(ta.username)} 
        disabled={!(selectedForUnassignment[ta.username] && selectedForUnassignment[ta.username].length > 0)}
        style={{ marginTop: '10px' }}
    >
        Unassign Selected Students
    </button>
</div>

        ))}
      </div>
      </div>
      </>
  );
};

export default TAStudentAssignments;