// Rubric.js
import React, { useContext } from 'react';
import MarkdownIt from 'markdown-it';
import { CourseDesignContext } from '../context/CourseDesignContext';

const md = new MarkdownIt();

const Rubric = ({ renderStyle = null }) => {
    const { courseDesignState } = useContext(CourseDesignContext);

    // Find the rubric in the state
    const rubric = courseDesignState.find(item => item.dataType === "rubric" && item.dataID === "1");
    const markdownContent = rubric ? rubric.dataText : 'No rubric available.';

    return (
      <div className={`rubric-container ${renderStyle || ''}`}>
            <div
                className="rubric-content"
                dangerouslySetInnerHTML={{ __html: md.render(markdownContent) }}
            />
        </div>
    );
};

export default Rubric;
