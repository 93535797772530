// atoms/QuestionDesignQuestionList.js

import React, { useContext, useState, useEffect, useMemo } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import QuestionCreator from './QuestionCreator'; // Ensure this path matches your file structure
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faArrowUp, faArrowDown, faExchangeAlt, faTimes, faCopy } from '@fortawesome/free-solid-svg-icons';  import { initializeSelection, generateUniqueId } from '../utils/courseDesignUtilities';


const QuestionDesignQuestionList = ({  selectedTopicDataIndex }) => {
    const { courseDesignState, removeFromDatabase, handleChange } = useContext(CourseDesignContext);
    const [showTopicSelection, setShowTopicSelection] = useState(null); // State to handle topic selection visibility


    // Filter and sort questions for the selected topic
      const orderedQuestions = courseDesignState
        .filter(item => item.dataType === 'question' && item.questionCourseDate === selectedTopicDataIndex)
        .sort((a, b) => parseInt(a.dataIndex) - parseInt(b.dataIndex));

    // Adjust dataIndex values to be sequential starting from 1 using useEffect
    useEffect(() => {
        orderedQuestions.forEach((question, index) => {
            const newDataIndex = (index + 1).toString();
            if (question.dataIndex !== newDataIndex) {
                handleChange('question', question.dataID, question.dataText, { ...question, dataIndex: newDataIndex });
            }
        });
    }, [orderedQuestions, handleChange]); // Add dependencies to ensure useEffect runs correctly


    // Function to handle deleting a question
    const handleDeleteQuestion = (questionID) => {
        console.log('Deleting question with ID:', questionID);
        removeFromDatabase('question', questionID);
    };


  // Function to handle moving a question up
    const handleMoveUp = (index) => {
        if (index > 0) {
            const currentQuestion = orderedQuestions[index];
            const previousQuestion = orderedQuestions[index - 1];

            handleChange('question', currentQuestion.dataID, currentQuestion.dataText, {
                ...currentQuestion,
                dataIndex: previousQuestion.dataIndex
            });
            handleChange('question', previousQuestion.dataID, previousQuestion.dataText, {
                ...previousQuestion,
                dataIndex: currentQuestion.dataIndex
            });
        }
    };

    // Function to handle moving a question down
    const handleMoveDown = (index) => {
        if (index < orderedQuestions.length - 1) {
            const currentQuestion = orderedQuestions[index];
            const nextQuestion = orderedQuestions[index + 1];

            handleChange('question', currentQuestion.dataID, currentQuestion.dataText, {
                ...currentQuestion,
                dataIndex: nextQuestion.dataIndex
            });
            handleChange('question', nextQuestion.dataID, nextQuestion.dataText, {
                ...nextQuestion,
                dataIndex: currentQuestion.dataIndex
            });
        }
    };

    const handleDuplicateQuestion = (questionID) => {
    const questionToDuplicate = courseDesignState.find(item => item.dataID === questionID);

    if (questionToDuplicate) {
        const newId = generateUniqueId();
        const nextDataIndex = (parseInt(questionToDuplicate.dataIndex) + 1).toString();
        const duplicatedQuestion = {
            ...questionToDuplicate,
            dataID: newId, // Assign a new unique ID
            dataIndex: nextDataIndex, // Increment the index
        };

        // Update the state with the duplicated question
        handleChange('question', duplicatedQuestion.dataID, duplicatedQuestion.dataText, {
            dataIndex: duplicatedQuestion.dataIndex,
            questionType: duplicatedQuestion.questionType,
            questionOptions: duplicatedQuestion.questionOptions,
            questionLogic: duplicatedQuestion.questionLogic,
            questionCourseDate: duplicatedQuestion.questionCourseDate,
        });
    }
};


     // Function to handle showing the topic selection
    const handleShowTopicSelection = (questionID) => {
        setShowTopicSelection(questionID);
    };

    // Function to handle moving a question to a different topic
    const handleMoveToTopic = (questionID, newTopicDataID) => {
        // set questionText to the dataText of the question with the questionID
        const currentQuestion = courseDesignState.find(item => item.dataType === 'question' && item.dataID === questionID);
        handleChange('question', questionID, currentQuestion.dataText, { questionCourseDate: newTopicDataID, dataIndex: "100" });
        console.log('Moving question to topic with ID:', newTopicDataID);
        setShowTopicSelection(null); // Close the topic selection
    };


// Use useMemo to filter and sort topics
const selectableTopics = useMemo(() => {
  return courseDesignState
    .filter(item => item.dataType === 'topic' && item.questionOptions === "yes")
        .sort((a, b) => parseInt(a.dataIndex) - parseInt(b.dataIndex)); // Sort by dataIndex
}, [courseDesignState]); // Recalculate only when courseDesignState changes


    return (
        <>
            {orderedQuestions.map((question, index) => {
                const updatedDataIndex = (index + 1).toString();

                return (
                    <div key={question.dataID} style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                        <QuestionCreator
                            questionCourseDate={selectedTopicDataIndex}
                            dataIndex={updatedDataIndex} // Use the updated dataIndex
                            dataText={question.dataText}
                            questionLogic={question.questionLogic}
                            questionOptions={question.questionOptions}
                            questionType={question.questionType}
                            dataID={question.dataID}
                        />
            <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>

                         {/* Conditionally render the Move Up button */}
                            {index > 0 && (
                                <button onClick={() => handleMoveUp(index)} style={{ marginBottom: '5px' }}>
                                    <FontAwesomeIcon icon={faArrowUp} />
                                </button>
                            )}

                            {/* Conditionally render the Move Down button */}
                            {index < orderedQuestions.length - 1 && (
                                <button onClick={() => handleMoveDown(index)} style={{ marginBottom: '5px' }}>
                                    <FontAwesomeIcon icon={faArrowDown} />
                                </button>
                            )}
<button
        onClick={() => handleDuplicateQuestion(question.dataID)}
        style={{ marginBottom: '5px' }}
        title="Duplicate Question"
    >
        <FontAwesomeIcon icon={faCopy} />
    </button>
                            {/* Delete button */}
                            <button onClick={() => handleDeleteQuestion(question.dataID)} style={{ marginBottom: '5px' }}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>

                               {/* Topic Change Button */}
                        <button onClick={() => handleShowTopicSelection(question.dataID)} style={{ marginBottom: '5px' }}>
        <FontAwesomeIcon icon={faExchangeAlt} />
                            </button>
                            
                             {/* Topic Selection Dropdown/Modal */}
    {showTopicSelection === question.dataID && (
                                <div style={{ position: 'absolute', top: '100%', left: 0, backgroundColor: 'white', border: '1px solid #ccc', padding: '10px', zIndex: 1 }}>
                                    {/* Close button in the top-right corner */}
                                    <button 
                                        onClick={() => handleShowTopicSelection(null)} 
                                        style={{
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            background: 'transparent',
                                            border: 'none',
                                            color: 'red',
                                            fontSize: '16px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
            <p>Move question to...</p>
            {selectableTopics.map((topic) => (
                <div key={topic.dataID} style={{ cursor: 'pointer', margin: '5px 0' }} onClick={() => handleMoveToTopic(question.dataID, topic.dataID)}>
                    {topic.dataText}
                </div>
            ))}
        </div>
    )}
                        </div>

                      
                    </div>
                );
            })}
        </>
    );
};

export default QuestionDesignQuestionList;
