import React, { useContext } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import CourseDesignQuestion from '../molecules/CourseDesignQuestion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const generateRandomID = () => {
  return Math.random().toString(36).substring(2, 7);
};

const CourseDesignSectionPicker = () => {
  const { courseDesignState, handleChange, removeFromDatabase } = useContext(CourseDesignContext);

  // Get and sort sectionName items by dataIndex
  const sections = courseDesignState
    .filter(item => item.dataType === 'sectionName')
    .sort((a, b) => parseFloat(a.dataIndex) - parseFloat(b.dataIndex));

  // Function to add a new section
  const addSection = () => {
    const highestDataIndex = sections.length > 0
      ? Math.max(...sections.map(section => parseFloat(section.dataIndex)))
      : 0;
    const newDataIndex = (highestDataIndex + 1).toString();
    const newID = generateRandomID();
    
    handleChange('sectionName', newID, '', { dataIndex: newDataIndex });
  };

  // Function to delete a section
  const deleteSection = (dataID) => {
    removeFromDatabase('sectionName', dataID);
  };

  return (
    <>
      <div>
        This website requires that courses be divided into sections. These are used to group together the questions that you will add to the individual class days. You can add as many sections as you want, and you can always add more, delete some, or change the order of the sections later.<br />
      </div>
      <div>
        {sections.map((section, index) => (
          <div key={section.dataID} style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
            <CourseDesignQuestion
              questionType="text"
              dataType="sectionName"
              dataID={section.dataID}
              value={section.dataText}
              onChange={(value) => handleChange('sectionName', section.dataID, value, { dataIndex: section.dataIndex })}
              inputStyle={{ width: '600px' }} // Set width to 200px
            />
           <button
  onClick={() => deleteSection(section.dataID)}
  style={{
    border: 'none',
    backgroundColor: 'var(--color2)',
    cursor: 'pointer',
    padding: '9px',
    borderRadius: '4px',
    marginTop: '15px',
    transition: 'opacity 0.2s ease',
  }}
  onMouseEnter={(e) => (e.currentTarget.style.opacity = '0.8')}
  onMouseLeave={(e) => (e.currentTarget.style.opacity = '1')}
>
  <FontAwesomeIcon icon={faTrash} />
</button>
          </div>
        ))}
        
        <button onClick={addSection}>+ add course section</button>
      </div>
    </>
  );
};

export default CourseDesignSectionPicker;
