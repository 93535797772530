import React, { useContext, useEffect, useState } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';


const HandBackAssignments = () => {
  const { courseDesignState, handleChange } = useContext(CourseDesignContext);
  const [localEvaluations, setLocalEvaluations] = useState([]);

  useEffect(() => {
    const filteredEvaluations = courseDesignState
      .filter(item => item.dataType === 'evaluation')
      .sort((a, b) => a.questionCourseDate.localeCompare(b.questionCourseDate));
    setLocalEvaluations(filteredEvaluations);
  }, [courseDesignState]);

  const handleGradingStatusChange = (dataID, newStatus) => {
    // Update the local state for immediate feedback
    setLocalEvaluations(prevEvaluations =>
      prevEvaluations.map(evaluation =>
        evaluation.dataID === dataID
          ? { ...evaluation, questionLogic: newStatus }
          : evaluation
      )
    );

    // Persist the change in the database
    const updatedEvaluation = localEvaluations.find(
      evaluation => evaluation.dataID === dataID
    );
    if (updatedEvaluation) {
      handleChange('evaluation', dataID, updatedEvaluation.dataText, {
        questionLogic: newStatus,
      });
    }
  };

  const handleEditDueDate = (dataID, newDate) => {
  // Update local state with the new due date
  setLocalEvaluations(prev =>
    prev.map(evaluation =>
      evaluation.dataID === dataID
        ? { ...evaluation, questionCourseDate: newDate }
        : evaluation
    )
  );

  // Persist the change in the database
  const updatedEvaluation = localEvaluations.find(
    evaluation => evaluation.dataID === dataID
  );
  if (updatedEvaluation) {
    handleChange('evaluation', dataID, updatedEvaluation.dataText, {
      questionCourseDate: newDate,
    });
  }
};

  const formatDate = (dateString) => {
  if (!dateString) return 'No due date set';

  const [year, month, day] = dateString.split('-');
  const months = [
    'January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September', 'October',
    'November', 'December'
  ];
  return `${months[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`;
};

  return (
    <div className="hand-back-assignments">
      <div className="instructions">
        <p>You can use the buttons below to make grades and comments available to students. Once "grading complete" is selected, students will be able to view their grades and comments for that assignment. You can also edit due dates for assignments below; but be aware that changing the due date of an assignment delete all numerical grades for that assignment. (This is because changing the due date will also change which student answers are being evaluated.) You can give extensions to individual students by clicking "custom due date" on the page for that student's assignment.</p>
      </div>

      {localEvaluations.map(evaluation => (
        <div
          key={evaluation.dataID}
          className="evaluation-item"
          style={{
            backgroundColor: 'white',
            boxShadow: 'var(--standard-box-shadow)',
            padding: '16px',
            marginBottom: '16px',
          }}
        >
          <h2>{evaluation.dataText}</h2>
                 <p>Due Date: {formatDate(evaluation.questionCourseDate)}</p>


        <button
  onClick={() => {
    const newDate = prompt(
      'Enter a new due date (yyyy-mm-dd):',
      evaluation.questionCourseDate
    );
    if (newDate) handleEditDueDate(evaluation.dataID, newDate);
  }}
>
  Edit Due Date
</button>

          <div style={{ marginTop: '8px', display: 'flex', gap: '8px' }}>
            <button
              style={{
                backgroundColor:
                  evaluation.questionLogic === 'ungraded' ? 'var(--color3)' : 'white',
                color: 'black',
                boxShadow: 'var(--standard-box-shadow)',
                padding: '8px 16px',
                cursor: 'pointer',
                border: 'none',
                borderRadius: '4px',
              }}
              onClick={() => handleGradingStatusChange(evaluation.dataID, 'ungraded')}
            >
              Grading Incomplete
            </button>
            <button
              style={{
                backgroundColor:
                  evaluation.questionLogic === 'graded' ? 'var(--color3)' : 'white',
                color: 'black',
                boxShadow: 'var(--standard-box-shadow)',
                padding: '8px 16px',
                cursor: 'pointer',
                border: 'none',
                borderRadius: '4px',
              }}
              onClick={() => handleGradingStatusChange(evaluation.dataID, 'graded')}
            >
              Grading Complete
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HandBackAssignments;
